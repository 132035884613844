import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/beecitylogo.png";
import "./Main.css";
import StoreBadge from "react-store-badge";
import LanguageIcon from "@mui/icons-material/Language";
import axios from "axios";

import Mobile1 from "../../assets/mobile_beehive_status.png";
import Mobile2 from "../../assets/mobile_apiaries_image.png";
import Mobile1En from "../../assets/mobile_beehive_status_en.png";
import Mobile2En from "../../assets/mobile_apiaries_image_en.png";
import Mobile1Uk from "../../assets/mobile_beehive_status_uk.png";
import Mobile2Uk from "../../assets/mobile_apiaries_image_uk.png";
import Web1 from "../../assets/apiaryweb.png";
import BeeKeeper from "../../assets/giphy.png";

import { Parallax } from "react-scroll-parallax";

import { FaMapMarkedAlt, FaArchive } from "react-icons/fa";
import { ImCloudDownload } from "react-icons/im";
import { GiBeet, GiBee, GiHoneycomb, GiHoneypot } from "react-icons/gi";
import { MdOutlineEditCalendar } from "react-icons/md";
import Carousel1 from "./Carousel";
import Carousel2 from "./Carousel2";
import { format } from "date-fns";
import UprageModal from "./UprageModal";
import TermsModal from "./TermsModal";
import PrivacyModal from "./PrivacyModal";
import AboutUsModal from "./AboutUsModal";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContextProvider";
import BlogModal from "./BlogModal";
import SocialMedia from "./SocialMedia";
import VideoSection from "./VideoSection";


const MainPage = ({setOnLandingPage}) => {
  const history = useHistory();

  const [uprageModalShow, setModalShow] = useState(false);
  const [termModalShow, setTermShow] = useState(false);
  const [privacyModalShow, setPrivacyShow] = useState(false);
  const [aboutUsModalShow, setAboutUsShow] = useState(false);
  const [blogModalShow, setBlogModalShow] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [scrollTop, setScrollTop] = useState(0);
  // let { currentUser } = useAuth();

  const { i18n, t } = useTranslation(["common"]);

  const screenWidth = window.screen.width;

  const handleScroll = (e) => {
    setScrollTop(e.target.scrollTop);
  };
  const handleChangeLanguage = (language) => {
    setSelectedLanguage(language);
  };
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const handleHome = async () => {
    setOnLandingPage(false);
    history.push("/login");
  };

  
    // const [getLocation, setGetLocation] = useState({
    //   ip: "",
    //   countryName: "",
    //   countryCode: "",
    //   city: "",
    //   timezone: ""
    // });
    const languageChange = (location) =>{
      location == 'Turkey' ? setSelectedLanguage('tr') : location == 'Ukraine' ?  setSelectedLanguage('uk') : (setSelectedLanguage('en'))
    }
    const getGeoInfo = () => {
      axios
        .get("https://ipapi.co/json/")
        .then((response) => {
          let data = response.data;
          languageChange(data.country_name)
          // setGetLocation({
          //   ...getLocation,
          //   ip: data.ip,
          //   countryName: data.country_name,
          //   countryCode: data.country_calling_code,
          //   city: data.city,
          //   timezone: data.timezone
          // });
        })
        .catch((error) => {
          console.log(error);
        });
    };

  
  
    useEffect(() => {
      getGeoInfo();
    }, []);



  return (
    <div>
      <div
        onScroll={(e) => handleScroll(e)}
        style={{
          height: "100vh",
          overflow: "scroll",
        }}
        data-bs-spy="scroll"
        data-bs-target="#navbar"
        data-bs-offset="65"
        className="body"
      >
          <nav
            className={`navbar navbar-expand-md fixed-top ${
              scrollTop > 50 ? "is-sticky" : ""
            } navbar-custom sticky sticky-dark`}
          >
            <div className="navbar-container">
              <div>
                <img className="navbar-logo" src={Logo} alt="" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "1rem",
                  }}
                >
                  <a className="navbar-br/and logo text-uppercase" href="">
                    BEECITY
                  </a>
                  <a className="navbar-br/and logo text-uppercase" href="">
                    APIARY
                  </a>
                </div>

                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="navbar-nav navbar-center" id="navbar-navlist">
                    <li className="nav-item">
                      <a data-scroll href="#home" className="nav-link">
                        {t("anasayfa")}
                      </a>
                    </li>
                    <li className="nav-item">
                    <a data-scroll href="#services" className="nav-link">
                      {t("hizmetler")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a data-scroll href="#features" className="nav-link">
                    {t("urunler")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a data-scroll href="#pricing" className="nav-link">
                    {t("ucretlendirme")}
                    </a>
                  </li>
                    {/* <li className="nav-item">
                      <a
                        onClick={() => setAboutUsShow(true)}
                        data-scroll
                        href="#team"
                        className="nav-link"
                      >
                        {t("hakkımızda")}
                      </a>
                    </li> */}
                    {/* <li className="nav-item">
                    <a onClick={() => setBlogModalShow(true)} className="nav-link">
                      {t("blog")}
                    </a>
                  </li> */}
                    <li  onClick={() => history.push("/forum")} className="nav-item">
                    <a className="nav-link">
                      Forum
                    </a>
                  </li>
                  </ul>
                </div>
              </div>
              <div className="nav-button navbar-selections">
                <ul className="">
                  <li>
                    <button             
                      type="button"
                      className="btn navbar-btn bg-dark text-white btn-rounded waves-effect waves-light"
                      onClick={handleHome}
                    >
                      {t("ucretsizdene")}
                    </button>
                  </li>
                  <li className="ms-3">
                    <select
                      className="form-select-sm bg-black text-white btn navbar-btn btn-rounded"
                      aria-label="Default select example"
                      onChange={(e) => handleChangeLanguage(e.target.value)}
                      value={selectedLanguage}              
                    >
                      <option value="tr">Türkçe</option>
                      <option value="en">English</option>
                      <option value="uk">український</option>
                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

        <section className="section  bg-home home-half" id="home">
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 section-1                                                                                                                                                                                                       text-white text-left">
                    <Parallax speed={parseFloat(`${scrollTop <= 50 ? 0 : 90}`)}>
                      <h1 className="home-title">{t("section-1-1")}</h1>
                      <img
                        src={BeeKeeper}
                        alt="none"
                        className="homepage-bee"
                      ></img>

                      <p className="pt-3 home-desc">{t("section-1-2")}</p>
                      <div
                        style={{
                          marginTop: "2rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <StoreBadge
                          name="Beecity Apiary"
                          googlePlayUrl="https://play.google.com/store/apps/details?id=com.arikent.apiary"
                          appStoreUrl="https://apps.apple.com/us/app/beecity-apiary/id1633088644"
                        />
                        <div
                          style={{
                            width: "10rem",
                            height: "3.4rem",
                            backgroundColor: "black",
                            border: "0.51px solid #f1f1f1",
                            borderRadius: "0.51rem",
                            cursor: "pointer",
                          }}
                          onClick={handleHome}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "inherit",
                              width: "inherit",
                            }}
                          >
                            <LanguageIcon
                              style={{ fontSize: "2.5rem", color: "cyan" }}
                            />
                            <div style={{ margin: "0.45rem" }}>
                              <h5
                                style={{
                                  color: "whitesmoke",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                WEB
                              </h5>
                              <h5
                                style={{
                                  color: "whitesmoke",
                                  margin: 0,
                                  padding: 0,
                                }}
                              >
                                {" "}
                                APP
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Parallax>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />

          <div className="wave-effect wave-anim">
            <div className="waves-shape shape-one">
              <div className="wave wave-one"></div>
            </div>
            <div className="waves-shape shape-two">
              <div className="wave wave-two"></div>
            </div>
            <div className="waves-shape shape-three">
              <div className="wave wave-three"></div>
            </div>
          </div>
        </section>

        <section className="section pt-5" id="services">
          <div className="container">
            <Parallax speed={parseFloat(`${scrollTop <= 50 ? 0 : 70}`)}>
              <div className="row p-5">
                <div className="col-lg-8 offset-lg-2">
                  <h1 className="section-title text-center">
                    {t("section-2-1")}
                  </h1>
                  <div className="section-title-border mt-3"></div>
                  <p className="section-subtitle text-muted text-center pt-4 font-secondary">
                    {t("section-2-2")}
                  </p>
                </div>
              </div>
            </Parallax>

            <Parallax translateY={["50px", "-50px"]}>
              <div className="row mt-4">
                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 700 ? "card-info-active-1" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-diamond text-primary"></i>
                    <FaMapMarkedAlt className="info-cards-icons" />
                    <h4 className="pt-5">{t("section-2-3-a-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-3-a-2")}</p>
                  </div>
                </div>
                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 700 ? "card-info-active-2" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-display2 text-primary"></i>
                    <FaArchive className="info-cards-icons" />
                    <h4 className="pt-5">{t("section-2-3-b-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-3-b-2")}</p>
                  </div>
                </div>

                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 700 ? "card-info-active-3" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-piggy text-primary"></i>
                    <ImCloudDownload className="info-cards-icons" />
                    <h4 className="pt-5">{t("section-2-3-c-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-3-c-2")}</p>
                  </div>
                </div>
              </div>
            </Parallax>
            <Parallax translateY={["50px", "-50px"]}>
              <div className="row mt-4">
                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 1050 ? "card-info-active-4" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-science text-primary"></i>
                    <GiBeet className="info-cards-icons" />

                    <h4 className="pt-5">{t("section-2-4-a-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-4-a-2")}</p>
                  </div>
                </div>

                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 1050 ? "card-info-active-5" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-news-paper text-primary"></i>
                    <GiBee className="info-cards-icons" />

                    <h4 className="pt-5">{t("section-2-4-b-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-4-b-2")}</p>
                  </div>
                </div>

                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 1050 ? "card-info-active-6" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-plane text-primary"></i>
                    <GiHoneycomb className="info-cards-icons" />

                    <h4 className="pt-5">{t("section-2-4-c-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-4-c-2")}</p>
                  </div>
                </div>
              </div>
            </Parallax>
            <Parallax translateY={["50px", "-50px"]}>
              <div className="row mt-4">
                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 1250 ? "card-info-active-7" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-science text-primary"></i>
                    <GiHoneypot className="info-cards-icons" />
                    <h4 className="pt-5"> {t("section-2-5-a-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-5-a-2")}</p>
                  </div>
                </div>

                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 1250 ? "card-info-active-8" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-news-paper text-primary"></i>
                    <h4 className="pt-5">{t("section-2-5-b-1")}</h4>
                    <MdOutlineEditCalendar className="info-cards-icons" />

                    <p className="pt-3 text-muted">{t("section-2-5-b-2")}</p>
                  </div>
                </div>

                <div
                  className={`col-lg-4 mt-3 ${
                    scrollTop > 1250 ? "card-info-active-9" : "card-info"
                  }`}
                >
                  <div className="services-box text-center hover-effect">
                    <i className="pe-7s-plane text-primary"></i>
                    <GiHoneypot className="info-cards-icons" />
                    <h4 className="pt-5">{t("section-2-5-c-1")}</h4>
                    <p className="pt-3 text-muted">{t("section-2-5-c-2")}</p>
                  </div>
                </div>
              </div>
            </Parallax>
          </div>
        </section>
        {/* <Parallax translateY={['-300px', '50px']}> */}

        <section
          className={`section bg-light ${
            scrollTop > 1600 ? "section-2-active" : "section-2"
          }`}
          id="features"
        >
          <div className="container">
            <div className="image-text-container">
              <div className="features-box">
                <h3>{t("section-3-a-1")}</h3>
                <p className="text-muted web-desc">{t("section-3-a-2")}</p>
                <ul className="text-danger list-styled w-6 mt-4 p-0 features-item-list">
                  <li className="">{t("section-3-a-3")}</li>
                  <li className="">{t("section-3-a-4")}</li>
                  <li className="">{t("section-3-a-5")}</li>
                  <li className="">{t("section-3-a-6")}</li>
                  <li className=""> {t("section-3-a-7")}</li>
                </ul>
              </div>
              <div style={{ display: "flex" }}>
                <div className={scrollTop > 1750 ? "card card-1" : "img-fluid"}>
                  <div className="image">
                    <img
                      src={
                        selectedLanguage === "tr"
                          ? Mobile2
                          : selectedLanguage === "en"
                          ? Mobile2En
                          : Mobile2Uk
                      }
                      alt="apiary and hive status image"
                    />
                  </div>
                  <div className="details">
                    <div className="center">
                      <h1>
                        {t("section-3-b-a-1")}
                        <br />
                        <span>{t("section-3-b-a-2")}</span>
                      </h1>
                      <p>{t("section-3-b-a-3")}</p>
                    </div>
                  </div>
                </div>
                <div className={scrollTop > 1750 ? "card card-2" : "img-fluid"}>
                  <div className="image">
                    <img
                      src={
                        selectedLanguage === "tr"
                          ? Mobile1
                          : selectedLanguage === "en"
                          ? Mobile1En
                          : Mobile1Uk
                      }
                      alt="hive status image"
                    />
                  </div>
                  <div className="details">
                    <div className="center">
                      <h1>
                        {t("section-3-b-b-1")} <br />
                        <span>{t("section-3-b-b-2")}</span>
                      </h1>
                      <p>{t("section-3-b-b-3")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg-light">
          <h2>{t("section-4-1")}</h2>
          <Carousel1
            selectedLanguage={selectedLanguage}
            screenWidth={screenWidth}
          />
        </section>

        <section
          className={`section bg-light ${
            scrollTop > 3100 ? "section-3-active" : "section-3"
          }`}
          id="features"
        >
          <div className="container">
            <div className="image-text-container">
              <div className={scrollTop > 3100 ? "card card-3" : "img-fluid2"}>
                <img src={Web1} alt="macbook image" />
              </div>
              <div className="features-box">
                <h3>{t("section-5-1")}</h3>
                <p className="text-muted web-desc">{t("section-5-2")}</p>
                <ul className="text-danger list-styled w-6 mt-4 p-0 features-item-list">
                  <li className="">{t("section-5-3")}</li>
                  <li className="">{t("section-5-4")}</li>

                  <li className="">{t("section-5-5")}</li>
                  <li className="">{t("section-5-6")}</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-light">
          <h2>{t("section-6-1")}</h2>
          <Carousel2 />
        </section>

        <section className="section section-lg bg-web-desc">
          <div className="bg-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="text-white">{t("section-7-1")}</h2>
                <p className="pt-3 home-desc">{t("section-7-2")}</p>
                <a
                  onClick={() => setModalShow(true)}
                  href="#"
                  className="btn btn-white mt-4 waves-effect waves-light mb-5"
                >
                  {t("section-7-3")}
                </a>
              </div>
            </div>
          </div>
          <div className="bg-pattern-effect">
            <img src="images/bg-pattern.png" alt="" />
          </div>
        </section>
        <UprageModal
          show={uprageModalShow}
          onHide={() => setModalShow(false)}
        />
        <TermsModal show={termModalShow} onHide={() => setTermShow(false)} />
        <PrivacyModal
          show={privacyModalShow}
          onHide={() => setPrivacyShow(false)}
        />
        <AboutUsModal
          show={aboutUsModalShow}
          onHide={() => setAboutUsShow(false)}
        />
        {/* <BlogModal
          show={blogModalShow}
          onHide={() => setBlogModalShow(false)}
        /> */}

        <section className="section pt-5" id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="section-title text-center">
                  {t("section-8-1")}
                </h1>
                <div className="section-title-border mt-3"></div>
              </div>
            </div>
            <div className={"row h-10 mt-5"}>
              <div className="col-lg-6">
                <div
                  style={{ backgroundColor: "#ffe082" }}
                  className="text-center pricing-box hover-effect"
                >
                  <h4 className="text-uppercase">{t("section-8-2-a-1")}</h4>
                  <h1>{t("section-8-2-a-2")}</h1>
                  <br></br>

                  <div className="pricing-border"></div>
                  <div className="plan-features mt-4">
                    <p>
                      {t("section-8-2-a-3")} <b className="">1</b>
                    </p>
                    <p>
                      {t("section-8-2-a-4")} <b className="">5</b>
                    </p>
                    <p>
                      {t("section-8-2-a-5")} <b className=""></b>
                    </p>

                    {/* <p>
                      Support: <b className="text-primary">No</b>
                    </p>
                    <p>
                      <b className="text-primary">1</b> Domain
                    </p>
                    <p>
                      <b className="text-primary">No</b> Hidden Fees
                    </p> */}
                  </div>
                  <a
                    href="#"
                    style={{ backgroundColor: "black", color: "white" }}
                    className="btn  waves-effect waves-light mt-4"
                    onClick={handleHome}
                  >
                    {t("section-8-2-a-6")}
                  </a>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  style={{ backgroundColor: "#ffe082" }}
                  className="text-center pricing-box  hover-effect price-active"
                >
                  <h4 className="text-uppercase">{t("section-8-2-b-1")}</h4>
                  <h1>{t("section-8-2-b-2")}</h1>
                  <h6 className="text-uppercase text-muted">
                    {t("section-8-2-b-3")}
                  </h6>
                  <div className="pricing-border"></div>
                  <div className="plan-features mt-4">
                    <p>
                      {t("section-8-2-b-4")} <b className=""></b>
                    </p>
                    <p>
                      {t("section-8-2-b-5")} <b className=""></b>
                    </p>
                    <p>
                      {t("section-8-2-b-6")} <b className=""></b>
                    </p>
                    <p>
                      {t("section-8-2-b-7")} <b className=""></b>
                    </p>
                    <p>
                      {t("section-8-2-b-8")} <b className=""></b>
                    </p>
                    <p>
                      {t("section-8-2-b-9")} <b className=""></b>
                    </p>
                    <p>
                      {t("section-8-2-b-10")} <b className=""></b>
                    </p>
                    {/* <p>
                      Support: <b className="text-primary">Yes</b>
                    </p>
                    <p>
                      <b className="text-primary">10</b> Domain
                    </p>
                    <p>
                      <b className="text-primary">No</b> Hidden Fees
                    </p> */}
                  </div>
                  <a
                    style={{ backgroundColor: "black", color: "white" }}
                    onClick={() => setModalShow(true)}
                    href="#"
                    className="btn waves-effect waves-light mt-4"
                  >
                    {t("section-8-2-b-11")}
                  </a>
                </div>
              </div>

              {/* <div className="col-lg-4">
                <div className="text-center pricing-box hover-effect">
                  <h4 className="text-uppercase">Ultimate</h4>
                  <h1>$29.90</h1>
                  <h6 className="text-uppercase text-muted">
                    Billing Per Month
                  </h6>
                  <div className="pricing-border"></div>
                  <div className="plan-features mt-4">
                    <p>
                      Bandwidth: <b className="text-primary">100GB</b>
                    </p>
                    <p>
                      Onlinespace: <b className="text-primary">2 GB</b>
                    </p>
                    <p>
                      Support: <b className="text-primary">Yes</b>
                    </p>
                    <p>
                      <b className="text-primary">Unlimited</b> Domain
                    </p>
                    <p>
                      <b className="text-primary">No</b> Hidden Fees
                    </p>
                  </div>
                  <a
                    href="#"
                    className="btn btn-primary waves-effect waves-light mt-4"
                  >
                    Join Now
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* <section className="section pt-4" id="team">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="section-title text-center">Behind The People</h1>
                <div className="section-title-border mt-3"></div>
                <p className="section-subtitle text-muted text-center font-secondary pt-4">
                  It is a long established fact that create category leading
                  br/and experiences a reader will be distracted by the readable
                  content of a page when looking at its layout.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3 col-sm-6">
                <div className="team-box text-center hover-effect">
                  <div className="team-wrapper">
                    <div className="team-member">
                      <img
                        alt=""
                        src="images/team/img-1.jpg"
                        className="img-fluid rounded"
                      />
                    </div>
                  </div>
                  <h4 className="team-name">Frank Johnson</h4>
                  <p className="text-uppercase team-designation">CEO</p>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="team-box text-center hover-effect">
                  <div className="team-wrapper">
                    <div className="team-member">
                      <img
                        alt=""
                        src="images/team/img-2.jpg"
                        className="img-fluid rounded"
                      />
                    </div>
                  </div>
                  <h4 className="team-name">Elaine Stclair</h4>
                  <p className="text-uppercase team-designation">Designer</p>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="team-box text-center hover-effect">
                  <div className="team-wrapper">
                    <div className="team-member">
                      <img
                        alt=""
                        src="images/team/img-3.jpg"
                        className="img-fluid rounded"
                      />
                    </div>
                  </div>
                  <h4 className="team-name">Wanda Arthur</h4>
                  <p className="text-uppercase team-designation">Developer</p>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="team-box text-center hover-effect">
                  <div className="team-wrapper">
                    <div className="team-member">
                      <img
                        alt=""
                        src="images/team/img-4.jpg"
                        className="img-fluid rounded"
                      />
                    </div>
                  </div>
                  <h4 className="team-name">Joshua Stemple</h4>
                  <p className="text-uppercase team-designation">Manager</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-light">
          <div className="container">
            <div className="row"> */}
        {/* <div className="col-lg-8 offset-lg-2">
                <h1 className="section-title text-center">Work Process</h1>
                <div className="section-title-border mt-3"></div>
                <p className="section-subtitle text-muted text-center font-secondary pt-4">
                  In an ideal world this website wouldn’t exist, a client would
                  acknowledge the importance of having web copy before the Proin
                  vitae ipsum vel ex finibus semper design starts.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 text-center process-left-icon-1">
                <i className="pe-7s-angle-right"></i>
              </div>
              <div className="col-lg-6 text-center process-left-icon-2">
                <i className="pe-7s-angle-right"></i>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4 plan-line">
                <div className="text-center process-box">
                  <i className="pe-7s-pen text-primary"></i>
                  <h4 className="pt-3">Tell us what you need</h4>
                  <p className="text-muted">
                    The Big Oxmox advised her not to do so.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 plan-line">
                <div className="text-center process-box">
                  <i className="pe-7s-id text-primary"></i>
                  <h4 className="pt-3">Get free quotes</h4>
                  <p className="text-muted">Little Blind Text didn’t listen.</p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="text-center process-box">
                  <i className="pe-7s-target text-primary"></i>
                  <h4 className="pt-3">Deliver high quality product</h4>
                  <p className="text-muted">
                    When she reached the first hills.
                  </p>
                </div>
              </div>

              <div className="text-center mx-auto">
                <a
                  href="#"
                  className="btn btn-primary waves-light waves-effect mt-5"
                >
                  Get Started <i className="mdi mdi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="section" id="testi">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="section-title text-center">What they've said</h1>
                <div className="section-title-border mt-3"></div>
                <p className="section-subtitle text-muted text-center font-secondary pt-4">
                  The Big Oxmox advised her not to do so, because there were
                  thousands of bad Commas, wild Question Marks and devious
                  pulvinar metus molestie sed Semikoli.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="testimonial-box hover-effect mt-4">
                  <img
                    src="images/testimonials/user-2.jpg"
                    alt=""
                    className="img-fluid d-block img-thumbnail rounded-circle"
                  />
                  <div className="testimonial-decs">
                    <p className="text-muted text-center mb-0">
                      “I feel confident imposing change on myself. It's a lot
                      more fun progressing than looking back. That's why
                      scelerisque pretium dolor, sit amet vehicula erat pelleque
                      need throw curve balls.”
                    </p>
                  </div>
                  <h5 className="text-center text-uppercase pt-3">
                    Ruben Reed -{" "}
                    <span className="text-muted text-capitalize">
                      Charleston
                    </span>
                  </h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="testimonial-box hover-effect mt-4">
                  <img
                    src="images/testimonials/user-1.jpg"
                    alt=""
                    className="img-fluid d-block img-thumbnail rounded-circle"
                  />
                  <div className="testimonial-decs">
                    <p className="text-muted text-center mb-0">
                      “Our task must be to free ourselves by widening our circle
                      of compassion to embr/ace all living creatures Integer
                      varius lacus non magna tempor congue natuasre the whole
                      its beauty.”{" "}
                    </p>
                  </div>
                  <h5 className="text-center text-uppercase pt-3">
                    Michael P. Howlett -{" "}
                    <span className="text-muted text-capitalize">
                      Worcester
                    </span>
                  </h5>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="testimonial-box hover-effect mt-4">
                  <img
                    src="images/testimonials/user-3.jpg"
                    alt=""
                    className="img-fluid d-block img-thumbnail rounded-circle"
                  />
                  <div className="testimonial-decs">
                    <p className="text-muted text-center mb-0">
                      “I've learned that people will forget what you said,
                      people will forget what you did, but people will never
                      aliquam in nunc quis tincidunt forget how you vestibulum
                      egestas them feel.”
                    </p>
                  </div>
                  <h5 className="text-center text-uppercase pt-3">
                    Theresa D. Sinclair -{" "}
                    <span className="text-muted text-capitalize">
                      Lynchburg
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section section-lg bg-get-start">
          <div className="bg-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <h1 className="get-started-title text-white">
                  Let's Get Started
                </h1>
                <div className="section-title-border mt-4 bg-white"></div>
                <p className="section-subtitle font-secondary text-white text-center pt-4">
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.{" "}
                </p>
                <a href="#" className="btn btn-white waves-effect mt-3 mb-4">
                  Get Started <i className="mdi mdi-arrow-right"></i>{" "}
                </a>
              </div>
            </div>
          </div>
          <div className="bg-pattern-effect">
            <img src="images/bg-pattern-light.png" alt="" />
          </div>
        </section>

        <section className="section bg-light pt-5" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="section-title text-center">Blog</h1>
                <div className="section-title-border mt-3"></div>
                <p className="section-subtitle text-muted text-center font-secondary pt-4">
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language ocean className at a euismod
                  mus ipsum vel ex finibus semper luctus quam.
                </p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-4">
                <div className="blog-box mt-4 hover-effect">
                  <img
                    src="images/blog/img-1.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div>
                    <h5 className="mt-4 text-muted">UI & UX Design</h5>
                    <h4 className="mt-3">
                      <a href="" className="blog-title">
                        {" "}
                        Doing a cross country road trip{" "}
                      </a>
                    </h4>
                    <p className="text-muted">
                      She packed her seven versalia, put her initial into the
                      belt and made herself on the way..
                    </p>
                    <div className="mt-3">
                      <a href="" className="read-btn">
                        Read More <i className="mdi mdi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="blog-box mt-4 hover-effect">
                  <img
                    src="images/blog/img-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div>
                    <h5 className="mt-4 text-muted">Digital Marketing</h5>
                    <h4 className="mt-3">
                      <a href="" className="blog-title">
                        New exhibition at our Museum
                      </a>
                    </h4>
                    <p className="text-muted">
                      Pityful a rethoric question ran over her cheek, then she
                      continued her way.
                    </p>
                    <div className="mt-3">
                      <a href="" className="read-btn">
                        Read More <i className="mdi mdi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="blog-box mt-4 hover-effect">
                  <img
                    src="images/blog/img-3.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div>
                    <h5 className="mt-4 text-muted">Travelling</h5>
                    <h4 className="mt-3">
                      <a href="" className="blog-title">
                        Why are so many people..
                      </a>
                    </h4>
                    <p className="text-muted">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia.
                    </p>
                    <div className="mt-3">
                      <a href="" className="read-btn">
                        Read More <i className="mdi mdi-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section " id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <h1 className="section-title text-center">Get In Touch</h1>
                <div className="section-title-border mt-3"></div>
                <p className="section-subtitle text-muted text-center font-secondary pt-4">
                  We thrive when coming up with innovative ideas but also
                  understand that a smart concept should be supported with
                  faucibus sapien odio measurable results.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="mt-4 pt-4">
                  <p className="mt-4">
                    <span className="h5">Office Address 1:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                      4461 Cedar Street Moro, AR 72368
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Office Address 2:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                      2467 Swick Hill Street <br />
                      New Orleans, LA 70171
                    </span>
                  </p>
                  <p className="mt-4">
                    <span className="h5">Working Hours:</span>
                    <br />{" "}
                    <span className="text-muted d-block mt-2">
                      9:00AM To 6:00PM
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="custom-form mt-4 pt-4">
                  <form
                    method="post"
                    name="myForm"
                    onSubmit="return validateForm()"
                  >
                    <p id="error-msg"></p>
                    <div id="simple-msg"></div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mt-2">
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Your name*"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group mt-2">
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder="Your email*"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            name="subject"
                            placeholder="Your Subject.."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mt-2">
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control"
                            placeholder="Your message..."
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-end">
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary"
                          value="Send Message"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-social bg-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <ul className="list-inline social mt-4">
                  <li className="list-inline-item">
                    <a href="" className="social-icon">
                      <i className="mdi mdi-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="" className="social-icon">
                      <i className="mdi mdi-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="" className="social-icon">
                      <i className="mdi mdi-linkedin"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="" className="social-icon">
                      <i className="mdi mdi-google-plus"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="" className="social-icon">
                      <i className="mdi mdi-microsoft-xbox"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 mt-4">
                <p className="contact-title">
                  <i className="pe-7s-call"></i> &nbsp;+91 123 4556 789
                </p>
              </div>
              <div className="col-lg-3 mt-4 text-end">
                <p className="contact-title">
                  <i className="pe-7s-mail-open"></i>&nbsp; Support@info.com
                </p>
              </div>
            </div>
          </div>
        </section> */}
        <VideoSection/>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 mt-4">
                <h4>{t("section-9-a-1")}</h4>
                <div className="text-muted mt-4">
                  <ul className="list-unstyled footer-list">
                    <li>
                      <a href="#">{t("section-9-a-2")}</a>
                    </li>
                    <li>
                      <a onClick={() => setAboutUsShow(true)} href="#">
                        {t("section-9-a-3")}
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">İletişim</a>
                    </li> */}
                    {/* <li>
                      <a href="#">Contact us</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 mt-4">
                <h4>{t("section-9-b-1")}</h4>
                <div className="text-muted mt-4">
                  <ul className="list-unstyled footer-list">
                    <li>
                      <a onClick={() => setTermShow(true)} href="#">
                        {t("section-9-b-2")}
                      </a>
                    </li>
                    <li>
                      <a onClick={() => setPrivacyShow(true)} href="#">
                        {t("section-9-b-3")}
                      </a>
                    </li>
                    {/*
                    <li>
                      <a href="#">Jobs</a>
                    </li>
                    <li>
                      <a href="#">Bookmarks</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 mt-4">
                <h4>{t("section-9-c-1")}</h4>
                <div className="text-muted mt-4">
                  <ul className="list-unstyled footer-list">
                    <li>
                      <a href="">beecityapiary.com</a>
                    </li>
                    {/* <li>
                      <a href="">Contact</a>
                    </li>
                    <li>
                      <a href="">Disscusion</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 mt-4">
                <h4>{t("section-9-d-1")}</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  className="text-muted mt-4"
                >
                  <a>
                    <b className="text-white">{t("section-9-d-2")}: </b> Biruni
                    Üniversitesi Teknopark Kazlıçeşme Mah. 245. Sk. No:5 LAB-1
                    Zeytinburnu/İstanbul
                  </a>
                  <a>
                    <b className="text-white">{t("section-9-d-3")}: </b> (0212)
                    671 90 34
                  </a>

                  <a>
                    <b className="text-white">{t("section-9-d-4")}:</b>
                    apiary@beecityglobal.com
                  </a>
                </div>
              </div>
            <SocialMedia/>
            </div>
          </div>
        </footer>

        <div className="footer-alt">
          <div className="container">
            <div className="row">
              <div className="col-bg-25">
                <div className="float-end ">
                  <p className="copy-rights text-muted">
                    Powered by © Giraffe Analytics {format(new Date(), "yyyy")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
