import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import notePng from "../assets/apiary2.jpg";

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import NoteForm from "../components/NoteForm";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from "react-i18next";
import { format } from "date-fns";



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  note: {
    minHeight:"82vh",
    justifyContent:"center",
    
  },
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  noteImg: {
    width: 400,
  },

  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const NewNote = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { addNote } = useApiary();
  const history = useHistory();
  const uid = currentUser?.uid;
  
  const { id } = useParams();
  const currentApiaryId = id;

  // const currentApiaryId = '3bdd7e3e-a97f-4cc3-b915-aa55b4e95c62';
  console.log('id:', id)
  const dateCreate = format(new Date(), "yyyy-MM-dd")

  const handler = (newNote) => {
    try {
      addNote(uid,  {
        ...newNote,
        date : format(new Date(newNote.date), "dd/MM/yyyy")
      },currentApiaryId);
      history.push(`/apiary-notes/${currentApiaryId}`);
      toastSuccessNotify("Note added");
    } catch (error) {
      toastErrorNotify("Note can not be added");
    }
  };

  const note = {
      noteId:uuidv4(),
      uid:uid,
      apiaryId:currentApiaryId,
      date:dateCreate,
      isFinished: false,
      notes:" ", 
  };
  const { t } = useTranslation(["common"]);

  
  return (
    <Container  className= {classes.note} maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <img src={notePng} alt="Note" className={classes.noteImg} />
        </Avatar> */}
        <Typography component="h1" variant="h5" className={classes.title}>
          ── {t("yeninot")} ──
        </Typography>
      </div>
      <NoteForm note={note} handler={handler} />
    </Container>
  );
};

export default NewNote;
