import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";

import NewApiary from "./NewApiary";
import Apiary from "./Apiary";
import UpdateApiaryForTabs from "./UpdateApiaryForTabs";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import amber from '@material-ui/core/colors/amber'
import BackGroundBee from "../assets/apiaries2.jpg";
import ApiaryHex from "./ApiaryHex";





// import clsx from 'clsx';
// import EditIcon from '@material-ui/icons/Edit';
// import Button from '@material-ui/core/Button';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  // container:{
  //   width: "100%",
  //   height: "100%",
  //   position:"relative",
  //   backgroundImage : "linear-gradient(rgba(245,240,187,0.3) 55%, rgba(255,159,41,0.7))",
  // },
  // hiveTabsRoot: {

  //   width: "100%",
  //   minHeight: "100%",
  //   background:  `url(${BackGroundBee}) fixed `,
  //   position:"absolute",
  //   backgroundSize:"contain",
  //   opacity:0.18,
  //   marginTop:"2.8rem"

  // },
  // hiveTabsRootImage:{
  //   backgroundColor:"#D3EBCD",
  //   width:"100%",
  //   minHeight:"100%",
  //   opacity:0.58,
  //   position:"absolute",
  //   left:0,
 
  // },

  hiveNaviRoot: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    // height: "100%",
  },
  // tabPanel:{
  //   backgroundColor:"#efeefe",
  //   "& > *":{
  //     color:"#000000",
  //     fontWeight:900
  //   }
  // },
  // activeTab:{
  //   backgroundColor : "rgba(255,69,0,0.7)"
  // },
  // defaultTab:{
  //   backgroundColor : "#efeefe"
  // },


  hiveNaviSwipe: {
    // height: "100vh",
 
  },
  hiveData: {
    fontSize: theme.spacing(1.7),
  },

  hiveMainRoot: {
    display: "flex",
    minHeight: "80vh",
  },

  hiveButton: {
    height: 60,
    width: 150,
  },

  hiveHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },

  // apiaryTabPanel:{
  //   marginTop:"-3.7rem"
  //   backgroundColor: "#F5F5F5",
  //   backgroundColor: "#FAEFE2",

  // },

  sectionGrid: {
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
  },
  controlGrid: {
    marginTop: theme.spacing(1),
    margin: theme.spacing(0.5),
    // minWidth: 100,
    // width: "auto",
  },

  fab: {
    position: "absolute",
    bottom: theme.spacing(15),
    top: theme.spacing(15),
    right: theme.spacing(2),
    // backgroundColor:"rgba(255,69,0,0.8)"
    
  },
  fabOrange: {
    color: theme.palette.common.white,
    backgroundColor:"rgba(255,69,0,0.8)",
    '&:hover': {
      backgroundColor: "rgba(255,69,0)",
    },
  },

  fabIn: {
    position: "sticky",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },

  totalCard: {
    marginBottom: theme.spacing(5),
  },
}));

export default function ApiaryNavigator() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [isUpdateApiary, setUpdateApiary] = useState(false);
  const [apiaryID, setApiaryID] = useState("");


  const {
    currentHives,
    getUserIsPro,
    userIsPro,
    addUserIsPro
  } = useApiary();

  const { id } = useParams();
  const [apiaryIdFromHive, setApiaryIdFromHive] = useState(id);
  const { t } = useTranslation(["common"]);


  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  console.log("Hive sayfasının id'si : ", id);
  console.log("Apiary id'si : ", apiaryIdFromHive);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const NewApiaryHandler = (uid) => {
    setValue(1);
  };
  const ApiaryTabHandler = (uid) => {
    setValue(0);
  };
  const UpdateTabHandler = (uid) => {
    setValue(2);
  };

  
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
    color: "inherit",
    className: clsx(classes.fab, classes.fabOrange),
    icon: <AddIcon />,
    label: t("Ekle"),
    onClick: () => NewApiaryHandler(uid),
    },
    {
    color: "secondary",
    className: classes.fab,
    icon: <ArrowBackIcon />,
    label: t("Geri"),
    onClick: () => ApiaryTabHandler(uid),
    
    },
    {
    color: "secondary",
    className: classes.fab,
    icon: <ArrowBackIcon />,
    label: t("Geri"),
    onClick: () => ApiaryTabHandler(uid),
    
    },

  ];
  useEffect(() => {
   isUpdateApiary && setValue(2)
  }, [isUpdateApiary])
  useEffect(() => {
   value === 0 && setUpdateApiary(false)
  }, [value])
  useEffect(async () => {
    await getUserIsPro(uid)
  }, [])
  if(userIsPro === undefined ){
   addUserIsPro(uid)

  }
  
  

  return (
   
    <div className={classes.hiveNaviRoot}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          // className={classes.tabPanel}
        >
          <Tab label={t("arılıklar")} {...a11yProps(0)} />
          <Tab  label={t("arılıkekle")} {...a11yProps(1)} />
          <Tab  label={t("arılıkduzenle")} {...a11yProps(2)} />
           {/* <Tab className={classes.defaultTab} label={t("arılıklar")} {...a11yProps(0)} />
          <Tab  className={classes.defaultTab} label={t("arılıkekle")} {...a11yProps(1)} />
          <Tab  className={classes.defaultTab} label={t("arılıkduzenle")} {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        className={classes.hiveNaviSwipe}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} >
          {/* <Apiary setUpdateApiary={setUpdateApiary} setApiaryID={setApiaryID} apiaryID = {apiaryID} /> */}
          <ApiaryHex setValue={setValue} setUpdateApiary={setUpdateApiary} setApiaryID={setApiaryID}/>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <NewApiary setValue={setValue} />
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
            <UpdateApiaryForTabs isUpdateApiary = {isUpdateApiary} apiaryID={apiaryID}/>
        </TabPanel>

      </SwipeableViews>
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              value === index ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Tooltip title={fab.label} aria-label="add">
            <Fab
              aria-label={fab.label}
              className={fab.className}
              color={fab.color}
              onClick={fab.onClick}
            >
              {fab.icon}
            </Fab>
          </Tooltip>
        </Zoom>
      ))}
    </div>
  );
}
