import React from "react";

import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

function SocialMedia() {
const RedTube = red[900];
  return (
    <div style={{ marginTop:'10px', display:'flex',  flexDirection: "row", justifyContent: 'center' }}>
    <div  style={{ width:'20vW', display:'flex',  flexDirection: "row", justifyContent: 'space-between' }}>
  
     <a target="_blank"  href='https://twitter.com/BeecityApiary'>
     <TwitterIcon fontSize='large' style={{color:`${blue[400]}`}} cursor="pointer"/>
     </a>

     <a target="_blank" href='https://www.facebook.com/profile.php?id=100085715457451'>
     <FacebookIcon fontSize='large' style={{color:`${blue[900]}`}} cursor="pointer"/>
     </a>
     
     <a target="_blank"  href='https://www.instagram.com/beecityapiary/'>
     <InstagramIcon fontSize='large' style={{color:`${red[300]}`}} cursor="pointer"/>
     </a>

     <a target="_blank" href='https://www.youtube.com/channel/UC5zzVK2OIvtKNI2Lj3fhANQ'>
     <YouTubeIcon fontSize='large' style={{color:`${red[900]}`}} cursor="pointer"/>
     </a>
     
    </div>
    </div>
  );
}
export default SocialMedia;