import React, { useEffect } from "react";
import { withStyles, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { teal, grey } from "@material-ui/core/colors";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";

import { useTranslation } from "react-i18next";




const styles = theme => ({
  root: {
    flexGrow: 1
  },
  primaryColor: {
    color: teal[200],

  },
  secondaryColor: {
    color: grey[200]
  },

  padding: {
    padding: 0
  },
  mainHeader: {
    backgroundColor: grey[100],
    padding: 20,
    alignItems: "center"
  },
  mainContent: {
    padding: 40
  },
  secondaryContainer: {
    padding: "20px 25px",
    backgroundColor: grey[200]
  }
});
const countries = [
  {
    value: "red",
    label: (<div style={{width:"1rem", height:"1rem", backgroundColor:"red"}}></div>)
  },
  {
    value: "orange",
    label: (<div style={{width:"1rem", height:"1rem", backgroundColor:"orange"}}></div>)
  },
  {
    value: "purple",
    label: (<div style={{width:"1rem", height:"1rem", backgroundColor:"purple"}}></div>)
  },
  {
    value: "green",
    label: (<div style={{width:"1rem", height:"1rem", backgroundColor:"green"}}></div>)
  }
];

function NewCalendarNoteForm(props) {
  const { classes,  handleClose, states,newCalendarNote,setNewCalendarNote,addCalendarNote } = props;
  const { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const {currentApiaries, getApiaries} = useApiary()
  const handleSaveNote = () =>{
    addCalendarNote(uid, newCalendarNote)
    handleClose()
  }
    const { t } = useTranslation(["common"]);


  useEffect(() => {
    getApiaries(uid)
  }, [newCalendarNote])


  return (
    <Dialog
      className={classes.root}
      maxWidth="sm"
      fullWidth
      open={states.openAddingForm}
      onClose={handleClose}
      height="800"
    >
      <DialogContent className={classes.padding}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" className={classes.mainHeader}>
              <Grid item xs={12}>
                <Typography style={{position:"relative"}}  className={classes.primaryColor} variant="h6">
                   {t("takvimefaaliyetekle")}
              <CancelIcon onClick={() => handleClose()} style={{position:"absolute", top:0, right:0, cursor:"pointer"}} />
                </Typography>
              </Grid>
              
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.mainContent}
              spacing={1}
            >
              <Grid item xs={6}>
                <TextField
                  style={{ marginBottom: 20 }}
                  fullWidth
                  type="date"
                  margin="dense"
                  variant="outlined"
                  label={t("baslamazamanı")}
                  value={states.openAddingForm && newCalendarNote.start }
                  id="başlangıç"
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, start: e.target.value })}
                >
                </TextField>
                {!newCalendarNote.allDay && <TextField
                  style={{ marginBottom: 20 }}
                  fullWidth
                  type="time"
                  margin="dense"
                  variant="outlined"
                  label="Başlagıç Saati"
                  value={states.openAddingForm && newCalendarNote.start.split("T")[1]}
                  id="başlangıç"
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, start:newCalendarNote.start[0] + `T${e.target.value}` })}
                >
                </TextField> }
                
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ marginBottom: 20 }}
                  fullWidth
                  type="date"
                  margin="dense"
                  variant="outlined"
                  label={t("bitiszamanı")}
                  value={states.openAddingForm && newCalendarNote.end }
                  id="bitiş"
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, end: e.target.value })}

                >
                </TextField>
                {!newCalendarNote.allDay &&
                        <TextField
                  style={{ marginBottom: 20 }}
                  fullWidth
                  type="time"
                  margin="dense"
                  variant="outlined"
                  label="Bitiş Saati"
                  value={states.openAddingForm && newCalendarNote.end.split("T")[1]}
                  id="başlangıç"
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, end:newCalendarNote.end[0] + `T${e.target.value}` })}
                >
                </TextField>}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label={t("baslık")}
                  id="başlık"
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, title: e.target.value })}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label={t("renk")}
                  select
                  variant="outlined"
                  value={newCalendarNote.backgroundColor}
                  id="color"
                  margin="dense"
                  // helperText={t("renk")}
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, backgroundColor: e.target.value })}
                >
                  {countries.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                labelId="demo-simple-select-label"
                  label={t("arılıkseciniz") + "..."}
                  select
                  variant="outlined"
                  value={newCalendarNote.apiaryName}
                  id="apiary"
                  margin="dense"
                  // helperText={t("arılıkseciniz")}
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, apiaryName: e.target.value })}
                >
                  {currentApiaries?.map(option => (
                    <MenuItem key={option.apiaryId} value={option.apiaryTitle}>
                      {option.apiaryTitle}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
             
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  margin="dense"
                  multiline
                  rows="4"
                  variant="outlined"
                  label={t("acıklamalar")}
                  id="additional-info"
                  onChange={(e) => setNewCalendarNote({ ...newCalendarNote, desc: e.target.value })}
                />
              </Grid>
            </Grid>
          </Grid>
          
            <Grid container style={{display:"flex", justifyContent:"center", width:"100%"}}>
                <Grid item >
                  <Button onClick={handleClose} style={{margin:"0 0.5rem"}} variant="contained" startIcon={<CancelIcon />} color="secondary">{t("iptal")}</Button>
                </Grid>
                <Grid item >
                  <Button onClick={handleSaveNote} style={{margin:"0 0.5rem"}}  variant="contained" startIcon={<SaveIcon />} color="primary">{t("kaydet")}</Button>
                </Grid>
            </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withStyles(styles)(NewCalendarNoteForm);
 