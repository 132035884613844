import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";
import { useHistory } from "react-router-dom";
import { toastErrorNotify } from "../utils/ToastNotify";
import { toastSuccessNotify } from "../utils/ToastNotify";
import "./ApiaryHex.scss";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";

import ApiaryCard from "../components/ApiaryCard";
import loadingGif from "../assets/loading.gif";
import BackImage from "../assets/apiary3.png";
import Load from "../assets/flying_bee.gif";
import Button from "@material-ui/core/Button";

import { DialogActions, DialogContentText } from "@material-ui/core";

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  // apiaryHex:{
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  // },
  // apiaryRoot: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "100%",
  //   // paddingTop: 30,
  //   flexGrow: 1,
  //   "& > *": {
  //     margin: theme.spacing(5),
  //   },
  // },
  // title: {
  //   // flexGrow: 1,
  //   // backgroundColor: 'white',
  //   fontFamily: "Girassol",
  //   textAlign: "center",
  //   fontWeight: "bold",
  //   // color: "#591900",
  //   color: "#1A2406",
  //   // color: "#046582",
  //   // color: "#00000",
  // },
  // apiaryMainRoot: {
  //   display: "flex",
  //   minHeight: "80vh",
  //   // marginTop: 30,
  //   // backgroundImage: `url(${backImage})`,
  //   // backgroundRepeat: "repeat",
  //   // backgroundSize: "auto",
  // },
}));

const ApiaryHex = ({setApiaryID, setUpdateApiary, setValue}) => {
  const classes = useStyles();
  const history = useHistory();
  const { currentApiaries, getApiaries, deleteOneApiary, loading} = useApiary();
  const [openModal, setOpenModal] = useState(false);
  const [isDeleteApiaryId, setIsDeleteApiaryId] = useState("");


  let { currentUser } = useAuth();
  const uid = currentUser.uid;

  const openDetails = (apiaryId) => {
    if (!currentUser) {
      toastErrorNotify("Please Login to get the details");
    }
    // if user doenst exist it is routed to the login page via PrivateRouter
    // history.push(`/hives/${apiaryId}`);
    history.push(`/apiaryNavigator/${apiaryId}`);
  };

  const updateHandler = (apiaryId) => {
    setApiaryID(apiaryId);
    setUpdateApiary(true)
    // history.push(`/update-apiary/${apiaryId}`);
  };
  const deleteHandler = (uid, apiaryId) => {
    deleteOneApiary(uid, apiaryId);
     setOpenModal(false);
    history.push(`/userNavigator/${uid}`);
    toastSuccessNotify("Deleted successfully!");
    console.log("delete handler apiary id:", apiaryId);
  };
  const deleteModalHandler = (uid, apiaryId) => {
    setOpenModal(true);
    setIsDeleteApiaryId(apiaryId)
  }
  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getApiaries(uid);
  }, []);

  console.log("Current Apiaries2:", currentApiaries);

  const { t } = useTranslation(["common"]);

  return (
    <div className="apiary-hex-container">
    <Grid className="apiary-hex">
         <Dialog
            // fullScreen={fullScreen}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
            <h1>{t("Arılık silindiğinde içinde bulunan tüm kovanlar silinecektir.Emin misiniz?")}</h1>
       
             
            </DialogTitle>
            <DialogContent>
              <DialogContentText> </DialogContentText>
            </DialogContent>
            <DialogActions>
          
              <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
              {t("kapat")}
              </Button>
               <Button onClick={() => deleteHandler(uid,isDeleteApiaryId )}  variant="outlined" color="primary" autoFocus>
               {t("sil")}
              </Button>
              
            </DialogActions>
          </Dialog>
      <ul className="hexagon-grid-container">
      {currentApiaries.length === 0  ? (<div>
              <h2>{t("Henüz Bir Arılık Oluşturmadınız...")}</h2>
            <h5>{t("Arılık oluşturmak için")}<b style={{cursor:"pointer"}} onClick={() => setValue(1)}> {t("Tıklayınız...")}</b></h5>
              <img className={classes.noApiary} src={Load} alt="loading" />
            </div>
            ) :  loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              width: "10rem",
              height: "8vh",
            }}
          >
            <img
              style={{ objectFit: "cover", width: "40%", transform:"translateX(-20%)" }}
              src={Load}
              alt="error"
            />
          </div>
        ) : ( 
        currentApiaries === undefined ? (
          <img src={loadingGif} alt="loading" />
        ) : currentApiaries ? (
          currentApiaries.map((item, apiaryId) => (
            <li className="hexagon hexagon-yellow" >
              <div className="hexagon-inner" >
                <img className="hexagon-avatar-img" src={BackImage} onClick={() =>openDetails(item.apiaryId)} />
                <span className="hexagon-name" onClick={() =>openDetails(item.apiaryId)}>{item.apiaryTitle}</span>
                <span className="hexagon-metric-label" onClick={() =>openDetails(item.apiaryId)}>
                  {item.apiaryLocation}
                </span>
                <span className="hexagon-featured-score">
                  {item.numberOfHives}
                </span>
                <span className="hexagon-icon-left hexagon-icon-green" onClick={() =>updateHandler(item.apiaryId)}>
                  <EditIcon />
                </span>
                <span class="hexagon-icon-right hexagon-icon-red" onClick={() =>deleteModalHandler(uid, item.apiaryId)}>
                  <DeleteIcon />
                </span>
              </div>
            </li>
          ))
        ) : (
          <h3>No data available.</h3>
        ))}
      </ul>
    </Grid>
    </div>
  );
};

export default ApiaryHex;
