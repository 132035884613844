import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import MainPage from "./components/MainPage"
import { ParallaxProvider } from 'react-scroll-parallax';


const LandingPage = ({setOnLandingPage}) => {
    // useEffect(() => {
    //   setOnLandingPage(true)
    // }, [])
    
  return (
    <ParallaxProvider>
    <div style={{backgroundColor:"white"}}>
        <MainPage setOnLandingPage={setOnLandingPage} />

    </div>
    </ParallaxProvider>
  )
}

export default LandingPage