import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    backgroundColor: "rgb(29,29,29)",
		display: 'flex',
  },
  
	copyrigth: {
    justifySelf: 'flex-end', 
    display: "none",
    fontFamily: "Girassol",
		color: "wheat",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "& span": {
      fontSize: 20,
      color: "wheat",
    },
  },
	power: {
    flexGrow: 1,
    display: "none",
    fontFamily: "Girassol",
		color: "wheat",
    textAlign: 'start',
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "& span": {
      fontSize: 20,
      color: "wheat",
    },
  },

}));
const Footer= ()=> {
	const classes = useStyles();
	const today = new Date();
	return (
		<footer className={classes.footerRoot}>
			
			<p className={classes.power}>Powered By Giraffe Analytics</p>
			<p className={classes.copyrigth}> Copyrigth &copy; {today.getFullYear()}</p>
	
			
		</footer>
	)
}
export default Footer
