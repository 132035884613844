import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import FeedingIcon from '@material-ui/icons/FilterVintageOutlined';
// import placeholder from "../assets/petek.png";
// import image from "../assets/apiary2.jpg";

import { useApiary } from "../context/ApiaryContextProvider";
// import { useAuth } from "../context/AuthContextProvider";
// import { toastErrorNotify } from "../utils/ToastNotify";
// import { toastSuccessNotify } from "../utils/ToastNotify";

// import { useHistory, useParams } from 'react-router-dom';
// import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: 345,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  module: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "text-overflow": "ellipsis",
    overflow: "hidden",
    textAlign: "left",
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    backgroundColor: "#efeefe",
    // height: "125px",
  },

  harvestCardActions: {
    justifyContent: "space-between",
  },

  title: {
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

 const TotalFeeding= () => {

	const classes = useStyles();
	const { currentFeeding} = useApiary();

  function totalCakeNeed () {
		let total = 0;
		currentFeeding.map((item) => (
			total +=  parseFloat(item.cakeNeed)
		));
		return total}

  function totalSyrupNeed () {
		let total = 0;
		currentFeeding.map((item) => (
			total +=  parseFloat(item.syrupNeed)
		));
		return total}

  function totalFondanNeed () {
		let total = 0;
		currentFeeding.map((item) => (
			total +=  parseFloat(item.fondanNeed)
		));
		return total}

  function totalHoneySyrupNeed () {
		let total = 0;
		currentFeeding.map((item) => (
			total +=  parseFloat(item.honeySyrupNeed)
		));
		return total}

  function totalHoneyCakeNeed () {
		let total = 0;
		currentFeeding.map((item) => (
			total += parseFloat(item.honeyCakeNeed)
		));
		return total}

  function totalOther () {
		let total = 0;
		currentFeeding.map((item) => (
			total +=  parseFloat(item.other)
		));
		return total}

  const { t } = useTranslation(["common"]);

		
	

	console.log('Beslenme Verisi:',currentFeeding )

	return (
		<Card className={classes.cardRoot}>
      <CardActionArea>
        
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {t("toplambeslenme")}
          </Typography>
          <p className={classes.module}>{t("toplamkekmiktarı")}         :{totalCakeNeed()} unit</p>
          <p className={classes.module}>{t("toplamsurupmiktarı")}       :{totalSyrupNeed()} unit</p>
          <p className={classes.module}>{t("toplamfondanmiktarı")}      :{totalFondanNeed()} unit</p>
          <p className={classes.module}>{t("toplamballısurupmiktarı")} :{totalHoneySyrupNeed()} unit</p>
          <p className={classes.module}>{t("toplamballıkekmiktarı")}   :{totalHoneyCakeNeed()} unit</p>
          <p className={classes.module}>{t("toplamdigermiktarı")}       :{totalOther()} unit</p>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.harvestCardActions}>
        <FeedingIcon className={classes.avatar} />
        {/* <Typography gutterBottom variant="h6" component="h2">
          {date}
        </Typography> */}
        {/* <Button
          className={classes.buttonDelete}
          variant="contained"
          onClick={() => deleteHandler()}
                >
         Delete
          </Button> */}
      </CardActions>
    </Card>
	)
}
export default TotalFeeding;
