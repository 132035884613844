import { Button, Center, Flex, Icon, Input, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getWeatherByCity, getWeatherByLocation } from "../redux/actions";
import { HiLocationMarker } from "react-icons/hi";
import { useTranslation } from "react-i18next";



export const Navbar = () => {

    const [city, setCity] = useState("");
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation(["common"]);


    const handleChnage = () => {
        dispatch(getWeatherByCity(city, toast, t("lang"), t));
    }

    const handleLocationData = () => {
        dispatch(getWeatherByLocation(toast, t("lang"), t));
    }

    return (
        <Flex p={'10px'} minH={'70px'} bg={'#DAEAF1'} justifyContent={'center'} flexDirection={['column', 'row']} gap={['10px', '0px']}>
            <Center px={'10px'}>
                <Input
                    // onKeyPress={({ key }) =>  { key === "Enter" ? handleChnage() : undefined }}
                    onInput={(e) => { setCity(e.target.value) }}
                    value={city}
                    borderRadius={'15px 0px 0px 15px'}
                    bg={'white'}
                    _focus={{ 'border': 'none' }}
                    placeholder={t("sehirgiriniz")}
                />
                <Button
                    onClick={handleChnage}
                    borderRadius={'0px 15px 15px 0px'}
                    color={'white'}
                    bg={'#1D1D1D'}
                    _hover={{ 'bg': '#D8D8D8',"color":'black' }}
                >
                    {t("ara")}
                </Button>
            </Center>
            <Center px={'10px'}>
                <Button
                    bg={'#1D1D1D'}
                    _hover={{ 'bg': '#D8D8D8',"color":'black' }}
                    color={'white'}
                    w={'100%'}
                    borderRadius={'15px'}
                    leftIcon={<Icon w={'30px'} h={'30px'} as={HiLocationMarker} />}
                    onClick={handleLocationData}
                >
                    {t("konumunuzagidin")}
                </Button>
            </Center>
        </Flex >
    );
};