import React, { useEffect, useState } from "react";
// import {
//   ParallaxProvider,
//   Parallax,
//   ParallaxBanner,
// } from "react-scroll-parallax";

import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ApiaryCard from "../components/ApiaryCard";
import loadingGif from "../assets/magnifyBee.png";
import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";
import DeleteAlert from "../components/DeleteAlert";
import { toastSuccessNotify } from "../utils/ToastNotify";
import { useHistory } from "react-router-dom";
import BackGroundBee from "../assets/apiary3.png";
import Load from "../assets/flying_bee.gif";
import { Image } from "@mui/icons-material";



const useStyles = makeStyles((theme) => ({

  apiaryRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // paddingTop: 30,
    // flexGrow: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
    // marginTop:"-7rem"
  },

  title: {
    // flexGrow: 1,
    // backgroundColor: 'white',
    fontFamily: "Girassol",
    textAlign: "center",
    fontWeight: "bold",
    // color: "#591900",
    color: "#1A2406",
    // color: "#046582",
    // color: "#00000",
  },
  apiaryMainRoot: {
    display: "flex",
    minHeight: "80vh",
    // marginTop: 30,
    // backgroundImage: `url(${backImage})`,
    // backgroundRepeat: "repeat",
    // backgroundSize: "auto",
  },
  noApiary:{
    width:"20%"
  },
}));

const Apiary = ({setUpdateApiary, setApiaryID , apiaryID}) => {
  const classes = useStyles();
  const {
    currentApiaries,
    getApiaries,
    deleteOneApiary,
    updateTotalHivesList,
    loading
  } = useApiary();
  const [openModal, setOpenModal] = useState(false);
  const [deleteApiary, setDeleteApiary] = useState(false);

  let { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const history = useHistory();

  console.log("uid: ", uid);
  console.log("Current Apiaries:", currentApiaries);

  useEffect(() => {
    getApiaries(uid);
  }, []);

  console.log("Current Apiaries2:", currentApiaries);

  const { t } = useTranslation(["common"]);
  const handleClose = () => {
    setOpenModal(false);
  };
  const deleteHandler = (uid, apiaryId) => {
    deleteOneApiary(uid, apiaryId);
    history.push(`/userNavigator/${uid}`);
    toastSuccessNotify(t("Başarıyla Silindi"));
    console.log("delete handler apiary id:", apiaryId);

  };

  return (
    // <ParallaxProvider>
    <div className={classes.apiaryMainRoot}>

      <Grid container className={classes.apiaryRoot} justifyContent="center">
        <DeleteAlert
          deleteHandler={deleteHandler}
          apiaryID={apiaryID}
          setDeleteApiary={setDeleteApiary}
          handleClose={handleClose}
          openModal={openModal}
        />
        {/* <Typography className={classes.title} variant="h2" noWrap>
              {t("arılıklar")}
            </Typography> */}
        {/* <Parallax speed={-10} > */}
        <Grid item xs={12} md={6} lg={4}>
          <Grid container justifyContent="center" spacing={1}>
            {currentApiaries.length === 0  ? (<div>
              <h5>Henüz Bir Arılık Yok.</h5>
              <img className={classes.noApiary} src={loadingGif} alt="loading" />
            </div>
            ) :  loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              width: "10rem",
              height: "8vh",
            }}
          >
            <img
              style={{ objectFit: "cover", width: "40%", transform:"translateX(-20%)" }}
              src={Load}
              alt="error"
            />
          </div>
        ) : (currentApiaries?.map((item, apiaryId) => (
                <Grid key={apiaryId} item xs={12} md={6} lg={4}>
                  <ApiaryCard
                    setApiaryID={setApiaryID}
                    deleteApiary={deleteApiary}
                    setUpdateApiary={setUpdateApiary}
                    setOpenModal={setOpenModal}
                    item={item}
                  />
                </Grid>
              ))
            )
            
            }
          </Grid>
        </Grid>
        {/* </Parallax> */}
      </Grid>
    </div>
    // </ParallaxProvider>
  );
};

export default Apiary;
