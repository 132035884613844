import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import HiveIcon from "@mui/icons-material/Hive";
import Button from "@material-ui/core/Button";

import placeholder from "../assets/hive.png";
import { useApiary } from "../context/ApiaryContextProvider";
// import { useAuth } from "../context/AuthContextProvider";
import { toastSuccessNotify } from "../utils/ToastNotify";

// import IconButton from "@material-ui/core/IconButton";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import moment from "moment";

const useStyles = makeStyles({
  detailRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 70,
    marginBottom: 20,
  },
  detailCardRoot: {
    minWidth: 250,
    width: "35vw",
    // maxWidth: 700,
  },
  media: {
    // minHeight: "30vh",
    height: "0",
    paddingTop: "56.25%", // 16:9
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    backgroundColor: "#efeefe",
    minHeight: "200px",
  },
  title: {
    fontFamily: "Girassol",
    textAlign: "center",
    margin: 20,
    color: "#046582",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: 20,
  },
  dataStyle: {
    textAlign: "center",
  },
});

const ApiaryDetails = ({ match }) => {
  const classes = useStyles();
  // const { currentUser } = useAuth();
  const history = useHistory();
  const { getOneApiary, deleteOneApiary } = useApiary();

  const result = getOneApiary(match.params.id);
  console.log("Result: ", result);

  const deleteHandler = (apiaryId) => {
    deleteOneApiary(apiaryId);
    history.push(`/apiaries/${apiaryId}`);
    toastSuccessNotify("Deleted successfully!");
  };

  const updateHandler = (apiaryId) => {
    history.push(`/update-apiary/${apiaryId}`);
  };

  return (
    <div className={classes.detailRoot}>
      <Typography className={classes.title} variant="h3" noWrap>
        ──── Detay ────
      </Typography>
      {result?.length > 0 &&
        result?.map((item, index) => (
          <div key={index}>
            <Card className={classes.detailCardRoot}>
              <CardActionArea onClick={updateHandler}>
                <CardMedia
                  className={classes.media}
                  image={item.image || placeholder}
                  title={item.apiaryTitle}
                />
                <CardContent className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.title}
                  >
                    {item.apiaryTitle}
                  </Typography>
                  <p className={classes.module}>Notlar: {item.apiaryNotes}</p>
                  <p className={classes.module}>Yeri: {item.apiaryLocation}</p>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <HiveIcon className={classes.avatar} />
                <Typography gutterBottom variant="h6" component="h2">
                  {item.numberOfHives}
                </Typography>
              </CardActions>
            </Card>
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                onClick={() => updateHandler(item.id)}
              >
                Apiary Control
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => deleteHandler(item.id)}
              >
                Delete Apiary
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ApiaryDetails;
