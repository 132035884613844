import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function VideoSection() {
  return (
		<div style= {{display:'flex',  flexDirection:'row', width:'100vw', justifyContent:'center'}} >
    <Card sx={{ maxWidth: '75vw', my:'4px', justifyContent:'center' }}>
      <CardMedia sx={{ height: '43vw'}}
        component="iframe"
        alt="Beecity Apiary"
        // height="340"
				src="https://www.youtube.com/embed/-3WzSBZ3K_Q" 
				autoPlay=''
				>
			</CardMedia>

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
				Beecity Apiary Intro
        </Typography>
        <Typography variant="body2" color="text.secondary">
				Beekeepers mostly use traditional methods to monitor and document their beehives' progress. Now Beecity Apiary makes monitoring much easier as it allows recording of information on each bee family's identification, health and treatments, and other beekeeping activities on a mobile device. 
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent:'flex-end'}}>
        <Button size="small" target="_blank" href='https://www.youtube.com/channel/UC5zzVK2OIvtKNI2Lj3fhANQ' >Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
		</div>
  );
}
