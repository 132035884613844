import React  from "react";
// import React, { useState, useEffect }  from "react";

// import { firebaseDB } from "../utils/firebaseUtil";
// import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import FeedingCard from "../components/FeedingCard";
import loadingGif from "../assets/loading.gif";
// import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";

 


const useStyles = makeStyles((theme) => ({
  feedingRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(5),
    },
  },

  title: {
    // flexGrow: 1,
    fontFamily: "Girassol",
    textAlign: "center",
    color: "#591900",

  },
  feedingMainRoot: {
    display: "flex",
    // marginTop: 30,
    // backgroundImage: `url(${backImage})`,
    // backgroundRepeat: "repeat",
    // backgroundSize: "cover",
  },
}));

// const feedingId = null;

const Feeding = () => {
  const classes = useStyles();
  const { currentFeeding} = useApiary();
  const { t } = useTranslation(["common"]);

  console.log('Current Feeding:',currentFeeding )

  // const [currentApiaries, setCurrentApiaries] = useState([]);
  // const [loading, setLoading] = useState(true);
  // let { currentUser } = useAuth();

  // const ref = firebaseDB.collection("users");
  // const uid = currentUser.uid;

  // console.log('uid: ', uid)

  // function getApiaries() {
  //   // console.log(apiaryId);
  //   setLoading(true);
  //   ref
  //   .doc(uid)
  //   .collection('apiary')
  //   .onSnapshot((querySnapshot) => {
  //     const items = [];
  //     querySnapshot.forEach((collection) => {
  //       items.push(collection.data());
  //     });
  //     console.log('items :', items)
  //     setCurrentApiaries(items);
  //     setLoading(false);
  //   });
  // }
  // useEffect(() => {
  //   getApiaries();
  // }, []);

  // console.log('Current Apiaries2:',currentApiaries )
  
  // const id = '0955fbf8-10df-4b90-8303-bb0fd38e783e';
  // function getOneApiary(id) {
  //   // const result = currentBlogs?.map((item) => item.id === id);
  //   const result = currentApiaries?.filter((item) => item.apiaryId === id);
  //   console.log('result :', result)
  //   console.log('id :', id)
  //   return result;
  // }
  // useEffect(() => {
  //   getOneApiary(id);
  // }, []);

  return (
    <div className={classes.feedingMainRoot}>
        <Grid container className={classes.feedingRoot} justifyContent="center">
        <Typography className={classes.title} variant="h3" noWrap>
        {t("hasatdurumu")}
        </Typography>
          <Grid item xs={12} >
            <Grid container justifyContent="center" spacing={5}>
              {currentFeeding === undefined ? (
                <img src={loadingGif} alt="loading" />
              ) : currentFeeding ? (
                currentFeeding.map((item, feedingId) => (
                  <Grid key={feedingId} item>
                    <FeedingCard item={item} />
                  </Grid>
                ))
              ) : (
                <h3>No data available.</h3>
              )}
            </Grid>
          </Grid>
        </Grid>
     </div>
  );
};

export default Feeding;
