import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import format from "date-fns/format";


const useStyles = makeStyles((theme) => ({
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  harvestForm: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  harvestGrid: {
    backgroundColor: "white",
    marginBottom: '5px',
  },
  submit: {
    margin: theme.spacing(3, 0),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },
  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const HarvestForm = (props) => {
  const { harvest, handler, newHarvest, setNewHarvest } = props;
  const classes = useStyles();
  const { t } = useTranslation(["common"]);


 

  return (
    <form className={classes.harvestForm} onSubmit={() => handler(newHarvest)}>
      <Grid container spacing={3}>

{/* Honey Amount */}
        <Grid className={classes.harvestGrid} item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="singleHoneyAmount"
            label={t("balmiktarı")+'(kg)'} 
            name="singleHoneyAmount"
            value={newHarvest.singleHoneyAmount}
            autoFocus
            onChange={(e) => setNewHarvest({ ...newHarvest, singleHoneyAmount: e.target.value*1 })}
          />
        </Grid>

{/* Propolis Amount */}
        <Grid className={classes.harvestGrid} item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="singlePropolisAmount"
            label={t("propolismiktarı")+'(gr)'}
            name="singlePropolisAmount"
            value={newHarvest.singlePropolisAmount}
            autoFocus
            onChange={(e) => setNewHarvest({ ...newHarvest, singlePropolisAmount: e.target.value*1 })}
          />
        </Grid>

{/* Polen Amount */}
        <Grid className={classes.harvestGrid} item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="singlePolenAmount"
            label={t("polenmiktarı")+'(gr)'}
            name="singlePolenAmount"
            value={newHarvest.singlePolenAmount}
            autoFocus
            onChange={(e) => setNewHarvest({ ...newHarvest, singlePolenAmount: e.target.value*1 })}
          />
        </Grid>

{/* Bee Wax Amount */}
        <Grid className={classes.harvestGrid} item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="singleBeeWaxAmount"
            label={t("arımumumiktarı")+'(kg)'}
            name="singleBeeWaxAmount"
            value={newHarvest.singleBeeWaxAmount}
            autoFocus
            onChange={(e) => setNewHarvest({ ...newHarvest, singleBeeWaxAmount: e.target.value*1 })}
          />
        </Grid>

{/* Bee Milk Amount */}
        <Grid className={classes.harvestGrid} item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="singleBeeMilkAmount"
            label={t("arısutumiktarı")+'(gr)'}
            name="singleBeeMilkAmount"
            value={newHarvest.singleBeeMilkAmount}
            autoFocus
            onChange={(e) => setNewHarvest({ ...newHarvest, singleBeeMilkAmount: e.target.value*1 })}
          />
        </Grid>

{/* Bee Venom Amount */}
        <Grid className={classes.harvestGrid} item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="singleBeeVenomAmount"
            label={t("arızehrimiktarı")+'(gr)'}
            name="singleBeeVenomAmount"
            value= {newHarvest.singleBeeVenomAmount}
            autoFocus
            onChange={(e) => setNewHarvest({ ...newHarvest, singleBeeVenomAmount: e.target.value*1 })}
          />
        </Grid>

{/* Date */}
        <Grid className={classes.harvestGrid} item xs={12}>
          <TextField
          id="date"
          label={t("tarihsec")}
          type="date"
          value={newHarvest.date}
          className={classes.dateField}
          onChange={(e) => setNewHarvest({ ...newHarvest, date: e.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
          />
        </Grid>
      </Grid>
      
{/* Onay Düğmesi */}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
      >
        {t("onay")}
      </Button>
    </form>
  );
};

export default HarvestForm;
