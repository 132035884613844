import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import HarvestIcon from '@mui/icons-material/Inventory2Outlined';
// import placeholder from "../assets/petek.png";
// import image from "../assets/apiary2.jpg";

import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";
import { toastErrorNotify } from "../utils/ToastNotify";
import { toastSuccessNotify } from "../utils/ToastNotify";

import { useHistory, useParams } from 'react-router-dom';
// import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
import { format } from "date-fns";




const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: 345,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  module: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "text-overflow": "ellipsis",
    overflow: "hidden",
    textAlign: "left",
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    backgroundColor: "#efeefe",
    // height: "125px",
  },
  harvestCardActions: {
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const HarvestCard = ({ item ,harvestFromSidebar }) => {
  const {
    harvestId,
    singleHoneyAmount,
    singlePropolisAmount,
    singlePolenAmount,
    singleBeeWaxAmount,
    singleBeeMilkAmount,
    singleBeeVenomAmount,
    date,
    apiaryId,
    hiveId
  } = item;

  const classes = useStyles();
  const history = useHistory();
  const { deleteOneHarvest, apiaryIdFromHive,oneHarvest } = useApiary();

  const { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const { id } = useParams();
  const apiaryIdFromHarvest = apiaryId;
  const hiveIdFromHarvest = hiveId;
  const hiveIdFromHive = id;

  const { t } = useTranslation(["common"]);


  // const openDetails = () => {
  //   if (!currentUser) {
  //     toastErrorNotify("Please Login to get the details");
  //   }
  //   // if user doenst exist it is routed to the login page via PrivateRouter
  //   history.push(`/hiveNavigator/${hiveId}`);
  // };

 
 
  

  const handleDeleteAndUpdateHarvest =  (uid, apiaryId, hiveId, harvestId) =>{
    deleteOneHarvest(uid , apiaryId = apiaryIdFromHarvest ? apiaryIdFromHarvest : apiaryIdFromHive , hiveId = hiveIdFromHarvest ? hiveIdFromHarvest : hiveIdFromHive, harvestId);  
   toastSuccessNotify(t("Başarıyla Silindi"));
   history.push({
    pathname: `/hiveNavigator/${hiveIdFromHarvest ? hiveIdFromHarvest : hiveIdFromHive}`,
    state: [1, "deleteHarvestActive"],
  });

  }




  return (
    <Card className={classes.cardRoot}>
      <CardActionArea >
        {/* <CardMedia
          className={classes.media}
          image={image || placeholder}
          title='Hasat Durumu'
        /> */}

        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {t("hasattarihi")}: {date}
          </Typography>
          <p className={classes.module}>{t("balmiktarı")}        : {singleHoneyAmount} kg</p>
          <p className={classes.module}>{t("propolismiktarı")}   : {singlePropolisAmount} gr</p>
          <p className={classes.module}>{t("polenmiktarı")}  : {singlePolenAmount} gr</p>
          <p className={classes.module}>{t("arımumumiktarı")}  : {singleBeeWaxAmount} kg</p>
          <p className={classes.module}>{t("arısutumiktarı")}  : {singleBeeMilkAmount} gr</p>
          <p className={classes.module}>{t("arızehrimiktarı")}  : {singleBeeVenomAmount} gr</p>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.harvestCardActions}>
        <HarvestIcon className={classes.avatar} />
        {/* <Typography gutterBottom variant="h6" component="h2">
          {date}
        </Typography> */}
        <Button
          className={classes.buttonDelete}
          variant="contained"
          onClick={() => handleDeleteAndUpdateHarvest(uid, apiaryId, hiveId, harvestId) }
        >
         {t("sil")}
          </Button>
      </CardActions>
    </Card>
  );
};

export default HarvestCard;
