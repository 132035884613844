import React, { useEffect, useState,useLayoutEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./Calendar.css";
import OneCalendarNoteModal from "./CalendarNoteCard";
import { useAuth } from "../context/AuthContextProvider";
import { firebaseDB } from "../utils/firebaseUtil";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import NewCalendarNoteForm from "./NewCalendarNoteForm";
import Bee from "../assets/flying_bee.gif";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";



const Calendar = () => {
  const currentModals = {
    currentEvents: [],
    openModal: false,
    openAddingForm: false,
    startTime: "",
    endTime: "",
    eventTitle: " ",
    id: "",
  };
  const [states, setStates] = useState(currentModals);

  const { currentUser } = useAuth();

  const ref = firebaseDB.collection("users");

  const [newCalendarNote, setNewCalendarNote] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
    allDay: "",
  });
  const { t } = useTranslation(["common"]);


  const [currentCalendarNotes, setCurrentCalendarNotes] = useState([]);
  const uid = currentUser?.uid;
  const [loading, setLoading] = useState(true);

  // CRUD FUNCTIONS
  function getCalendarNote(uid) {
    // console.log(apiaryId);
    setLoading(true);
    ref
      .doc(uid)
      .collection("calendarNote")
      .onSnapshot((querySnapshot) => {
       
        const items = [];
        querySnapshot.forEach((collection) => {
          items.push(collection.data());
          //  setDocs(...docs, collection.data())
        });
        setCurrentCalendarNotes(items);
        setLoading(false);
      });
  }
  useLayoutEffect(() => {
    getCalendarNote(uid);
  }, []);

  function deleteOneCalendarNote(uid, calendarId) {
    ref
      .doc(uid)
      .collection("calendarNote")
      .doc(calendarId)
      .delete()
      // .then(() => {
      //   setCurrentCalendarNotes((prev) =>
      //     prev.filter((element) => element.calendarId !== calendarId)
      //   );
      // })
      .catch((err) => {
        console.error(err);
      });
  }

  function addCalendarNote(uid, newCalendarNote) {
    ref
      .doc(uid)
      .collection("calendarNote")
      .doc(newCalendarNote.id)
      .set(newCalendarNote)
      // .then(() => {
      //   setCurrentCalendarNotes((prev) => [newCalendarNote, ...prev]);
      // })
      .catch((err) => {
        console.error(err);
      });
  }
  function updateCalendarNote(calendarNote) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("calendarNote")
      .doc(calendarNote.id)
      .update(calendarNote)
      // .then(() => {
      //   setCurrentCalendarNotes((prev) =>
      //     prev.map((element) => {
      //       if (element.id !== calendarNote.id) {
      //         return element;
      //       }
      //       return calendarNote;
      //     })
      //   );
      // })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleDateSelect = (selectInfo) => {
    console.log(selectInfo);

    setStates({ openAddingForm: true, openModal: false });
    setNewCalendarNote({
      id: uuidv4(),
      title: " ",  
      start: selectInfo === "today" ? format(new Date(), "yyyy-MM-dd") : selectInfo.startStr,
      end:  selectInfo === "today" ? format(new Date().setDate(new Date().getDate() + 1) , `yyyy-MM-dd` ) : selectInfo.endStr,
      allDay:selectInfo === "today" ? true : selectInfo.allDay,
      backgroundColor: " ",
      desc: " ",
      apiaryName: " ", 
    });
  };
  const handleEventClick = (eventInfo) => {
    console.log("Event Clicked:", eventInfo);

    setStates({
      openModal: true,
      eventTitle: eventInfo.event.title, 
      startTime: eventInfo.event.start,
      endTime: eventInfo.event.end,
      id: eventInfo.event.id,
      openAddingForm: false,
    });
  };
  const handleClose = () => {
    setStates({ openAddingForm: false, openModal: false });
  };

  const handleChange = (eventInfo) => {
    const [selectedCalendarNote] = currentCalendarNotes?.filter(
      (note) => note.id === eventInfo.event.id
    );
    const newSelectedNotes = {
      ...selectedCalendarNote,
      id: selectedCalendarNote.id,
      title: selectedCalendarNote.title,
      start: eventInfo.event.startStr,
      end: eventInfo.event.endStr,
      allDay: eventInfo.event.allDay,
      backgroundColor: selectedCalendarNote.backgroundColor,
      desc: selectedCalendarNote.desc,
      apiaryName: selectedCalendarNote.apiaryName,
    };

    updateCalendarNote(newSelectedNotes);
  };
  console.log(newCalendarNote, "newCalendarNote");

  return (
    <div className="calendar">
      <div className="calendar-main">
        {/* <h1>{t("arıcıtakvimi")}</h1> */}
        <OneCalendarNoteModal
          currentCalendarNotes={currentCalendarNotes}
          uid={uid}
          deleteOneCalendarNote={deleteOneCalendarNote}
          states={states}
          handleClose={handleClose}
        />
        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="loading-bee" src={Bee} alt="" />
          </div>
        )}
        <NewCalendarNoteForm
          addCalendarNote={addCalendarNote}
          setNewCalendarNote={setNewCalendarNote}
          newCalendarNote={newCalendarNote}
          states={states}
          handleClose={handleClose}
        />
        <div className="fullcalendarContainer">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today btn",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          customButtons={{
            btn: {
              text: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>{t("yeninot")}</span>
                  <AddIcon className="add-icon" />
                </div>
              ),
              click: () =>
              handleDateSelect("today"),
            },
          }}
          aspectRatio="2"
          buttonText={{
            today: t("bugun"),
            month: t("aylık"),
            week: t("haftalık"),
            day: t("gunluk"),
          }}
          allDayText={t("tumgun")}
          initialView="dayGridMonth"
          locale={t("lang")}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          firstDay="1"
          events={currentCalendarNotes} // alternatively, use the `events` setting to fetch from a feed
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventChange={handleChange}
          //   eventAdd={addCalendarNote}
          //   eventMouseEnter
          /* you can update a remote database when these fire:
            eventRemove={function(){}}
            */
        />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
