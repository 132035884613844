import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  feedingForm: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: "white",
    borderRadius: "10px",
  },
  feedingGrid: {
    // backgroundColor: "white",
    marginBottom: "5px",
  },
  feedingFormSection: {
    // backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    // flexWrap: "wrap",
  },
  submit: {
    margin: theme.spacing(3, 0),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },
  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const FeedingForm = (props) => {
  const { feeding, handler } = props;
  const classes = useStyles();
  const [newFeeding, setNewFeeding] = useState(feeding);
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    setNewFeeding(feeding);
  }, [feeding]);

  // cakeUnit,
  // syrupUnit,
  // fondanUnit,
  // honeySyrupUnit,
  // honeyCakeUnit,
  // otherUnit,

  return (
    <form className={classes.feedingForm} onSubmit={() => handler(newFeeding)}>
      <Grid container spacing={3}>
        {/* Cake Need */}
        <Grid className={classes.feedingFormSection} item xs={12}>
          <Grid className={classes.feedingGrid} item xs={8}>
            <TextField
              variant="outlined"
              type="number"
              fullWidth
              required
              id="cakeNeed"
              label={t("kekihtiyacı")}
              name="cakeNeed"
              value={newFeeding.cakeNeed}
              autoFocus
              onChange={(e) =>
                setNewFeeding({ ...newFeeding, cakeNeed: e.target.value * 1 })
              }
            />
          </Grid>
          <Grid className={classes.feedingGrid} item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="cakeUnit">{t("birim")}</InputLabel>
              <Select
                placeholder="Birim Seç"
                labelId="cakeUnit"
                id="cake-unit"
                autoFocus
                required
                value={newFeeding.cakeUnit}
                onChange={(e) =>
                  setNewFeeding({ ...newFeeding, cakeUnit: e.target.value })
                }
                label="Cake Unit"
              >
                <MenuItem value={"kg"}>
                  <em>kg</em>
                </MenuItem>
                <MenuItem value={"gr"}>gr</MenuItem>
                <MenuItem value={"pound"}>pound</MenuItem>
                <MenuItem value={"litre"}>litre</MenuItem>
                <MenuItem value={"galon"}>galon</MenuItem>
                <MenuItem value={"unit"}>unit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Syrup Need */}
        <Grid className={classes.feedingFormSection} item xs={12}>
          <Grid className={classes.feedingGrid} item xs={8}>
            <TextField
              variant="outlined"
              type="number"
              fullWidth
              required
              id="syrupNeed"
              label={t("surupihtiyacı")}
              name="syrupNeed"
              value={newFeeding.syrupNeed}
              autoFocus
              onChange={(e) =>
                setNewFeeding({ ...newFeeding, syrupNeed: e.target.value * 1 })
              }
            />
          </Grid>
          <Grid className={classes.feedingGrid} item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="syrupUnit">{t("birim")}</InputLabel>
              <Select
                placeholder="Birim Seç"
                labelId="syrupUnit"
                id="syrup-unit"
                autoFocus
                required
                value={newFeeding.syrupUnit}
                onChange={(e) =>
                  setNewFeeding({ ...newFeeding, syrupUnit: e.target.value })
                }
                label="Syrup Unit"
              >
                <MenuItem value={"kg"}>
                  <em>kg</em>
                </MenuItem>
                <MenuItem value={"gr"}>gr</MenuItem>
                <MenuItem value={"pound"}>pound</MenuItem>
                <MenuItem value={"litre"}>litre</MenuItem>
                <MenuItem value={"galon"}>galon</MenuItem>
                <MenuItem value={"unit"}>unit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Fondan Need */}
        <Grid className={classes.feedingFormSection} item xs={12}>
          <Grid className={classes.feedingGrid} item xs={8}>
            <TextField
              variant="outlined"
              type="number"
              fullWidth
              required
              id="fondanNeed"
              label={t("fondanihtiyacı")}
              name="fondanNeed"
              value={newFeeding.fondanNeed}
              autoFocus
              onChange={(e) =>
                setNewFeeding({ ...newFeeding, fondanNeed: e.target.value * 1 })
              }
            />
          </Grid>
          <Grid className={classes.feedingGrid} item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="fondanUnit">{t("birim")}</InputLabel>
              <Select
                placeholder="Birim Seç"
                labelId="fondanUnit"
                id="fondan-unit"
                autoFocus
                required
                value={newFeeding.fondanUnit}
                onChange={(e) =>
                  setNewFeeding({ ...newFeeding, fondanUnit: e.target.value })
                }
                label="Fondan Unit"
              >
                <MenuItem value={"kg"}>
                  <em>kg</em>
                </MenuItem>
                <MenuItem value={"gr"}>gr</MenuItem>
                <MenuItem value={"pound"}>pound</MenuItem>
                <MenuItem value={"litre"}>litre</MenuItem>
                <MenuItem value={"galon"}>galon</MenuItem>
                <MenuItem value={"unit"}>unit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Honey Syrup Need */}

        <Grid className={classes.feedingFormSection} item xs={12}>
          <Grid className={classes.feedingGrid} item xs={8}>
            <TextField
              variant="outlined"
              type="number"
              fullWidth
              required
              id="honeySyrupNeed"
              label={t("ballısurupihtiyacı")}
              name="honeySyrupNeed"
              value={newFeeding.honeySyrupNeed}
              autoFocus
              onChange={(e) =>
                setNewFeeding({
                  ...newFeeding,
                  honeySyrupNeed: e.target.value * 1,
                })
              }
            />
          </Grid>
          <Grid className={classes.feedingGrid} item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="honeySyrupUnit">{t("birim")}</InputLabel>
              <Select
                placeholder="Birim Seç"
                labelId="honeySyrupUnit"
                id="Honey-Syrup-unit"
                autoFocus
                required
                value={newFeeding.honeySyrupUnit}
                onChange={(e) =>
                  setNewFeeding({
                    ...newFeeding,
                    honeySyrupUnit: e.target.value,
                  })
                }
                label="Honey Syrup Unit"
              >
                <MenuItem value={"kg"}>
                  <em>kg</em>
                </MenuItem>
                <MenuItem value={"gr"}>gr</MenuItem>
                <MenuItem value={"pound"}>pound</MenuItem>
                <MenuItem value={"litre"}>litre</MenuItem>
                <MenuItem value={"galon"}>galon</MenuItem>
                <MenuItem value={"unit"}>unit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Honey Cake Need */}

        <Grid className={classes.feedingFormSection} item xs={12}>
          <Grid className={classes.feedingGrid} item xs={8}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="honeyCakeNeed"
            label={t("Ballı Kek İhtiyacı")}
            name="honeyCakeNeed"
            value={newFeeding.honeyCakeNeed}
            autoFocus
            onChange={(e) =>
              setNewFeeding({
                ...newFeeding,
                honeyCakeNeed: e.target.value * 1,
              })
            }
          />
          </Grid>
          <Grid className={classes.feedingGrid} item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="honeyCakeUnit">{t("birim")}</InputLabel>
              <Select
                placeholder="Birim Seç"
                labelId="honeyCakeUnit"
                id="Honey-Cake-Unit"
                autoFocus
                required
                value={newFeeding.honeyCakeUnit}
                onChange={(e) =>
                  setNewFeeding({
                    ...newFeeding,
                    honeyCakeUnit: e.target.value,
                  })
                }
                label="Honey Cake Unit"
              >
                <MenuItem value={"kg"}>
                  <em>kg</em>
                </MenuItem>
                <MenuItem value={"gr"}>gr</MenuItem>
                <MenuItem value={"pound"}>pound</MenuItem>
                <MenuItem value={"litre"}>litre</MenuItem>
                <MenuItem value={"galon"}>galon</MenuItem>
                <MenuItem value={"unit"}>unit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Other */}

        <Grid className={classes.feedingFormSection} item xs={12}>
          <Grid className={classes.feedingGrid} item xs={8}>
          <TextField
            variant="outlined"
            type="number"
            fullWidth
            required
            id="other"
            label={t("diger")}
            name="other"
            value={newFeeding.other}
            autoFocus
            onChange={(e) =>
              setNewFeeding({ ...newFeeding, other: e.target.value * 1 })
            }
          />
          </Grid>
          <Grid className={classes.feedingGrid} item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel id="otherUnit">{t("birim")}</InputLabel>
              <Select
                placeholder="Birim Seç"
                labelId="otherUnit"
                id="other-Unit"
                autoFocus
                required
                value={newFeeding.otherUnit}
                onChange={(e) =>
                  setNewFeeding({
                    ...newFeeding,
                    otherUnit: e.target.value,
                  })
                }
                label="Other"
              >
                <MenuItem value={"kg"}>
                  <em>kg</em>
                </MenuItem>
                <MenuItem value={"gr"}>gr</MenuItem>
                <MenuItem value={"pound"}>pound</MenuItem>
                <MenuItem value={"litre"}>litre</MenuItem>
                <MenuItem value={"galon"}>galon</MenuItem>
                <MenuItem value={"unit"}>unit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* Date */}
        <Grid className={classes.feedingFormSection} item xs={12}>
        <Grid className={classes.feedingGrid} item xs={12}>
          <TextField
            id="date"
            label={t("tarihsec")}
            type="date"
            value={newFeeding.date}
            className={classes.dateField}
            onChange={(e) =>
              setNewFeeding({ ...newFeeding, date: e.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        </Grid>
      </Grid>

      {/* Onay Düğmesi */}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
      >
        {t("onay")}
      </Button>
    </form>
  );
};

export default FeedingForm;
