import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import StoreBadge from "react-store-badge";


const AboutUsModal = (props) =>  {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Uygulama Hakkında
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4></h4>
        <p>
        Arıcılar, kovan kontrollerini yapmak, arı koloni gelişimi  takip etmek ve notlar çıkarmak için çoğunlukla geleneksel yöntemler kullanırlar. Artık bu geleneksel yöntemleri unutun. Beecity Apiary ile  arılığınızdaki kovanların tüm kontrollerini yapmak ve ihtiyaçlarını kayıt altına almak çok daha kolay. 

Beecity Apiary, arı kovanı kayıtlarınızı yönetmenize yardımcı olabilecek kullanıcı dostu bir uygulamadır. Kovanlarınız hakkında önemli arıcılık bilgilerini takip etmenizi sağlar. Bu uygulamanın sunduğu ana özelliklerden biri, hasat, beslenme ve ekipman raporlarını arılık düzeyinde takip etme yeteneğidir. Arıcılığı daha profesyonel hale getirmek için farklı grafikler, raporlar ve gelişmiş analitik bilgileri kullanarak arılarınızı takip edin.

Beecity Apiary ile ana arı durumunu, oğul verme, kuluçka gelişimi, koloni gücü ve sağlığını kaydedip izleyebilirsiniz. Kovanlarınızı incelerken arılar ve kovan hakkında kendi yorumlarınızı da ekleyebilirsiniz. Arılıklarınız konumunu haritada işaretleyerek gerçek zamanlı hava durumunu görebilirsiniz.

Beecity Apiary, arı kovanlarınızın toplam hasadını izlemenize yardımcı olur. Her kovan için tek tek hasat raporu eklediğinizde, uygulama tüm verileri arılık düzeyinde toplar. Böylece hasat raporlarınızı yıllık olarak kolayca takip edebilirsiniz.

Beecity Apiary'ye şimdi kaydolun; kovanlarınızı kolayca planlayın ve takip edin.

        </p>
        <StoreBadge
                        name="Beecity Apiary"
                        googlePlayUrl="https://play.google.com/store/apps/details?id=com.arikent.apiary"
                        appStoreUrl="https://apps.apple.com/us/app/beecity-apiary/id1633088644"
                      />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Kapat</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default AboutUsModal;