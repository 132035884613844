import { Container, Image } from "@chakra-ui/react";
import ErrorImage from "../weatherAssets/images/Error.gif"

export const Error = () => {

    return (
        <Container mt={['200px', '100px']} p={'100px'}>
            <Image src={ErrorImage} />
        </Container>
    );
};