import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";

import HarvestCard from "../components/HarvestCard";
import TotalHarvest from "../components/TotalHarvest";
import FeedingCard from "../components/FeedingCard";
import TotalFeeding from "../components/TotalFeeding";
import loadingGif from "../assets/loading.gif";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";

import { useTranslation } from "react-i18next";
import { format, yearsToMonths } from "date-fns";

 


// import clsx from 'clsx';
// import EditIcon from '@material-ui/icons/Edit';
// import Button from '@material-ui/core/Button';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
    hiveNaviRoot: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: '100%',
  },
  hiveNaviSwipe: {
      height: '100vh',
  },
  hiveData: {
      fontSize: theme.spacing(1.7)
  },
  
  sectionGrid: {
    justifyContent: "space-between",
    display: 'flex',
    flexWrap: "wrap",
  },
  controlGrid: {
    // marginTop: theme.spacing(1),
    // margin: theme.spacing(0.5),
    // minWidth: 100,
    // width: "auto",
  },
  hr:{
    color: 'blue',
  },

  fab: {
    position: 'absolute',
    bottom: theme.spacing(15),
    top: theme.spacing(20),
    right: theme.spacing(2),
  },
  fabIn: {
    position: 'sticky',
    top: theme.spacing(1),
    right: theme.spacing(2),
  },

  totalCard: {
    marginBottom:theme.spacing(5),
  },
}));

export default function HiveNavigator() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { apiaryIdFromHive, currentHives, currentHarvest, currentFeeding, getHarvest, getFeeding } = useApiary();
  const [year, setYear] = useState(format(new Date(), "dd/MM/yyyy").slice(6,10))
  const [filteredHarvest, setFilteredHarvest] = useState([])

  const { t } = useTranslation(["common"]);
  
  console.log('Hiveden gelen Apiary Id:', apiaryIdFromHive )
  
  const { id } = useParams();
  const location = useLocation();
  console.log('Hive Nlocationlocation:', location)

  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  console.log('currentHarvest:', currentHarvest)
  console.log('Current Harvets Nav:', currentHarvest[0])
  console.log('Current Feeding Nav:', currentFeeding)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const handleHarvest = () => {
  //   return(alert('Henüz Hasat Verileri Sayfası Hazırlanmadı...'));
  // };
  
  const handleUpdateHive =()=>{
    history.push(`/update-hive/${id}`);
    console.log("Harvest handler id'si : ", id)
  }

  const handleAddHarvest =()=>{
    history.push(`/new-harvest/${id}`);
    console.log("Harvest handler id'si : ", id)
  }

  const handleAddFeeding =()=>{
    history.push(`/new-feeding/${id}`);
    console.log("Feeding handler id'si : ", id)
  }

  // const handleFeeding = () => {
  //   return(alert('Henüz Beslenme Verileri Sayfası Hazırlanmadı...'));
  // };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };


  const hiveResult = currentHives?.filter((item) => (item.hiveId === id))
  useEffect(() => {
    const currentHarvestByYear = currentHarvest?.filter(e => e.date.slice(6,10) == year)
    setFilteredHarvest(currentHarvestByYear)
    console.log("currentHarvestyList is here", currentHarvest)
   
  }, [currentHarvest, year])

useEffect(() => {
  location.state !== undefined && location?.state[0] === 1 && setValue( location?.state[0])
}, [])


  
  // console.log('Result:', hiveResult)
  // console.log('Result Hive Number:', hiveResult[0].hiveNumber)
  // console.log('Result Id:', id)

  const fabs = [
    {
      color: 'secondary',
      className: classes.fab,
      icon: <EditIcon />,
      label: 'Edit',
      title: 'Düzenle',
      onClick: handleUpdateHive,
      
    },
    {
      color: 'primary',
      className: classes.fab,
      icon: <AddIcon />,
      label: 'Add',
      title: 'Ekle',
      onClick: handleAddHarvest,
    },
    {
      color: 'primary',
      className: classes.fab,
      icon: <AddIcon />,
      label: 'Add',
      title: 'Ekle',
      onClick: handleAddFeeding,
    },
  ];
  useEffect(() => {
    getHarvest(uid, apiaryIdFromHive, id);
    getFeeding(uid, apiaryIdFromHive, id);
  },[]);
 

  return (
    <div className={classes.hiveNaviRoot}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t("kovanverileri")} {...a11yProps(0)} />
          <Tab label={t("hasatverileri")} {...a11yProps(1)} />
          <Tab label={t("beslenmedurumu")} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        className={classes.hiveNaviSwipe}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
        <Grid className={classes.sectionGrid}> 
          <Grid className={classes.controlGrid} item xs={12} md={6} lg={3}> 
          <h4>{t("kovandurumu")}</h4>
          <hr></hr>
          <p className={classes.hiveData} >{t("kovannumarası")}: {hiveResult[0].hiveNumber =='Seçiniz'? ('-'): hiveResult[0].hiveNumber }</p> 
          <p className={classes.hiveData} >{t("populasyon")}: {hiveResult[0].colonyState =='Seçiniz'? ('-'): t(hiveResult[0].colonyState)}</p> 
          <p className={classes.hiveData} >{t("arıırkı")}: {hiveResult[0].race =='Seçiniz'? ('-'): t(hiveResult[0].race)}</p> 
          <p className={classes.hiveData} >{t("saglıkdurumu")}: {hiveResult[0].healthStatus =='Seçiniz'? ('-'): t(hiveResult[0].healthStatus)}</p>
          <p className={classes.hiveData}>{t("acıklamalar")}:{hiveResult[0].colonyDescription}</p>
          </Grid>
          <hr></hr> 
          <Grid className={classes.controlGrid} item xs={12} md={6} lg={3}>
          <h4>{t("anaarıdurumu")}</h4>
          <hr></hr>
          <p className={classes.hiveData} >{t("anaarıdurumu")}: {hiveResult[0].queenExist =='Seçiniz'? ('-'): t(hiveResult[0].queenExist)}</p> 
          <p className={classes.hiveData} >{t("anaarıdogumyılı")}: {hiveResult[0].queenAge =='Seçiniz'? ('-'): hiveResult[0].queenAge}</p> 
          <p className={classes.hiveData} >{t("anaarırengi")}: {hiveResult[0].queenColor =='Seçiniz'? ('-'): t(hiveResult[0].queenColor)}</p> 
          <p className={classes.hiveData} >{t("yumurtadurumu")}: {hiveResult[0].dailyEgg =='Seçiniz'? ('-'): t(hiveResult[0].dailyEgg)}</p> 
          <p className={classes.hiveData} >{t("larvadurumu")}: {hiveResult[0].larvaStatus =='Seçiniz'? ('-'): t(hiveResult[0].larvaStatus)}</p> 
          <p className={classes.hiveData} >{t("pupadurumu")}: {hiveResult[0].pupaStatus =='Seçiniz'? ('-'): t(hiveResult[0].pupaStatus)}</p>
          <p className={classes.hiveData} >{t("anaarımemesi")}: {hiveResult[0].queenCellStatus =='Seçiniz'? ('-'): t(hiveResult[0].queenCellStatus)}</p> 
          <p className={classes.hiveData} >{t("erkekarıdurumu")}: {hiveResult[0].maleBeeStatus =='Seçiniz'? ('-'): t(hiveResult[0].maleBeeStatus)}</p>
          <p className={classes.hiveData}>{t("acıklamalar")}:{hiveResult[0].description}</p>
          </Grid> 
          <hr></hr>
          <Grid className={classes.controlGrid} item xs={12} md={6} lg={3}>
          <h4>{t("ekipmanihtiyacı")}</h4>
          <hr></hr>
          <p className={classes.hiveData} >{t("ızgaraihtiyacı")}: {hiveResult[0].queenGridNeed =='Seçiniz'? ('-'): hiveResult[0].queenGridNeed}</p> 
          <p className={classes.hiveData} >{t("katmiktarı")}: {hiveResult[0].superQuantity =='Seçiniz'? ('-'): hiveResult[0].superQuantity}</p> 
          <p className={classes.hiveData} >{t("katihtiyacı")}: {hiveResult[0].superNeed =='Seçiniz'? ('-'): hiveResult[0].superNeed}</p> 
          <p className={classes.hiveData} >{t("cerceveadedi")}: {hiveResult[0].framesQuantity =='Seçiniz'? ('-'): hiveResult[0].framesQuantity}</p> 
          <p className={classes.hiveData} >{t("cerceveihtiyacı")}: {hiveResult[0].emptyFrameNeed =='Seçiniz'? ('-'): hiveResult[0].emptyFrameNeed}</p> 
          <p className={classes.hiveData} >{t("polentuzakdurumu")}: {hiveResult[0].pollenTrapStatus =='Seçiniz'? ('-'): t(hiveResult[0].pollenTrapStatus)}</p>
          <p className={classes.hiveData} >{t("bolmetahtasıihtiyacı")}: {hiveResult[0].seperatorNeed =='Seçiniz'? ('-'): hiveResult[0].seperatorNeed}</p> 
          <p className={classes.hiveData}>{t("acıklamalar")}:{hiveResult[0].equipmentDescription}</p>
          </Grid>
          <hr></hr>
          <Grid className={classes.controlGrid} item xs={12} md={6} lg={3}>
          <h4>{t("beslenme")}</h4>
          <hr></hr>
          <p className={classes.hiveData} >{t("beslenmeihtiyacı")}: {hiveResult[0].foodNeed =='Seçiniz'? ('-'): t(hiveResult[0].foodNeed)}</p> 
          <p className={classes.hiveData} >{t("kekihtiyacı")}: {hiveResult[0].cakeNeed ==''? ('-'): hiveResult[0].cakeNeed}</p> 
          <p className={classes.hiveData} >{t("surupihtiyacı")}: {hiveResult[0].syrupNeed ==''? ('-'): hiveResult[0].syrupNeed}</p> 
          <p className={classes.hiveData} >{t("fondanihtiyacı")}: {hiveResult[0].fondanNeed ==''? ('-'): hiveResult[0].fondanNeed}</p> 
          <p className={classes.hiveData} >{t("ballısurupihtiyacı")}: {hiveResult[0].honeySyrupNeed ==''? ('-'): hiveResult[0].honeySyrupNeed}</p> 
          <p className={classes.hiveData} >{t("ballıkekihtiyacı")}: {hiveResult[0].honeyCakeNeed ==''? ('-'): hiveResult[0].honeyCakeNeed}</p>
          <p className={classes.hiveData}>{t("acıklamalar")}:{hiveResult[0].feedgingDescription}</p>
          </Grid>
          </Grid>
          <hr></hr>
          <Button
            variant="contained"
            onClick = {handleUpdateHive}
                  >
            {t("kontrol")}
            </Button>
         <hr></hr>

        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>

          {/* <Grid className={classes.totalCard} container justifyContent="center"> */}
             <TotalHarvest filteredHarvest={filteredHarvest} year={year} newHarvest = {location.state !== undefined && location.state[1]} setYear={setYear} />
          {/* </Grid> */}

          <hr></hr>
          
          <p> {t("anlıkhasatverileri")}</p>

          <Grid container justifyContent="center" spacing={5} >
              {currentHarvest === undefined ? (
                <img src={loadingGif} alt="loading" />
              ) : currentHarvest ? (
                filteredHarvest?.map((item, harvestId) => (
                  <Grid key={harvestId} item>
                    <HarvestCard year={year} filteredHarvest={filteredHarvest} item={item} />
                  </Grid>
                ))
              ) : (
                <h4>No data available.</h4>
              )}
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
        <Grid className={classes.totalCard} container justifyContent="center">
             <TotalFeeding/>
          </Grid>

          <hr></hr>
          
          <p>{t("anlıkbeslenmeverileri")}</p>

          <Grid container justifyContent="center" spacing={5}>
              {currentFeeding === undefined ? (
                <img src={loadingGif} alt="loading" />
              ) : currentFeeding ? (
                currentFeeding.map((item, feedingId) => (
                  <Grid key={feedingId} item>
                    <FeedingCard item={item} />
                  </Grid>
                ))
              ) : (
                <h4>No data available.</h4>
              )}
          </Grid>
        </TabPanel>

      </SwipeableViews>
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Tooltip title={fab.title} aria-label="add">
          <Fab aria-label={fab.label} className={fab.className} color={fab.color} onClick={fab.onClick}>
            {fab.icon} 
          </Fab>
          </Tooltip>
        </Zoom>
      ))}
    </div>
  );
}

