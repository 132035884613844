import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const PrivacyModal = (props) =>  {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Privacy Policy

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4></h4>
        <p>
        Giraffe Analytic built the Beecity Apiary app as a Commercial app. This service  is provided by Giraffe Analytic and is intended for use as is.
This page is used to inform visitors regarding the policies with the collection, use, and disclosure of Personal Information if anyone decided to use  Beecity Apiary.
If you choose to use Beecity Apiary, then you agree to the collection and use of information in relation to this policy. We will not use or share your information with anyone except as described in this Privacy Policy.
The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Beecity Apiary unless otherwise defined in this Privacy Policy.

        </p>
        <h4>Information Collection and Use</h4>
        <p>We gather the following personal information that you provide to us when using the Service: your name, email address, phone number, address, social media usernames, business name, billing information, and your activity while using the Service. The information is gathered in order to offer you the Service.
 In order to create your Account, you must supply at least the following information: your name and email address. If you do not, we will be unable to provide the Service to you.
For marketing purposes, we additionally gather the following personal information: name and email. we may periodically send promotional email about new products, special offers or other information. We will only collect such information with your permission. You are always free to revoke your consent.
 
The app does use third-party services that may collect information used to identify you.
Link to the privacy policy of third-party service providers used by the app
</p>
        <h4>Log Data</h4>
        <p>We want to inform you that whenever you use our Service, in a case of an error in the app I collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.</p>
        <h4>Cookies</h4>
        <p>
        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies'' to collect information and improve their services. We use cookies to identify you and to track your logged-in status, in order to allow you to access your secured content. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.

        </p>
        <h4>Service Providers</h4>
        <p>In some special cases we also use cookies provided by trusted third parties. We may employ third-party companies and individuals due to the following reasons:

<ul>
    <li>To facilitate our Service;</li>
    <li>To provide the Service on our behalf;</li>
    <li>To perform Service-related services; or</li>
    <li>To assist us in analyzing how our Service is used.</li>
</ul>

We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.


        </p>
        <h4>Security</h4>
        <p>
        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and Beecity Apiary cannot guarantee its absolute security. Users are responsible for understanding and assuming any risks related to data transmission, including the possibility of third parties intercepting their personal information. Secure socket layer (SSL) technology is used by the Site and application to encrypt your Personal Information before sending it securely from your PC or mobile device to our servers.
        </p>
        <h4>Links to Other Sites</h4>
        <p>
        This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by Beecity Apiary. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.

        </p>
        <h4>Children’s Privacy</h4>
        <p>
        These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case We discover that a child under 13 has provided me with personal information, we  immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we  will be able to do the necessary actions.
        </p>
        <h4>Changes to This Privacy Policy</h4>
        <p>
        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
This policy is effective as of 2022-07-02

        </p>
        <h4>Contact Us</h4>
        <p>
        If you have any questions or suggestions about the Privacy Policy, do not hesitate to contact us at apiary@beecityglobal.com.

        </p>



      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Kapat</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default PrivacyModal;