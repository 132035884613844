import React, { useEffect, useMemo, useState } from "react";
// import React, { useState, useEffect }  from "react";

// import { firebaseDB } from "../utils/firebaseUtil";
// import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import HarvestCard from "../components/HarvestCard";
import loadingGif from "../assets/loading.gif";
// import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";
import { FormControl, MenuItem, styled, TextField } from "@material-ui/core";
import { useAuth } from "../context/AuthContextProvider";
import { firebaseDB } from "../utils/firebaseUtil";
import { inputLabelClasses, outlinedInputClasses } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  harvestRoot: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // flexGrow: 1,
    minHeight: "100vh",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  race: {
    display: "flex",
    justifyContent: "space-around",
    // flexDirection:"column"
    // width:"100%",
  },
  formControl: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    // flexGrow: 1,
    fontFamily: "Girassol",
    textAlign: "center",
    color: "#591900",
  },
  harvestMainRoot: {
    // display: "flex",
    // marginTop: 30,
    // backgroundImage: `url(${backImage})`,
    // backgroundRepeat: "repeat",
    // backgroundSize: "cover",
  },
}));

const Harvest = () => {
  const classes = useStyles();
  const {
    currentApiaries,
    getApiaries,
    allHarvest,
    getAllHarvest, 
    allHives  ,
    getAllHives 
  } = useApiary();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  const [selectedYear, setYear] = useState("");
  const [selectedApiaryId, setApiary] = useState("");
  const [harvestFromSidebar, setHarvestFromSidebar] = useState(false);

  const { t } = useTranslation(["common"]);

  const StyledTextField = styled(TextField)((e) => ({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: "none",
      // backgroundImage:"linear-gradient(#efeefe 75%, rgba(0,0,0,0.7))",
      borderBottom: e.value === "" ? "1px solid  #3F4E4F" : "3px solid #3F4E4F",
      borderBottomWidth: e.value === "" ? "0.05rem" : "0.35rem",
      transition: "background-image 1s",

      // boxShadow: "1px 1px 2px 2px  #3F4E4F",
      // backgroundColor:  "#FAB718",
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "black",
      fontWeight: "900",
      backgroundColor: "#efeefe",
    },
    [`& .${inputLabelClasses.shrink}`]: {
      backgroundColor: "transparant",
      marginTop: "-1.3rem",
      padding: "0.5rem",
      fontSize: "1.1rem",
      color: "#001E6C",
      fontWeight: "700",
    },
  }));

  const handleSelectApiary = (e) => {
    setApiary(e.target.value);
  };

  useEffect(() => {
    getApiaries(uid);
    getAllHarvest(uid);
    getAllHives(uid)
    setHarvestFromSidebar(true)
  }, []);

  const allControlledHarvest = () => {    
    const allApiaryIds = currentApiaries.map(apiary => apiary.apiaryId)
    const filteredAllHives = allHives?.filter((hive) =>
    allApiaryIds.includes(hive.apiaryId));

    
    const allHiveIds = filteredAllHives.map(hive => hive.hiveId)  
    const filteredHarvest = allHarvest?.filter(harvest => 
      allApiaryIds.includes(harvest.apiaryId) && allHiveIds.includes(harvest.hiveId)
      )
    return filteredHarvest
  }

  const harvestFilterByApiary = allControlledHarvest()?.filter(
    (harvest) => harvest?.apiaryId == selectedApiaryId
  );
  const harvestFilterByYear_Exist_Apiary =
    selectedApiaryId &&
    harvestFilterByApiary?.filter(
      (harvest) => harvest.date.slice(0, 4) == selectedYear
    );
  const harvestFilterByYear_None_Apiary = allControlledHarvest()?.filter(
    (harvest) => harvest.date.slice(0, 4) == selectedYear
  );

  return (
    <div className={classes.harvestRoot}>
      <Grid container justifyContent="center">
        <Typography className={classes.title} variant="h3" noWrap>
          {t("hasatdurumu")}
        </Typography>
      </Grid>

      <div className={classes.race} xs={12}>
        <Grid className={classes.formControlGrid} item xs={5}>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <StyledTextField
              variant="outlined"
              select
              fullWidth
              id="queen-grid-need"
              value={selectedApiaryId}
              onChange={(e) => handleSelectApiary(e)}
              label={t("arılıklar")}
            >
              <MenuItem value={""}>{t("tümarılıklar")}</MenuItem>
              {currentApiaries?.map((option) => (
                <MenuItem key={option.apiaryId} value={option.apiaryId}>
                  {option.apiaryTitle}
                </MenuItem>
              ))}
            </StyledTextField>
          </FormControl>
        </Grid>
        <Grid className={classes.formControlGrid} item xs={5}>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <StyledTextField
              variant="outlined"
              select
              fullWidth
              id="queen-grid-need"
              required
              value={selectedYear}
              onChange={(e) => setYear(e.target.value)}
              label={t("yıllar")}
            >
              <MenuItem value={""}>{t("tümyıllar")}</MenuItem>
              <MenuItem value={"2015"}>2015</MenuItem>
              <MenuItem value={"2016"}>2016</MenuItem>
              <MenuItem value={"2017"}>2017</MenuItem>
              <MenuItem value={"2018"}>2018</MenuItem>
              <MenuItem value={"2019"}>2019</MenuItem>
              <MenuItem value={"2020"}>2020</MenuItem>
              <MenuItem value={"2021"}>2021</MenuItem>
              <MenuItem value={"2022"}>2022</MenuItem>
              <MenuItem value={"2023"}>2023</MenuItem>
              <MenuItem value={"2024"}>2024</MenuItem>
              <MenuItem value={"2025"}>2025</MenuItem>
            </StyledTextField>
          </FormControl>
        </Grid>
      </div>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={5}>
          {(selectedYear && selectedApiaryId
            ? harvestFilterByYear_Exist_Apiary
            : selectedApiaryId
            ? harvestFilterByApiary
            : selectedYear && !selectedApiaryId
            ? harvestFilterByYear_None_Apiary
            : allControlledHarvest()
          ).map((item, harvestId) => (
            <Grid key={harvestId} item>
              <HarvestCard harvestFromSidebar={harvestFromSidebar} item={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Harvest;
