import React, { useState } from "react";
import {
  ProSidebar,
  SubMenu,
  Menu,
  MenuItem,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FaArchive, FaArrowCircleLeft, FaForumbee } from "react-icons/fa";
import { GiBee, GiBeehive, GiTreeBeehive } from "react-icons/gi";
import { CgNotes } from "react-icons/cg";
import { FaHome, FaMapMarkedAlt, FaCloudSunRain,FaAlignJustify, FaArrowCircleRight } from "react-icons/fa";
import { SiGooglecalendar } from "react-icons/si";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import { useHistory } from "react-router-dom";

import { useApiary } from "../../context/ApiaryContextProvider";
import { useAuth } from "../../context/AuthContextProvider";


import { useTranslation } from "react-i18next";
import "./Sidebar.css";

const Sidebar = ({menuCollapse, setMenuCollapse }) => {
  const history = useHistory();
  const { currentApiaries, apiaryIdFromHive } = useApiary();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const [sidebarState, setSidebarState] = useState("home");



  console.log(sidebarState);

  const handleHome = () => {
    history.push("/home");
    setSidebarState("home");
  };

  const handleApiary = () => {
    history.push("/userNavigator/:id");
    setSidebarState("apiary");
  };
  const handleHives = async (state, apiaryId) => {
    await history.push("/userNavigator/:id");
    history.push(`/apiaryNavigator/${apiaryId}`);
    setSidebarState(state);
  };

  const handleReport = (e) => {
    history.push("/apiary-report/:id");
    setSidebarState("report");
  };

  const handleNotes = () => {
    history.push("/apiary-notes/:id");
    setSidebarState("note");
  };

  const handleWeather = () => {
    history.push("/weather-page");
    setSidebarState("weather");
  };
  const handleAdmin = () => {
    history.push("/admin");
    setSidebarState("admin");
  };

  const handleCalendar = () => {
    history.push("/calendar-page");
    setSidebarState("calendar");
  };
  const handleVeterinary = () => {
    history.push("/veterinary-page/:id");
    setSidebarState("veterinary");
  };
  const handleHarvest = () => {
    history.push("/harvest/:id");
    setSidebarState("harvest");
  };

  const { t } = useTranslation(["common"]);

  return (
    <>
      {currentUser && menuCollapse === 0 && <FaAlignJustify className="menu-handler-container" onClick={() => setMenuCollapse(1)} /> }
       
     {/* <div className={menuCollapse === 0 ? "slide-none" : "slide-exist"}></div> */}
        
          
         

      { currentUser ? (
        <div className="sidebar">
          <div id="header">
            <ProSidebar className={menuCollapse === 1 ? "pro-sidebar" : menuCollapse === 2 ? "pro-sidebar-lg" : menuCollapse === 0 && "pro-sidebar-none"} collapsed={"true"}>
              <SidebarContent>
                <Menu iconShape="round">
                  <MenuItem>
                  {menuCollapse !== 0 && 
       <div className="menu-handler-container-active"  >
          <BsCaretLeftFill onClick={() => setMenuCollapse(menuCollapse -1 )} />
           {menuCollapse !== 2 && <BsCaretRightFill onClick={() => setMenuCollapse(2)}  />}
       </div>}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </MenuItem>
                  <MenuItem
                    className="nav-link"
                    onClick={handleHome}
                    icon={<FaHome style={{ fontSize: "2.3rem" }} />}
                    active={window.location.pathname === "/home"}
                  >
                    {t("anasayfa")}
                  </MenuItem>
                  <MenuItem
                    className="nav-link"
                    onClick={handleApiary}
                    icon={<FaMapMarkedAlt style={{ fontSize: "1.5rem" }} />}
                    active={window.location.pathname === "/userNavigator/:id"}
                  >
                    {t("arılıklar")}
                  </MenuItem>
                  <SubMenu
                    className="nav-link"
                    title={t("kovanlar")}
                    icon={<FaArchive style={{ fontSize: "1.5rem" }} />}
                    style={{
                      backgroundColor:
                        window.location.pathname ===
                          `/apiaryNavigator/${apiaryIdFromHive}` && "#ff6f00",
                    }}
                  >
                    <p>{t("kovanlar")}</p>
                    {currentUser ? (
                      currentApiaries?.map((option) => (
                        <MenuItem
                          key={option.apiaryId}
                          value={option.apiaryTitle}
                          onClick={() => handleHives("hive", option.apiaryId)}
                          icon={<GiBee />}
                        >
                          {option.apiaryTitle}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>Please Login</MenuItem>
                    )}
                  </SubMenu>

                  <MenuItem
                    className="nav-link"
                    onClick={(e) => handleReport(e)}
                    icon={<GiBeehive style={{ fontSize: "1.5rem" }} />}
                    active={window.location.pathname === "/apiary-report/:id"}
                  >
                    {t("rapor")}
                  </MenuItem>
                  <MenuItem
                    className="nav-link"
                    onClick={handleHarvest}
                    icon={<GiTreeBeehive style={{ fontSize: "1.5rem" }} />}
                    active={window.location.pathname === "/harvest/:id"}
                  >
                    {t("hasat")}
                  </MenuItem>
                  <MenuItem
                    onClick={handleNotes}
                    icon={<CgNotes style={{ fontSize: "1.5rem" }} />}
                    className="nav-link"
                    active={window.location.pathname === "/apiary-notes/:id"}
                  >
                    {t("notlar")}
                  </MenuItem>        
                  <MenuItem
                    className="nav-link"
                    onClick={handleWeather}
                    icon={<FaCloudSunRain style={{ fontSize: "1.5rem" }} />}
                    active={window.location.pathname === "/weather-page"}
                  >
                    {t("hava")}
                  </MenuItem>
                  <MenuItem
                    className="nav-link"
                    onClick={handleCalendar}
                    icon={<SiGooglecalendar style={{ fontSize: "1.5rem" }} />}
                    active={window.location.pathname === "/calendar-page"}
                  >
                    {t("takvim")}
                  </MenuItem>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                  <MenuItem></MenuItem>
                </Menu>
              </SidebarContent>
              {/* <SidebarFooter>{t("arıkentuygulaması")}</SidebarFooter> */}
            </ProSidebar>
          </div>
        </div>
      ) : (
        <div className="none"></div>
      )}
    </>
  );
};

export default Sidebar;
