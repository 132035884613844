import React, { useEffect, useState } from "react";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import hivePng from "../assets/apiary2.jpg";
import backImage from "../assets/apiary3.png";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
// import HiveForm from "../components/HiveForm";
import HiveFormTabs from "../components/HiveFormTabs";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DialogActions, DialogContentText } from "@material-ui/core";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Button from "@material-ui/core/Button";
import StoreBadge from "react-store-badge";

const useStyles = makeStyles((theme) => ({
  newHiveContainer: {
    backgroundImage: `url(${backImage})`,
    backgroundRepeat: "repeat",
    // backgroundSize: "auto",
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  hiveImg: {
    width: 400,
  },

  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const NewHive = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const {
    addHive,
    updateTotalHivesList,
    getHives,
    getUserIsPro,
    userIsPro,
    currentHives,
    addTotalHarvest,
  } = useApiary();
  const history = useHistory();

  const uid = currentUser?.uid;
  const { id } = useParams();
  const currentApiaryId = id;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["common"]);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
    toastErrorNotify(t("Kovan Eklenemedi"));
    history.push(`/apiaryNavigator/${currentApiaryId}`);
  };

  useEffect(() => {
    getHives(uid, currentApiaryId);
    getUserIsPro(uid);
  }, []);

  console.log("id:", id);
  const dateCreate = moment().format("DD/MM/YYYY");

  const handler = (newHive) => {
    try {
      if (currentHives.length < 6) {
        addHive(uid, newHive, currentApiaryId);
        toastSuccessNotify(t("Kovan Eklendi"));
        history.push(`/apiaryNavigator/${currentApiaryId}`);

      } else if (userIsPro === true) {
        addHive(uid, { ...newHive, isActive: true }, currentApiaryId);
        toastSuccessNotify(t("Kovan Eklendi"));
        history.push(`/apiaryNavigator/${currentApiaryId}`);

      } else {
        //  toastErrorNotify(t("Kovan Eklenemedi"));
        setOpenModal(true);

        // history.push(`/apiaryNavigator/${currentApiaryId}`);
        updateTotalHivesList(uid);
      }
    } catch (error) {
      toastErrorNotify(t("Kovan Eklenemedi"));
    }
  };

  const hive = {
    hiveId: uuidv4(),
    uid: uid,
    apiaryId: currentApiaryId,
    isActive: false,
    hiveNumber: "",
    colonyState: "Seçiniz",
    race: "",
    healthStatus: "Seçiniz",

    queenAge: "Seçiniz",
    queenExist: "Seçiniz",
    queenColor: "Seçiniz",
    dailyEgg: "Seçiniz",
    larvaStatus: "Seçiniz",
    pupaStatus: "Seçiniz",
    queenCellStatus: "Seçiniz",
    maleBeeStatus: "Seçiniz",

    queenGridNeed: "0",
    superQuantity: "0",
    superNeed: "0",
    framesQuantity: "0",
    emptyFrameNeed: "0",
    framesNeed: "",
    foundationSheetsNeed: "0",
    deepFoundationSheetsNeed: "0",
    pollenTrapStatus: "Seçiniz",
    seperatorNeed: "0",

    foodNeed: "Seçiniz",
    cakeNeed: "",
    syrupNeed: "",
    fondanNeed: "",
    honeySyrupNeed: "",
    honeyCakeNeed: "",

    description: "",
    colonyDescription: "",
    equipmentDescription: "",
    feedgingDescription: "",
  };

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <h1>
            {t(
              "Daha fazla kovan eklemek için lütfen IOS veya Android uygulamamızdan satın alınız?"
            )}
          </h1>
        </DialogTitle>
        <DialogContent>
          <StoreBadge
            name="Beecity Apiary"
            googlePlayUrl="https://play.google.com/store/apps/details?id=com.arikent.apiary"
            appStoreUrl="https://apps.apple.com/us/app/beecity-apiary/id1633088644"
          />
          <DialogContentText> </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            autoFocus
          >
            {t("kapat")}
          </Button>
          {/* <Button onClick={() => deleteHandler(uid,isDeleteApiaryId )}  variant="outlined" color="primary" autoFocus>
               {t("sil")}
              </Button> */}
        </DialogActions>
      </Dialog>

      <HiveFormTabs setOpen hive={hive} handler={handler} />
    </div>
  );
};

export default NewHive;
