import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@material-ui/core/Button";

import CancelIcon from "@mui/icons-material/Cancel";

import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContextProvider";




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DeleteAlert({
  openModal,
  handleClose,
  apiaryID,
  deleteHandler
}) {
    let { currentUser } = useAuth();
    const uid = currentUser?.uid;
  const { t } = useTranslation(["common"]);
  const handleRemoveApiary = () =>{
    deleteHandler(uid,apiaryID )
    handleClose()
  }

  return (
    <div>
      <Modal
        maxwidth="sm"
        fullWidth
        open={openModal}
        onClose={handleClose}
        height="800"
      >
        <Fade in={openModal}>
          <Box
            style={{backgroundColor:"#FAB718"}}
            sx={style}
          >
            <Typography id="transition-modal-title" variant="h6" >
              {t("Arılık silindiğinde içinde bulunan tüm kovanlar silinecektir.Emin misiniz?")}
            </Typography>
            
              <Button
                style={{ margin: "0.5rem 0.5rem" }}
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => handleRemoveApiary()}
              >
                {t("sil")}
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                startIcon={<CancelIcon />}
              >
                {t("iptal")}
              </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
