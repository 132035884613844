import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import HiveIcon from "@mui/icons-material/Hive";
import DeleteIcon from "@material-ui/icons/DeleteForever";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
// import moment from "moment";

import { useTranslation } from "react-i18next";


import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";

import { toastErrorNotify } from "../utils/ToastNotify";
import { toastSuccessNotify } from "../utils/ToastNotify";
import {FaHeartbeat} from "react-icons/fa"
import {GiBee} from "react-icons/gi"
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import Tooltip from "@material-ui/core/Tooltip";

import { DialogActions, DialogContentText } from "@material-ui/core";
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

 


const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: 185,
    maxWidth: 185,
    minHeight: 175,
    maxHeight: 175,

    backgroundColor: "#EEBF7D",
    overflow: "hidden",
  },
 
  module: {
    display: "flex",
    flexDirection:"column",
    fontSize:"12px"

    // overflow: "hidden",
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    backgroundColor: "#efeefe",
    height: "80px",
    width:"185px",
    marginTop: "2rem",
    display:"flex",          
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
  },
  hiveCardActions: {
    display:"flex",
    justifyContent: "center",
  },
  title: {
    fontFamily: "Girassol",
    color: "#046582",
    // marginTop: "1rem",
  },
  hiveCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  hiveLid: {
    position: "relative",
    width: "14.5rem",
    padding: "0.7rem",
    marginBottom: "0rem",
    borderRadius: "0.7rem 0.7rem 1.5rem 1.5rem",
  },
  hiveLidHover: {
    position: "relative",
    width: "14.5rem",
    padding: "0.9rem",
    backgroundColor: "#EEBF7D",
    marginBottom: "-0.5rem",
    borderRadius: "0.7rem 0.7rem 1.5rem 1.5rem",
    transform: "rotate(-8deg) translate(0%, -80%)",
    transition: "transform 0.5s",
  },

  hiveLidBorder: {
    position: "absolute",
    bottom: "0",
    width: "10.6rem",
    padding: "0.1rem",
    backgroundColor: "#4B5D67",
    borderRadius: "0.5rem",
    left: "2rem",
  },
  hiveEntry: {
    transformX: "-50%",
    width: "4rem",
    height: "0.5rem",
    borderRadius: "2rem 2rem 2rem 2rem",
    backgroundColor: "grey",
    marginBottom: "-1.5rem",
    marginTop: "1rem",
    zIndex: 999,
  },
  hiveIcon:{
    fontSize:"20px"
  }
}));

const HiveCard = ({ item, updatedTotalHarvest }) => {
  const { hiveId, hiveNumber, race, healthStatus, queenExist,colonyState,  dailyEgg, larvaStatus , pupaStatus, queenCellStatus, maleBeeStatus, queenGridNeed, superNeed,emptyFrameNeed, seperatorNeed } = item;

  const classes = useStyles();
  const history = useHistory();
  const { deleteOneHive, updateTotalHarvest,currentHives,getHives,getAllHarvest, allControlledHarvest } = useApiary();
  // const { getOneHive, deleteOneHive } = useApiary();


  const { currentUser } = useAuth();
  const uid = currentUser?.uid;

  const { id } = useParams();
  const [cardHover, setCardHover] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [isDeleteApiaryId, setIsDeleteApiaryId] = useState("");

  
  const openDetails = () => {
    if (!currentUser) {
      toastErrorNotify("Please Login to get the details");
    }
    // if user doenst exist it is routed to the login page via PrivateRouter
    history.push(`/hiveNavigator/${hiveId}`);
  };
  const allYears = [
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
  ];
  const initialHarvest = {
    totalBeeMilkAmount: 0,
    totalBeeVenomAmount: 0,
    totalBeeWaxAmount: 0,
    totalHoneyAmount: 0,
    totalPolenAmount: 0,
    totalPropolisAmount: 0,
  }


  const deleteHandler = (uid, id, hiveId) => {
    deleteOneHive(uid, id, hiveId); 
    setOpenModal(false);
    allYears.map(year => 
      updateTotalHarvest(
        uid,
        id,
        year,     
        initialHarvest     
      ))
      
      history.push(`/apiaryNavigator/${id}`);
      toastSuccessNotify(t("Başarıyla Silindi"));
      
    };
    const deleteModalHandler = () => {
      setOpenModal(true);
    }
   const handleClose = () => {
      setOpenModal(false);
    };

  const { t } = useTranslation(["common"]);

  return (
    <div>
    <Dialog
                // fullScreen={fullScreen}
                open={openModal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                <h1>{t("Kovan silindiğinde içinde bulunan tüm bilgiler silinecektir.Emin misiniz?")}</h1>
           
                 
                </DialogTitle>
                <DialogContent>
                  <DialogContentText> </DialogContentText>
                </DialogContent>
                <DialogActions>
              
                  <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
                  {t("kapat")}
                  </Button>
                   <Button onClick={() => deleteHandler(uid,id, hiveId )}  variant="outlined" color="primary" autoFocus>
                   {t("sil")}
                  </Button>
                  
                </DialogActions>
              </Dialog>
    
  
   
    

    <div
      onMouseEnter={() => setCardHover(true)}
      onMouseLeave={() => setCardHover(false)}
      className={classes.hiveCardContainer}
    >
      <div
        style={{
          backgroundColor: 
            ((healthStatus == "Sağlıklı" || healthStatus == "Seçiniz") &&
            (colonyState == "Normal"  || colonyState == "Güçlü" || colonyState == "Seçiniz") &&
            (queenExist == "Var" || queenExist == "Seçiniz")&&
            (dailyEgg == "Var" || dailyEgg == "Seçiniz" ) &&
            (larvaStatus == "Var" || larvaStatus == "Seçiniz")&&
            (pupaStatus == "Var" || pupaStatus == "Seçiniz" )&&
            (queenExist == "Var" || queenExist == "Seçiniz")&&
            (queenCellStatus == "Yok" || queenCellStatus == "Seçiniz") &&
            (maleBeeStatus == "Normal" || maleBeeStatus == "Seçiniz" ) &&
            (queenGridNeed == 0 ) &&
            (superNeed == 0 ) &&
            (emptyFrameNeed == 0 ) &&
            (seperatorNeed == 0 )) 
              ? "green"
              : "red"          
        }}
        className={!cardHover ? classes.hiveLid : classes.hiveLidHover}
      >
        <div className={classes.hiveLidBorder}></div>
      </div>
      <div className={classes.hiveEntry}></div>

      <Card className={classes.cardRoot}>
        <CardActionArea onClick={openDetails}>
          {/* <CardMedia
          className={classes.media}
          image={image || placeholder}
          title={hiveNumber}
        /> */}

          <CardContent className={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h6"
              component="h4"
              className={classes.title}
            >
              {hiveNumber}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" component="p">
            {moment(published_date).format("MMM DD, YYYY")}
          </Typography> */}
          <div style={{display:"flex"}}>
            {/* <h5 className={classes.module}>
              {t("saglıkdurumu")}:  {t(`${healthStatus}`)} 
            </h5>
            <h5 className={classes.module}>
              {t("anaarıdurumu")}: {t(`${queenExist}`)}
            </h5> */}
            <Typography gutterBottom variant="h6" component="h6">
            {t(race)}
          </Typography>
    

          </div>
            {/* <p className={classes.module}>{t("notlar")}: {description}</p> */}
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.hiveCardActions}>
          <IconButton >
            <HiveIcon className={classes.hiveIcon}  onClick={openDetails} />
          </IconButton>
          <Tooltip title={t("saglıkdurumu")} aria-label="add">
          <IconButton >
          <FaHeartbeat style={{color:healthStatus === "Sağlıklı" ? "green" : "red"}}  className={classes.hiveIcon} />
          </IconButton>

          </Tooltip> 
          <Tooltip title={t("anaarıdurumu")} aria-label="add">
          <IconButton >

            <GiBee style={{color:queenExist === "Var" ? "green" : "red"}}  className={classes.hiveIcon}  />
            </IconButton>

          </Tooltip>   
          <Tooltip title={t("sil")} aria-label="delete">

          <IconButton >
            <DeleteIcon className={classes.hiveIcon} onClick={() => deleteModalHandler()} />
          </IconButton>
          </Tooltip> 


          {/* <Button
          variant="contained"
          color="secondary"
          onClick={() => deleteHandler(uid, id, hiveId)}
          startIcon={<DeleteIcon />}
        >
       Sil
          </Button> */}
        </CardActions>
      </Card>
    </div>
    </div>

  );
};

export default HiveCard;
