import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import HarvestIcon from '@mui/icons-material/Inventory2Outlined';
// import placeholder from "../assets/petek.png";
// import image from "../assets/apiary2.jpg";

import { useApiary } from "../context/ApiaryContextProvider";
// import { useAuth } from "../context/AuthContextProvider";
// import { toastErrorNotify } from "../utils/ToastNotify";
// import { toastSuccessNotify } from "../utils/ToastNotify";

// import { useHistory, useParams } from 'react-router-dom';
// import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
import { FormControl, Grid, MenuItem, TextField } from '@material-ui/core';
import { format } from 'date-fns';
import { useAuth } from '../context/AuthContextProvider';
import { useParams } from 'react-router-dom';




const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: 345,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  module: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "text-overflow": "ellipsis",
    overflow: "hidden",
    textAlign: "left",
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    backgroundColor: "#efeefe",
    // height: "125px",
  },

  harvestCardActions: {
    justifyContent: "space-between",
  },

  title: {
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

 const TotalHarvest= ({year, setYear,filteredHarvest, newHarvest}) => {
  

	const classes = useStyles();
	const { currentApiaries,updateTotalHarvest,apiaryIdFromHive, allHarvest, getAllHarvest, allHives,getAllHives} = useApiary();
  
  const { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const { id } = useParams();
  const apiaryId = apiaryIdFromHive; 
  const hiveId = id;

  function totalHoneyAmount (harvestList) {
		let total = 0;
		harvestList.map((item) => (
			total +=  parseFloat(item.singleHoneyAmount)
		));
		return total}

  function totalPropolisAmount (harvestList) {
		let total = 0;
		harvestList.map((item) => (
			total +=  parseFloat(item.singlePropolisAmount)
		));
		return total}

  function totalPolenAmount (harvestList) {
		let total = 0;
		harvestList.map((item) => (
			total +=  parseFloat(item.singlePolenAmount)
		));
		return total}

  function totalBeeWaxAmount (harvestList) {
		let total = 0;
		harvestList.map((item) => (
			total +=  parseFloat(item.singleBeeWaxAmount)
		));
		return total}

  function totalBeeMilkAmount (harvestList) {
		let total = 0;
		harvestList.map((item) => (
			total += parseFloat(item.singleBeeMilkAmount)
		));
		return total}

  function totalBeeVenomAmount (harvestList) {
		let total = 0;
		harvestList.map((item) => (
			total +=  parseFloat(item.singleBeeVenomAmount)
		));
		return total}
    const { t } = useTranslation(["common"]);

    useEffect( () => {
      getAllHarvest(uid)
      getAllHives(uid)
   }, [])
   const allApiaryIds = currentApiaries?.map((apiary) => apiary.apiaryId);
   const filteredAllHives = allHives?.filter((hive) =>
   allApiaryIds.includes(hive.apiaryId)
 );

  const allHiveIds = filteredAllHives.map(hive => hive.hiveId)  
  const allFilteredHarvestList = allHarvest?.filter(harvest => 
     allHiveIds.includes(harvest.hiveId)
    )
      const harvestFilterbyApiary = allFilteredHarvestList.filter(
        (harvest) => harvest.apiaryId === apiaryIdFromHive && harvest.date.slice(6,10) == newHarvest?.date?.slice(0,4))
      
    

  console.log("harvestFilterbyApiary", harvestFilterbyApiary)
  const updatedTotalHarvest =  (harvestList) => {
    const newTotalHarvest = {
      totalHoneyAmount :   totalHoneyAmount(harvestList) , 
      totalPropolisAmount :totalPropolisAmount(harvestList),
      totalPolenAmount :   totalPolenAmount(harvestList), 
      totalBeeWaxAmount :  totalBeeWaxAmount(harvestList) ,
      totalBeeMilkAmount : totalBeeMilkAmount(harvestList),
      totalBeeVenomAmount :totalBeeVenomAmount(harvestList)   
  }
  return newTotalHarvest;
}


  useEffect(async () => {
    if(newHarvest == "deleteHarvestActive"){
      await  updateTotalHarvest(uid, apiaryId, String(year)  ,updatedTotalHarvest(filteredHarvest))
    }
    else if(newHarvest !== "deleteHarvestActive" && newHarvest !== undefined){
   await  updateTotalHarvest(uid, apiaryId, newHarvest?.date?.slice(0,4)  ,updatedTotalHarvest(harvestFilterbyApiary))
    }
 }, [allFilteredHarvestList])

		
	
	return (
    <Grid container justifyContent="center" item xs={12} md={12} lg={12}>
		<Card className={classes.cardRoot}>
      <CardActionArea>
        
        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
           {t("toplamhasat")}
          </Typography>
          <p className={classes.module}>{t("toplambalmiktarı")}        :{totalHoneyAmount(filteredHarvest)} kg</p>
          <p className={classes.module}>{t("toplampropolismiktarı")}   :{totalPropolisAmount(filteredHarvest)} gr</p>
          <p className={classes.module}>{t("toplampolenmiktarı")}      :{totalPolenAmount(filteredHarvest)} gr</p>
          <p className={classes.module}>{t("toplamarımumumiktarı")}   :{totalBeeWaxAmount(filteredHarvest)} kg</p>
          <p className={classes.module}>{t("toplamarısutumiktarı")}   :{totalBeeMilkAmount(filteredHarvest)} gr</p>
          <p className={classes.module}>{t("toplamarızehrimiktarı")}  :{totalBeeVenomAmount(filteredHarvest)} gr</p>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.harvestCardActions}>
        {/* <HarvestIcon className={classes.avatar} /> */}
        
                  <FormControl
                    variant="outlined"
                    fullWidth
                    // className={classes.formControl}
                  >

                    <TextField 
                      variant="standard"
                      select
                      fullWidth  
                      id="queen-grid-need"
                      required
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      label={t("Seçiniz")}

                    >
                      <MenuItem value={"2015"}>2015</MenuItem>
                      <MenuItem value={"2016"}>2016</MenuItem>
                      <MenuItem value={"2017"}>2017</MenuItem>
                      <MenuItem value={"2018"}>2018</MenuItem>
                      <MenuItem value={"2019"}>2019</MenuItem>
                      <MenuItem value={"2020"}>2020</MenuItem>
                      <MenuItem value={"2021"}>2021</MenuItem>
                      <MenuItem value={"2022"}>2022</MenuItem>
                      <MenuItem value={"2023"}>2023</MenuItem>
                      <MenuItem value={"2024"}>2024</MenuItem>
                      <MenuItem value={"2025"}>2025</MenuItem>          
                    </TextField>
                  </FormControl>
      </CardActions>
    </Card>
    </Grid>
	)
}
export default TotalHarvest;