import React, { useEffect, useMemo, useState } from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InventoryIcon from '@mui/icons-material/Inventory';
// import Typography from "@material-ui/core/Typography";

import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import HiveCard from "../components/HiveCard";

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Weather from "../components/Weather";
import axios from "axios";
import Load from "../assets/flying_bee.gif";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  hiveRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(5),
    },
  },

  title: {
    // flexGrow: 1,
    fontFamily: "Girassol",
    textAlign: "center",
    color: "#591900",
  },
  hiveHeader: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",

    // paddingTop: 30,
    // flexGrow: 1,
    // "& > *": {
    //   margin: theme.spacing(5),
    // },
  },
  cardContent: {
    display: "flex",
    backgroundColor: "#efeefe",
    fontWeight: "500",
  },

  hiveFormControl: {
    height: 60,
    width: 150,
    backgroundColor: "#efeefe",
  },

  headerCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30px",

    // width: "10rem",
    // height: "5rem",
    backgroundColor: "#efeefe",
    boxShadow: "3px 3px 3px 3px rgba(0,0,0,0.085)",
  },
  hiveButton: {
    height: 60,
    width: 150,
  },
  weather: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  hiveMainRoot: {
    display: "flex",
    minHeight: "80vh",
    // backgroundColor: "#FFFFFF",
    // marginTop: 30,
    // backgroundImage: `url(${backImage})`,
    // backgroundRepeat: "repeat",
    // backgroundSize: "auto",
  },
}));

const Hives = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    currentHives,
    getHives,
    getOneApiary,
    oneApiary,
    appendMethods,
    updateTotalHivesList,
    loading,
    allHarvest,
    getAllHarvest,
    updateTotalHarvest,

    // updateTotalHives,
  } = useApiary();
  const { id } = useParams();
  const [apiaryIdFromHive, setApiaryIdFromHive] = useState(id);

  const [weatherData, setWeatherData] = useState([]);
  const [loadingImage, setLoading] = useState(false);

  const { t } = useTranslation(["common"]);

  // const [apiary, setApiary] = React.useState("");

  console.log("Current Hives:", currentHives);

  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  console.log("Hive sayfasının id'si : ", id);
  console.log("Apiary id'si : ", apiaryIdFromHive);

  const NewHiveHandler = (id) => {
    history.push(`/new-hive/${id}`);
    console.log("handler id'si : ", id);
  };
  const allHiveIds = currentHives.map((hive) => hive.hiveId);
  const filteredHarvest = allHarvest?.filter((harvest) =>
    allHiveIds.includes(harvest.hiveId)
  );
  const harvestFilterbyApiary = filteredHarvest.filter(
    (harvest) => harvest.apiaryId === apiaryIdFromHive
  );
  const allHarvestYears = harvestFilterbyApiary.map((harvest) =>
    harvest.date.slice(6, 10)
  );
  console.log("harvestFilterbyApiary", harvestFilterbyApiary);
  const harvestYears = new Set(allHarvestYears);

  function totalHoneyAmount() {
    let total = 0;
    let oneYearTotal = {};
    let allYearTotal = [];
    harvestYears.forEach((year) => {
      total = 0;
      oneYearTotal = {};
      const harvestFilterbyYear = harvestFilterbyApiary.filter(
        (harvest) => harvest.date.slice(6, 10) == year
      );
      harvestFilterbyYear.forEach((item) => {
        total += parseFloat(item.singleHoneyAmount);
        oneYearTotal = { total: total, year: year };
      });
      allYearTotal.push(oneYearTotal);
    });
    return allYearTotal;
  }

  function totalPropolisAmount() {
    let total = 0;
    let oneYearTotal = {};
    let allYearTotal = [];
    harvestYears.forEach((year) => {
      total = 0;
      oneYearTotal = {};
      const harvestFilterbyYear = harvestFilterbyApiary.filter(
        (harvest) => harvest.date.slice(6, 10) == year
      );
      harvestFilterbyYear.forEach((item) => {
        total += parseFloat(item.singlePropolisAmount);
        oneYearTotal = { total: total, year: year };
      });
      allYearTotal.push(oneYearTotal);
    });
    return allYearTotal;
  }

  function totalPolenAmount() {
    let total = 0;
    let oneYearTotal = {};
    let allYearTotal = [];
    harvestYears.forEach((year) => {
      total = 0;
      oneYearTotal = {};
      const harvestFilterbyYear = harvestFilterbyApiary.filter(
        (harvest) => harvest.date.slice(6, 10) == year
      );
      harvestFilterbyYear.forEach((item) => {
        total += parseFloat(item.singlePolenAmount);
        oneYearTotal = { total: total, year: year };
      });
      allYearTotal.push(oneYearTotal);
    });
    return allYearTotal;
  }

  function totalBeeWaxAmount() {
    let total = 0;
    let oneYearTotal = {};
    let allYearTotal = [];
    harvestYears.forEach((year) => {
      total = 0;
      oneYearTotal = {};
      const harvestFilterbyYear = harvestFilterbyApiary.filter(
        (harvest) => harvest.date.slice(6, 10) == year
      );
      harvestFilterbyYear.forEach((item) => {
        total += parseFloat(item.singleBeeWaxAmount);
        oneYearTotal = { total: total, year: year };
      });
      allYearTotal.push(oneYearTotal);
    });
    return allYearTotal;
  }

  function totalBeeMilkAmount() {
    let total = 0;
    let oneYearTotal = {};
    let allYearTotal = [];
    harvestYears.forEach((year) => {
      total = 0;
      oneYearTotal = {};
      const harvestFilterbyYear = harvestFilterbyApiary.filter(
        (harvest) => harvest.date.slice(6, 10) == year
      );
      harvestFilterbyYear.forEach((item) => {
        total += parseFloat(item.singleBeeMilkAmount);
        oneYearTotal = { total: total, year: year };
      });
      allYearTotal.push(oneYearTotal);
    });
    return allYearTotal;
  }
  console.log("harvestYears", harvestYears.size);
  function totalBeeVenomAmount() {
    let total = 0;
    let oneYearTotal = {};
    let allYearTotal = [];
    harvestYears.forEach((year) => {
      total = 0;
      oneYearTotal = {};
      const harvestFilterbyYear = harvestFilterbyApiary.filter(
        (harvest) => harvest.date.slice(6, 10) == year
      );
      harvestFilterbyYear.forEach((item) => {
        total += parseFloat(item.singleBeeVenomAmount);
        oneYearTotal = { total: total, year: year };
      });
      allYearTotal.push(oneYearTotal);
    });
    return allYearTotal;
  }

  const updatedTotalHarvest = () => {
    const newTotalHarvest = Array.from(harvestYears).map((year) => ({
      year: year,
      harvest: {
        totalHoneyAmount: totalHoneyAmount().filter((e) => e.year == year)[0]
          .total,
        totalPropolisAmount: totalPropolisAmount().filter(
          (e) => e.year == year
        )[0].total,
        totalPolenAmount: totalPolenAmount().filter((e) => e.year == year)[0]
          .total,
        totalBeeWaxAmount: totalBeeWaxAmount().filter((e) => e.year == year)[0]
          .total,
        totalBeeMilkAmount: totalBeeMilkAmount().filter(
          (e) => e.year == year
        )[0].total,
        totalBeeVenomAmount: totalBeeVenomAmount().filter(
          (e) => e.year == year
        )[0].total,
      },
    }));

    return newTotalHarvest;
  };
  console.log("updatedTotalHarvest().", updatedTotalHarvest());

  useEffect(() => {
    fethWeatherApi();
    updateTotalHivesList(uid);
  }, [oneApiary]);

  const fethWeatherApi = async () => {
    setLoading(true);
    const weatherAppAPI = "c0d290eeee9dd399b017a6d2ba64be7e";
    let weatherInfo = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?lat=${
        oneApiary[0]?.latitude
      }&lon=${oneApiary[0]?.longitude}&appid=${weatherAppAPI}&lang=${t("lang")}`
    );
    setWeatherData(weatherInfo);
    setLoading(false);
  };

  useEffect(() => {
    getHives(uid, id);
    appendMethods({ apiaryIdFromHive });
    getOneApiary(uid, apiaryIdFromHive);
    getAllHarvest(uid);
  }, []);

  const allYears = [
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
  ];
  const initialHarvest = {
    totalBeeMilkAmount: 0,
    totalBeeVenomAmount: 0,
    totalBeeWaxAmount: 0,
    totalHoneyAmount: 0,
    totalPolenAmount: 0,
    totalPropolisAmount: 0,
  };
  useEffect(() => {
    if (updatedTotalHarvest().length !== 0) {
      updatedTotalHarvest().map((e) => {
        updateTotalHarvest(uid, id, e.year, e.harvest);
      });
    } else if (updatedTotalHarvest().length == 0) {
      allYears.forEach((year) =>
        updateTotalHarvest(uid, id, year, initialHarvest)
      );
    }
  }, [filteredHarvest]);

  const handleApiary = (uid) => {
    history.push(`/userNavigator/${uid}`);
  };

  const sortedCurrentHives = currentHives?.sort(
    (a, b) => a.hiveNumber - b.hiveNumber
  );

  return (
    // <ParallaxProvider>
    <div className={classes.hiveMainRoot}>
      <Grid container className={classes.hiveRoot} justifyContent="center">
      <Button
            variant="contained"
            color="inherit"
            className={classes.button}
            size="large"
            onClick={() => handleApiary(uid)}
            startIcon={<ArrowBackIcon />}
          >
            {t("Geri")}
          </Button>
        <Grid className={classes.hiveHeader} item xs={12} md={6} lg={6}>
          <Button
            variant="contained"
            color="inherit"
            className={classes.button}
            size="large"
            startIcon={<InventoryIcon />}
          >
            {t("toplam")} {t("kovan")}: {currentHives.length}
          </Button>
          {/* <Card className={classes.headerCard}>
            <CardContent className={classes.cardContent}>
              {t("toplam")} {t("kovan")}: {currentHives.length}
            </CardContent>
          </Card> */}

          <Weather
            className={classes.weather}
            weatherData={weatherData}
            loadingImage={loadingImage}
          />
          {/* </Button> */}
        </Grid>
        {/* <Parallax speed={-10}> */}

        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={5}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "10rem",
                  height: "8vh",
                }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    width: "45%",
                    transform: "translateX(-20%)",
                  }}
                  src={Load}
                  alt="error"
                />
              </div>
            ) : currentHives ? (
              sortedCurrentHives?.map((item, hiveId) => (
                <Grid key={hiveId} item xs={12} md={6} lg={3}>
                  <HiveCard
                    updatedTotalHarvest={updatedTotalHarvest}
                    item={item}
                  />
                </Grid>
              ))
            ) : (
              <h3>No data available.</h3>
            )}
          </Grid>
        </Grid>
        {/* </Parallax> */}
      </Grid>
    </div>
    // </ParallaxProvider>
  );
};

export default Hives;
