import { Box, Heading } from "@chakra-ui/react";
import { Container, Image } from "@chakra-ui/react";
import Load from "../assets/loading.gif";

const Weather = ({ weatherData, loadingImage }) => {
  return (
    <div>
      <Box
        width={"10rem"}
        height={"5rem"}
        backgroundColor={"#efeefe"}    

        color={"#52616B"}
        p={"15px"}
        textAlign={"center"}
        borderRadius={"30px"}
        boxShadow={"3px 3px 3px 3px rgba(0,0,0,0.085)"}

      >
        {loadingImage ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              width: "10rem",
              height: "8vh",
            }}
          >
            <Image
              style={{ objectFit: "cover", width: "40%", transform:"translateX(-20%)" }}
              src={Load}
              alt="error"
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent:"space-around" ,
              height:"110%"
            }}
          >
            <Heading margin={0} p={0} fontSize={"14px"} fontWeight={900}>
              {weatherData?.data?.name?.split(" ")[0]}
            </Heading>
            <div
              style={{
                display: "flex",
                alignItems: "center",
 
              }}
            >
              <img
                style={{ width: "3.2rem" }}
                src={`http://openweathermap.org/img/wn/${weatherData?.data?.weather[0].icon}@2x.png`}
              />

              <Heading
                color={"#5e82f4"}
                fontSize={["20px", "20px", "20px", "20px", "20px"]}
                 m={0} p={0} marginLeft={"10px"}
              >
                {Math.round(weatherData?.data?.main.temp - 273)}
                <sup>o</sup>C
              </Heading>
            </div>
            {/* <Heading margin={0} p={0}  fontSize={"13px"}>
            {weatherData?.data?.weather[0]?.description.toUpperCase()}
          </Heading> */}
          </div>
        )}
      </Box>
    </div>
  );
};

export default Weather;
