import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  MenuItem,
  styled,
  TextField,
} from "@material-ui/core";
import { FaArchive, FaMapMarkedAlt } from "react-icons/fa";
import {
  GiHoneypot,
  GiHoneyJar,
  GiPollenDust,
  GiWaxTablet,
  GiBeetleShell,
  GiWaterFlask,
} from "react-icons/gi";
// import { FaMapMarkedAlt } from "react-icons/gr";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import { firebaseDB } from "../utils/firebaseUtil";
import moment from "moment";
import { format } from "date-fns";
import { inputLabelClasses, outlinedInputClasses } from "@mui/material";
import PieChartCards from "../components/PieChartCards";
import BarChartTotalHarvest from "../components/BarChartTotalHarvest";
import BarChartHarvestFromReport from "../components/BarChartHarvestFromReport";

const useStyles = makeStyles((theme) => ({
  reportRoot: {
    minHeight: "100vh",
    // flexGrow: 1,
    "& > *": {
      margin: "1rem 0",
    },
  },
  race: {
    display: "flex",
    justifyContent: "space-around",
  },
  cardHeaders: {
    display: "flex",
    justifyContent: "space-around",
  },

  report: {
    justifyContent: "center",
    color: "white",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "3rem",
    flexWrap: "wrap",
  },
  card: {
    width: "15.5rem",
    height: "10.5rem",
    margin: "1rem",
    // padding:"0.5rem",
    borderBottom: "2px solid orange",
    backgroundImage:
      "linear-gradient(rgba(245,240,187,0.3) 75%, rgba(255,159,41,0.5))",
  },
  title: {
    fontSize: "19px",
  },
  statistic: {
    fontSize: 40,
    fontWeigth: 500,
    "& > span": {
      fontSize: 20,
      paddingLeft: 10,
    },
  },
}));

const ApiaryReport = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const [harvestData, setHarvestData] = useState([]);
  const ref = firebaseDB.collection("users");

  const {
    currentApiaries,
    getApiaries,
    getAllHarvest,
    currentHives,
    allHarvest,
    getHives,
    getAllHives,
    allHives,
  } = useApiary();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const [selectedYear, setYear] = useState("2022");
  const [selectedApiaryId, setApiary] = useState("");
  const allApiaryIds = currentApiaries?.map((apiary) => apiary.apiaryId);
  const filteredAllHives = allHives?.filter((hive) =>
  allApiaryIds.includes(hive.apiaryId)
);


  const years = [
    2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
  ];

  const HarvestAmountNames = [
    "toplambalmiktarı",
    "toplampropolismiktarı",
    "toplampolenmiktarı",
    "toplamarımumumiktarı",
    "toplamarısutumiktarı",
    "toplamarızehrimiktarı",
  ];

  const harvestFilterByApiary = allHarvest?.filter(
    (harvest) => harvest?.apiaryId == selectedApiaryId
  );

  const harvestFilterByYear_Exist_Apiary =
    selectedApiaryId &&
    harvestFilterByApiary?.filter(
      (harvest) => harvest.date.slice(0, 4) == selectedYear
    );
  const harvestFilterByYear_None_Apiary = allHarvest?.filter(
    (harvest) => harvest.date.slice(0, 4) == selectedYear
  );

  const calculateTotalHarvest = (state, filteredList) => {
    const MonthAmounts = filteredList?.map((amount) =>
      state === 0
        ? amount?.singleHoneyAmount
        : state === 1
        ? amount?.singlePropolisAmount
        : state === 2
        ? amount?.singlePolenAmount
        : state === 3
        ? amount?.singleBeeWaxAmount
        : state === 4
        ? amount?.singleBeeMilkAmount
        : state === 5 && amount?.singleBeeVenomAmount
    );
    const MonthAmount = parseFloat(
      MonthAmounts?.reduce((pre, curr) => pre + curr, 0)
    );
    return MonthAmount;
  };

  useEffect(async () => {
    getApiaries(uid);
    getAllHarvest(uid);
    getAllHives(uid);
  }, []);
  useEffect(async () => {
    selectedApiaryId && getHives(uid, selectedApiaryId);
  }, [selectedApiaryId]);

  const StyledTextField = styled(TextField)((e) => ({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: "none",
      // backgroundImage:"linear-gradient(#efeefe 75%, rgba(0,0,0,0.7))",
      borderBottom: e.value === "" ? "1px solid  #3F4E4F" : "3px solid #3F4E4F",
      borderBottomWidth: e.value === "" ? "0.05rem" : "0.35rem",
      transition: "background-image 1s",

      // boxShadow: "1px 1px 2px 2px  #3F4E4F",
      // backgroundColor:  "#FAB718",
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "black",
      fontWeight: "900",
      backgroundColor: "#efeefe",
    },
    [`& .${inputLabelClasses.shrink}`]: {
      backgroundColor: "transparant",
      marginTop: "-1.3rem",
      padding: "0.5rem",
      fontSize: "1.1rem",
      color: "#001E6C",
      fontWeight: "700",
    },
  }));

  return (
    <div className={classes.reportRoot}>
      <h2>{t("rapor")}</h2>
      <hr />
      <h3>{t("kovandurumu")}</h3>
      <div>
        <div className={classes.race} xs={12}>
          <Grid className={classes.formControlGrid} item xs={5}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <StyledTextField
                variant="outlined"
                select
                fullWidth
                id="queen-grid-need"
                value={selectedApiaryId}
                onChange={(e) => setApiary(e.target.value)}
                label={
                  selectedApiaryId !== "" ? t("arılıklar") : t("tümarılıklar")
                }
              >
                <MenuItem value={""}>{t("tümarılıklar")}</MenuItem>
                {currentApiaries?.map((option) => (
                  <MenuItem key={option.apiaryId} value={option.apiaryId}>
                    {option.apiaryTitle}
                  </MenuItem>
                ))}
              </StyledTextField>
            </FormControl>
          </Grid>
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.cardHeaders}>
            <Card className={classes.card}>
              <CardActionArea>
                <FaMapMarkedAlt
                  style={{ fontSize: "2.5rem", color: "#FAB718" }}
                />
                <CardContent>
                  <Typography
                    className={classes.title}
                    gutterBottom
                    variant="h5"
                    component="div"
                  >
                    {t("arılıklar")}
                  </Typography>
                  <Typography className={classes.statistic}>
                  {selectedApiaryId !== "" ? 1 : currentApiaries.length}

                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

            <Card className={classes.card}>
              <CardActionArea>
                <FaArchive style={{ fontSize: "2.5rem", color: "#FAB718" }} />
                <CardContent>
                  <Typography
                    className={classes.title}
                    gutterBottom
                    variant="h5"
                    component="div"
                  >
                    {t("kovanlar")}
                  </Typography>
                  <Typography className={classes.statistic}>
                  {selectedApiaryId === ""
                  ? filteredAllHives.length
                  : currentHives.length}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
      <PieChartCards filteredAllHives={selectedApiaryId ? currentHives : filteredAllHives} />
      <hr />
      <h3>{t("hasatdurumu")}</h3>
      <div className={classes.race} xs={12}>
        <Grid className={classes.formControlGrid} item xs={5}>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <StyledTextField
              variant="outlined"
              select
              fullWidth
              id="queen-grid-need"
              required
              value={selectedYear}
              onChange={(e) => setYear(e.target.value)}
              label={t("yıllar")}
            >
              <MenuItem value={""}>{t("tümyıllar")}</MenuItem>
              <MenuItem value={"2015"}>2015</MenuItem>
              <MenuItem value={"2016"}>2016</MenuItem>
              <MenuItem value={"2017"}>2017</MenuItem>
              <MenuItem value={"2018"}>2018</MenuItem>
              <MenuItem value={"2019"}>2019</MenuItem>
              <MenuItem value={"2020"}>2020</MenuItem>
              <MenuItem value={"2021"}>2021</MenuItem>
              <MenuItem value={"2022"}>2022</MenuItem>
              <MenuItem value={"2023"}>2023</MenuItem>
              <MenuItem value={"2024"}>2024</MenuItem>
              <MenuItem value={"2025"}>2025</MenuItem>
            </StyledTextField>
          </FormControl>
        </Grid>
      </div>
      <h4>{t("Arılık")} : {selectedApiaryId ? (currentApiaries.filter(apiary => apiary.apiaryId === selectedApiaryId))[0].apiaryTitle.toUpperCase() : t("tümarılıklar")}</h4>
      <BarChartHarvestFromReport selectedYear={selectedYear} selectedApiaryId={selectedApiaryId} />
    </div>
  );
};

export default ApiaryReport;
