import styled from "styled-components";

export const GeneralContainer = styled.div`
display: flex;
flex-direction: row;
/* justify-content:center; */
`;

export const Container = styled.div`

flex-grow: 1;
`

