import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { firebaseDB } from "../utils/firebaseUtil";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import HiveFormUpdateTabs from "../components/HiveFormUpdateTabs";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  updateRoot: {
    marginTop: 90,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  apiaryImg: {
    width: 400,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },
  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));
const UpdateHive = ({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  
  const { currentUser } = useAuth();

  const { currentHives, apiaryIdFromHive } = useApiary();

  console.log('UpdateHives --> currentHives:', currentHives)

  const [oneHive, setOneHive] = useState([]);
  const { t } = useTranslation(["common"]);


  const ref = firebaseDB.collection("users");
  const uid = currentUser?.uid;
  const apiaryId = apiaryIdFromHive;
  const { id } = useParams();
  console.log('update UID: ', uid, 
              'update Apiary ID: ', apiaryId,
              'update ID: ', id)

  function updateHive(uid, apiaryId, id, updatedHive) {
    // setLoading(true);
    ref
      .doc(uid)
      .collection('apiary')
      .doc(apiaryId)
      .collection('hive')
      .doc(id)
      .update(updatedHive)
      .then(() => {
        setOneHive((prev) =>
          prev.map((element) => {
            if (element.hiveId !== updatedHive.hiveId) {
              return element;
            }
            return updatedHive;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const result = currentHives.filter((item)=> item.hiveId==id)
  console.log('UpdateHives --> result:', result[0].hiveNumber)

  const res = useMemo(() => {
    return result
      ? result[0]
      : {
        hiveId:"",
        hiveNumber:"",
        colonyState:"",
        race:"",
        healthStatus: "",
        
        queenAge:"",
        queenExist:"",
        queenColor:"",
        dailyEgg: "",  
        larvaStatus: "",
        pupaStatus: "",
        queenCellStatus: "",
        maleBeeStatus: "",
        
        queenGridNeed: "",
        superQuantity: "",
        superNeed: "",
        framesQuantity: "",
        emptyFrameNeed: "",
        framesNeed: "",
        foundationSheetsNeed: "",
        deepFoundationSheetsNeed: "",
        pollenTrapStatus: "",
        seperatorNeed:"",
        
        foodNeed: "",
        cakeNeed: "",
        syrupNeed: "",
        fondanNeed: "",
        honeySyrupNeed: "",
        honeyCakeNeed: "",
  
        description:"",
        colonyDescription:"",
        equipmentDescription:"",
        feedgingDescription:"",
        };
  }, [result]);

  const [updatedHive, setUpdatedHive] = useState(res);

  useEffect(() => {
    setUpdatedHive(res);
  }, [res]);

  const handler = (hiveToUpdate) => {
    try {
      updateHive(uid, apiaryId, id, hiveToUpdate);
      history.push(`/hiveNavigator/${id}`);
      toastSuccessNotify(t("Kovan Güncellendi"));
    } catch (error) {
      toastErrorNotify(t("Kovan Güncellenemedi"));
    }
  };

  return <HiveFormUpdateTabs hive={updatedHive} handler={handler} />;
};

export default UpdateHive;
