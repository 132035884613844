import React, { useEffect,  } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import {
  GiHoneypot,
  GiHoneyJar,
  GiPollenDust,
  GiWaxTablet,
  GiBeetleShell,
  GiWaterFlask,
} from "react-icons/gi";
// import { FaMapMarkedAlt } from "react-icons/gr";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import moment from "moment";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";
import uk from "date-fns/locale/uk";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";

import ChartDataLabels from "chartjs-plugin-datalabels";

const useStyles = makeStyles((theme) => ({

  title: {
    fontFamily: "Girassol",
    textAlign: "center",
    // justifyContent: "center",
    color: "#046582",
  },



  card: {
    width: "20rem",
    height: "10.5rem",
    margin: "1.5rem",
    // padding:"0.5rem",
    borderBottom: "2px solid orange",
    backgroundImage:
      "linear-gradient(rgba(245,240,187,0.3) 75%, rgba(255,159,41,0.5))",
  },

  cardMain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems:"center",
    width:"100vw",
    "& > *": {
      width: "20.5rem",
      height: "30.5rem",
    },
  },


  title: {
    fontSize: "19px",
  },
  statistic: {
    fontSize: 40,
    fontWeigth: 500,
    "& > span": {
      fontSize: 20,
      paddingLeft: 10,
    },
  },
  chart: {
    marginLeft: "auto",
    marginRight: "auto",
    height:"auto",
    width:"auto"

  },

}));

const BarChartTotalHarvest = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);

  const {
    getAllHives,
    getApiaries,
    allHarvest,
    getAllHarvest,
    allControlledHarvest
  } = useApiary();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  const dateCreate = moment().format("dd/MM/yyyy");
  useEffect(() => {
    getApiaries(uid);
    getAllHarvest(uid);
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
  );
  const HarvestAmountNames = [
    "toplambalmiktarı",
    "toplampropolismiktarı",
    "toplampolenmiktarı",
    "toplamarımumumiktarı",
    "toplamarısutumiktarı",
    "toplamarızehrimiktarı",
  ];

  const options = HarvestAmountNames.map((harvestNmae, index) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: {
        // anchor:"center",
        labels: {
          title: {
            display: true,
            padding: {
              top: -30,
            },
            weight: "bold",
            color: "#00325c",
            font: {
              size: 13,
            },
            align: "end",
          },
        },
        formatter: function (value) {
          return index === 0 || index === 3 ? value + " kg" : value + " gr";
        },
      },
      title: {
        display: false,
        text: "Total Honey Amount / KG",
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }));
  

  const filteredThisYearHarvestList = allControlledHarvest()?.filter(
    (harvest) => harvest.date.slice(6, 10) == dateCreate.slice(6, 10)
  );
  const filteredThisMonthHarvestList = filteredThisYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == dateCreate.slice(3, 5)
  );
  const filteredPrevMonthHarvestList = filteredThisYearHarvestList?.filter(
    (harvest) =>
      harvest.date.slice(3, 5) == parseFloat(dateCreate.slice(3, 5)) - 1
  );
  const filteredPrevPrevMonthHarvestList = filteredThisYearHarvestList?.filter(
    (harvest) =>
      harvest.date.slice(3, 5) == parseFloat(dateCreate.slice(3, 5)) - 2
  );

  const calculateTotalHarvest = (state, filteredMonthList) => {
    const MonthAmounts = filteredMonthList?.map((amount) =>
      state === 0
        ? amount?.singleHoneyAmount
        : state === 1
        ? amount?.singlePropolisAmount
        : state === 2
        ? amount?.singlePolenAmount
        : state === 3
        ? amount?.singleBeeWaxAmount
        : state === 4
        ? amount?.singleBeeMilkAmount
        : state === 5 && amount?.singleBeeVenomAmount
    );
    const MonthAmount = parseFloat(
      MonthAmounts?.reduce((pre, curr) => pre + curr, 0)
    );
    return MonthAmount;
  };

  const monthNames = (state) => {
    const MonthName = format(
      new Date().setMonth(new Date().getMonth() + state),
      `MMMM`,
      {
        locale:
          t("lang") == "tr"
            ? tr
            : t("lang") == "en"
            ? en
            : t("lang") == "uk" && uk,
      }
    );
    return MonthName;
  };

  

  const harvestData = HarvestAmountNames.map((name, index) => ({
    labels: [monthNames(-2), monthNames(-1), monthNames(0)],

    datasets: [
      {
        label: t(name),
        data: [
          calculateTotalHarvest(index, filteredPrevPrevMonthHarvestList),
          calculateTotalHarvest(index, filteredPrevMonthHarvestList),
          calculateTotalHarvest(index, filteredThisMonthHarvestList),
        ],
        borderColor: "rgba(255, 69, 0, 0.8)",
        backgroundColor: "rgba(255, 69, 0, 0.8)",
      },
    ],
  }));

 
  return (  

        

        <div className={classes.cardMain}>
          {HarvestAmountNames?.map((amountNames, index) => (
            <Card className={classes.card}>
              <CardActionArea>
                {index === 0 ? (
                  <GiHoneypot
                    style={{ fontSize: "2.5rem", color: "#FAB718" }}
                  />
                ) : index === 1 ? (
                  <GiHoneyJar
                    style={{ fontSize: "2.5rem", color: "#FAB718" }}
                  />
                ) : index === 2 ? (
                  <GiPollenDust
                    style={{ fontSize: "2.5rem", color: "#FAB718" }}
                  />
                ) : index === 3 ? (
                  <GiWaxTablet
                    style={{ fontSize: "2.5rem", color: "#FAB718" }}
                  />
                ) : index === 4 ? (
                  <GiBeetleShell
                    style={{ fontSize: "2.5rem", color: "#FAB718" }}
                  />
                ) : (
                  <GiWaterFlask
                    style={{ fontSize: "2.5rem", color: "#FAB718" }}
                  />
                )}
                <CardContent>
                  <Typography
                    className={classes.title}
                    gutterBottom
                    variant="h5"
                    component="div"
                  >
                    {t(amountNames)}
                  </Typography>
                  <Typography className={classes.statistic}>
                    {calculateTotalHarvest(index, filteredThisYearHarvestList)}
                    <span>
                      {index === 0 || index === 3
                        ? `/kg-${t("yıllık")}(${dateCreate.slice(6, 10)})`
                        : `/gr-${t("yıllık")}(${dateCreate.slice(6, 10)})`}
                    </span>
                  </Typography>
                </CardContent>
              </CardActionArea>
              <div style={{ height: "20rem", width: "100%" }}>
                {allHarvest.length !== 0 ? (
                  <Bar
                    className={classes.chart}
                    options={options[index]}
                    data={harvestData[index]}
                  />
                ) : (
                  <h6>Henüz Hasat Bulunmamaktadır.</h6>
                )}
              </div>
            </Card>
          ))}
        </div>

  );
};

export default BarChartTotalHarvest;
