import React from "react";
import moment from "moment";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import feedingImg from "../assets/duman.png";

import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import FeedingForm from "../components/FeedingForm";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  feedingImg: {
    width: 150,
  },

  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const NewFeeding = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { addFeeding, apiaryIdFromHive } = useApiary();
  const history = useHistory();

  const uid = currentUser?.uid;
  const { id } = useParams();
  const apiaryId = apiaryIdFromHive; 
  const hiveId = id;
  const { t } = useTranslation(["common"]);


  console.log('Feeding id ler:', uid, apiaryId, hiveId )

  // const handler = (newFeeding) => {
  //   try {
  //     addFeeding(uid, apiaryId, hiveId, newFeeding);
  //     history.push("/feeding/:id"); // Buranın dönüşü apiary list olmalı
  //     toastSuccessNotify("Feeding added");
  //   } catch (error) {
  //     toastErrorNotify("Feeding can not be added");
  //   }
  // };
  
  const handler = (newFeeding) => {
    addFeeding(uid, apiaryId, hiveId, newFeeding);
    history.push(`/hiveNavigator/${hiveId}`); // Buranın dönüşü apiary list olmalı
    toastSuccessNotify(t("Beslenme Eklendi"));
};
  
  const dateCreate = moment().format("DD/MM/YYYY");

  const feeding = {
    feedingId: uuidv4(),
    uid:uid,
    apiaryId:apiaryId,
    hiveId:hiveId,
    cakeNeed: 0.0,
    syrupNeed: 0.0,
    fondanNeed: 0.0,
    honeySyrupNeed: 0.0,
    honeyCakeNeed: 0.0,
    other: 0.0,
    date: dateCreate,
    cakeUnit: "kg",
    syrupUnit: "kg",
    fondanUnit: "kg",
    honeySyrupUnit: "kg",
    honeyCakeUnit: "kg",
    otherUnit: "kg",
  };

  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Grid item xs={12} md={12} lg={12}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={feedingImg} alt="feeding" className={classes.feedingImg} />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.title}>
          {/* ── {t("yenibeslenme")} ── */}
          {t("yenibeslenme")}
        </Typography>
      </div>
      <FeedingForm feeding={feeding} handler={handler} />
      </Grid>
    </Container>
  );
};

export default NewFeeding;
