import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LanguageIcon from "@mui/icons-material/Language";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import arikentLogo from "../assets/beecitylogo.png";
import { useAuth } from "../context/AuthContextProvider";
import { Link, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  navRoot:{
    width:"100%",

  },
  appBar:{
    backgroundColor: "rgb(29,29,29)",
  
  },
 
    toolbar:{
      display:"flex",
      justifyContent:"space-between"
    },
 

  // menuButton: {
  //   marginRight: theme.spacing(20),
  // },
  title: {

    cursor:"pointer",
    // paddingRight:"6rem",
    width:"100%",
    display:"block",

      display:"block",
      fontSize: 35,
      fontWeight:"200",
      fontFamily: "Girassol",
      color: "wheat",


    "& span": {
      fontSize: 40,
      color: "wheat",
    },
    "&:hover": {
      color: "wheat",
    },
    [theme.breakpoints.up("xs")]: {
      display:"none",
    },
    [theme.breakpoints.up("sm")]: {
      display:"block",

        fontSize: 20,
    },
    "& span": {
      fontSize: 10,
    },

    [theme.breakpoints.up("md")]: {  
      display:"block",

        fontSize: 35,
    },
    [theme.breakpoints.up("lg")]: {
      display:"block",
        fontSize: 35,
        
    },

  },

  logo: {
    width: "5rem",
    zIndex:1001
  },
  linkStyle: {
    textDecoration: "none",
    color: "black",
  },


}));
const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "-100%" },
};
export default function Navbar({setMenuCollapse}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElLng, setAnchorElLng] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState("");
  const open = Boolean(anchorEl);
  const openLng = Boolean(anchorElLng);
  let { currentUser, logout } = useAuth();
  const history = useHistory();


  useEffect(() => {
    i18n.changeLanguage(selectedIndex)
	}, [selectedIndex]);


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenu2 = (event) => {
    setAnchorElLng(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
    setMenuCollapse(0)
  };
  const handleApiary = () => {
    setAnchorEl(null);
    currentUser ? history.push("/home") : history.push("/login") ;
  };
  const options = [
    { lang: "Türkçe", code: "tr" },
    { lang: "English", code: "en" },
    { lang: "український", code: "uk" },
  ];
  const { i18n, t } = useTranslation(["common"]);
  const handleClickLang =   (index) => {
    setSelectedIndex(index);
    setAnchorElLng(null);
    
  };
  const handleHome = () => {
    currentUser ? history.push("/home") : history.push("/login") ;
  };

  return (
    <div className={classes.navRoot}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => handleApiary()}
          >
            <img src={arikentLogo} alt="logo" className={classes.logo} />
          </IconButton>
          <div onClick={handleHome} className={classes.titleRoot}>
            {/* <Link to="/home" className={classes.login}> */}
              <Typography variant="h2" className={classes.title}>
              <span>──── </span> {t("title")}<span> ────</span>
              </Typography>
            {/* </Link> */}
          </div>

          <div style={{display:"flex"}}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle style={{ fontSize: "35px" }} />
            </IconButton>

            {currentUser?.email ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                {/* <Link to="/profile" className={classes.linkStyle}>
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                </Link> */}
                <Link to="/new-apiary" className={classes.linkStyle}>
                  <MenuItem onClick={handleClose}>{t("yeniarılık")}</MenuItem>
                </Link>
                <Link to="/login" className={classes.linkStyle}>
                  <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
                </Link>
              </Menu>
            ) : (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <Link to="/login" className={classes.linkStyle}>
                  <MenuItem onClick={handleClose}>{t("girisyap")}</MenuItem>
                </Link>
                <Link to="/register" className={classes.linkStyle}>
                  <MenuItem onClick={handleClose}>{t("uyeol")}</MenuItem>
                </Link>
              </Menu>
            )}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenu2}
            >
              <LanguageIcon style={{ fontSize: "35px" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElLng}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={openLng}
              onClose={handleClose}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option.lang}
                  selected={index === selectedIndex}
                  onClick={() => handleClickLang(option.code)}
                >
                  {option.lang}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      </div>
  );
}

// import { motion } from "framer-motion"

// const variants = {
//   open: { opacity: 1, x: 0 },
//   closed: { opacity: 0, x: "-100%" },
// }

// export const MyComponent = () => {
//   const [isOpen, setIsOpen] = useState(false)

//   return (
//     <motion.nav
//       animate={isOpen ? "open" : "closed"}
//       variants={variants}
//     >
//       <Toggle onClick={() => setIsOpen(isOpen => !isOpen)} />
//       <Items />
//     </motion.nav>
//   )
// }
