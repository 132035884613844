import { ArrowBackIosNewTwoTone, TryRounded } from "@mui/icons-material";
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { firebaseDB } from "../utils/firebaseUtil";
import { useAuth } from "../context/AuthContextProvider";
import firebase from "firebase";

// import { doc, getDoc } from "firebase/firestore";
// import { useHistory, useParams, withRouter } from "react-router-dom";

// import { v4 as uuidv4 } from "uuid";
// import NewHarvest from "../pages/NewHarvest";

// Creating context for global data
const ApiaryContext = createContext();

// Defining a method for getting context
// it returns state and dispatch
export function useApiary() {
  return useContext(ApiaryContext);
}

// Defining a method for ApiaryContext.Provider
export function ApiaryContextProvider({ children }) {
  const [userIsPro, setUserIsPro] = useState([]);
  const [currentApiaries, setCurrentApiaries] = useState([]);
  const [oneApiary, setOneApiary] = useState([]);

  const [currentHives, setCurrentHives] = useState([]);
  const [allHives, setAllHives] = useState([]);

  // const [oneHive, setOneHive] = useState([]);

  const [currentHarvest, setCurrentHarvest] = useState([]);

  const [allHarvest, setAllHarvest] = useState([]);

  const [oneHarvest, setOneHarvest] = useState([]);

  const [totalHarvest, setTotalHarvest] = useState({});

  const [saveHiveList, setSaveHiveList] = useState([]);

  const [currentFeeding, setCurrentFeeding] = useState([]);

  const [currentNotes, setCurrentNotes] = useState([]);

  const [allNotes, setCurrentAllNotes] = useState([]);

  const [loading, setLoading] = useState(true);

  const [methods, setMethods] = useState({});

  const [isUserPro, setIsUserPro] = useState(false);

  // let { currentUser } = useAuth();

  // const [title, setTitle] = useState("");
  // const [desc, setDesc] = useState("");

  // const { id } = useParams();
  // console.log('id: ', id)
  console.log("Current Apiary:", currentApiaries);
  console.log("Current Notes:", currentNotes);

  // const uid = 'QpbPah3vduXMXOcS0qslVuKi53F2';
  // const uid = currentUser.uid;
  const ref = firebaseDB.collection("users");
  const { currentUser } = useAuth();

  // ****** Add Functions *********
  const uid = currentUser?.uid;
  async function addUserIsPro(uid) {
    await ref
      .doc(uid)
      .set({ isPro: false })

      .catch((err) => {
        console.error(err);
      });
    console.log("addUserOk?", uid);
  }
  function addApiary(uid, newApiary) {
    ref
      .doc(uid)
      .collection("apiary")
      .doc(newApiary.apiaryId)
      .set(newApiary)
      // .then(() => {
      //   setCurrentApiaries((prev) => [newApiary, ...prev]);
      // })
      .catch((err) => {
        console.error(err);
      });
  }

  function addHive(uid, newHive, apiaryId) {
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(newHive.hiveId)
      .set(newHive)
      // .then(() => {
      //   setCurrentHives((prev) => [newHive, ...prev]);
      // })
      .catch((err) => {
        console.error(err);
      });
  }
  function addTotalHarvest(uid, apiaryId, years, apiaryHarvest) {
    years.forEach((year) =>
      ref
        .doc(uid)
        .collection("apiary")
        .doc(apiaryId)
        .collection("totalHarvest")
        .doc(String(year))
        .set(apiaryHarvest)
        .catch((err) => {
          console.error(err);
        })
    );
  }

  function addNote(uid, newNote, apiaryId) {
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("note")
      .doc(newNote.noteId)
      .set(newNote)
      // .then(() => {
      //   setCurrentNotes((prev) => [newNote, ...prev]);
      // })
      .catch((err) => {
        console.error(err);
      });
  }

  function addHarvest(uid, apiaryId, hiveId, newHarvest) {
    console.log(
      "Add Harvest İçine gönderilenler:",
      "Uid:",
      uid,
      "apiaryId:",
      apiaryId,
      "hiveId:",
      hiveId,
      "newHarvest:",
      newHarvest
    );
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .collection("harvest")
      .doc(newHarvest.harvestId)
      .set(newHarvest)
      // .then(() => {
      //   setCurrentHarvest((prev) => [newHarvest, ...prev]);
      // })
      .catch((err) => {
        console.error(err);
      });
    console.log("Add Harvest İçine gönderilen idler:", uid, apiaryId, hiveId);
  }
  function addFeeding(uid, apiaryId, hiveId, newFeeding) {
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .collection("feeding")
      .doc(newFeeding.feedingId)
      .set(newFeeding)
      // .then(() => {
      //   setCurrentFeeding((prev) => [newFeeding, ...prev]);
      // })
      .catch((err) => {
        console.error(err);
      });
  }
  // ****** Get Functions *********
  // function getUser(uid) {
  //   setLoading(true);
  //   ref
  //     .doc(uid)
  //     .get().then((doc) =>{
  //       const item = doc.data().isPro;
  //       setIsUserPro(item);
  //       console.log("is User Pro1:", doc.data().isPro);
  //       console.log("is User Pro2:", item);
  //       setLoading(false);
  //     });

  // }
  // useEffect(() => {
  //   getUser(uid);
  //   console.log("is User Pro3:", isUserPro);
  // }, []);

  function getUserIsPro(uid) {
    setLoading(true);
    ref.doc(uid).onSnapshot((doc) => {
      setUserIsPro(doc?.data()?.isPro);
    });
    setLoading(false);
  }
  useEffect(() => {
    getUserIsPro();
  }, []);
  function getApiaries(uid) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((collection) => {
          items.push(collection.data());
        });
        setCurrentApiaries(items);
        setLoading(false);
      });
  }
  useEffect(() => {
    getApiaries();
  }, []);

  function getOneApiary(uid, apiaryId) {
    // console.log(apiaryId);
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .onSnapshot((doc) => {
        const items = [];
        items.push(doc.data());
        // querySnapshot.forEach((doc) => {
        //   items.push(doc.data());
        // });
        setOneApiary(items);
        setLoading(false);
      });
  }
  useEffect(() => {
    getOneApiary();
  }, []);

  function getHives(uid, apiaryId) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((collection) => {
          items.push(collection.data());
        });
        setCurrentHives(items);
        setLoading(false);
      });
  }

  useEffect(() => {
    getHives();
  }, []);

  async function getAllHives(uid) {
    // await getApiaries(uid);
    // const allApiaryIds = currentApiaries?.map((apiary) => apiary.apiaryId);
    setLoading(true);
    const querySnapshot = await firebaseDB
      .collectionGroup(`hive`)
      .where("uid", "==", `${uid}`)
      .get();
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
    // const filteredItems = items?.filter((hive) =>
    //   allApiaryIds.includes(hive.apiaryId)
    // );

    setAllHives(items);
    setLoading(false);
  }
  // useEffect(() => {
  //   currentUser &&  getAllHives();
  // }, []);

  function getTotalHarvest(uid, apiaryId, year) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("totalHarvest")
      .doc(year)
      .onSnapshot((doc) => {
        const items = [];
        items.push(doc.data());
        setTotalHarvest(items);
        setLoading(false);
      });
  }
  useEffect(() => {
    getTotalHarvest();
  }, []);

  function getOneHive(uid, apiaryId, hiveId) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .onSnapshot((doc) => {
        const items = [];
        items.push(doc.data());
        // querySnapshot.forEach((doc) => {
        //   items.push(doc.data());
        // });
        setCurrentHives(items);
        setLoading(false);
      });
  }
  useEffect(() => {
    getHives();
  }, []);

  function getNotes(uid, apiaryId) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("note")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((collection) => {
          items.push(collection.data());
        });
        setCurrentNotes(items);
        setLoading(false);
      });
  }
  useEffect(() => {
    getNotes();
  }, []);
  async function getAllNotes(uid) {
    setLoading(true);
    const querySnapshot = await firebaseDB
      .collectionGroup(`note`)
      .where("uid", "==", `${uid}`)
      .get();
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
    setCurrentAllNotes(items);

    setLoading(false);
  }
  // useEffect(() => {
  //   currentUser &&  getAllNotes();
  // }, []);

  function getHarvest(uid, apiaryId, hiveId) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .collection("harvest")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((collection) => {
          items.push(collection.data());
        });
        setCurrentHarvest(items);
        setLoading(false);
      });
  }

  useEffect(() => {
    getHarvest();
  }, []);

  async function getAllHarvest(uid) {
    setLoading(true);

    const querySnapshot = await firebaseDB
      .collectionGroup(`harvest`)
      .where("uid", "==", `${uid}`)
      .get();
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push(doc.data());
    });
    setAllHarvest(items);
    setLoading(false);
  }
  // useEffect(() => {
  //   currentUser && getAllHarvest(uid);
  // }, []);

  function getOneHarvest(uid, apiaryId, hiveId, harvestId) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .collection("harvest")
      .doc(harvestId)
      .onSnapshot((doc) => {
        const items = [];
        items.push(doc.data());
        // querySnapshot.forEach((doc) => {
        //   items.push(doc.data());
        // });
        setOneHarvest(items);
        setLoading(false);
      });
  }
  useEffect(() => {
    getOneHarvest();
  }, []);

  function getFeeding(uid, apiaryId, hiveId) {
    setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .collection("feeding")
      .onSnapshot((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((collection) => {
          items.push(collection.data());
        });
        setCurrentFeeding(items);
        setLoading(false);
      });
  }
  useEffect(() => {
    getFeeding();
  }, []);

  // ****** Delete Functions *********

  async function deleteOneApiary(uid, apiaryId) {
    // const hives = await firebaseDB
    //   .collectionGroup(`hive`)
    //   .where("apiaryId", "==", `${apiaryId}`);
    // // .get()
    // hives.get().then((querySnapshot) => {
    //   Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
    // });
    // const harvest = await firebaseDB
    //   .collectionGroup("harvest")
    //   .where("apiaryId", "==", `${apiaryId}`);
    // // .get();
    // harvest.get().then((querySnapshot) => {
    //   Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
    // });
    // const feedings = await firebaseDB
    //   .collectionGroup(`feeding`)
    //   .where("apiaryId", "==", `${apiaryId}`);

    // feedings.get().then((querySnapshot) => {
    //   Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
    // });
    // const years = [
    //   2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
    // ];
    // years.forEach((year) =>
    //   ref
    //     .doc(uid)
    //     .collection("apiary")
    //     .doc(apiaryId)
    //     .collection("totalHarvest")
    //     .doc(String(year))
    //     .delete()
    // );
    // const totalHarvest = ref
    //   .doc(uid)
    //   .collection("apiary")
    //   .doc(apiaryId)
    //   .collection("totalHarvest");

    // totalHarvest.get().then((querySnapshot) => {
    //   Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
    // });
    ref.doc(uid).collection("apiary").doc(apiaryId).delete();
    // const notes = ref
    //   .doc(uid)
    //   .collection("apiary")
    //   .doc(apiaryId)
    //   .collection("note");

    // notes.get().then((querySnapshot) => {
    //   Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
    // });
  }

  async function deleteOneHive(uid, apiaryId, hiveId) {
    // const harvest = ref
    //   .doc(uid)
    //   .collection("apiary")
    //   .doc(apiaryId)
    //   .collection("hive")
    //   .doc(hiveId)
    //   .collection("harvest");
    // // .get();
    // harvest.get().then((querySnapshot) => {
    //   Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
    // });
    // const feedings = ref
    //   .doc(uid)
    //   .collection("apiary")
    //   .doc(apiaryId)
    //   .collection("hive")
    //   .doc(hiveId)
    //   .collection("feeding");

    // feedings.get().then((querySnapshot) => {
    //   Promise.all(querySnapshot.docs.map((d) => d.ref.delete()));
    // });
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .delete()
      .catch((err) => {
        console.error(err);
      });
  }

  function deleteOneNote(uid, apiaryId, noteId) {
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("note")
      .doc(noteId)
      .delete()
      .then(() => {
        setCurrentHives((prev) =>
          prev.filter((element) => element.noteId !== noteId)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function deleteOneHarvest(uid, apiaryId, hiveId, harvestId) {
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .collection("harvest")
      .doc(harvestId)
      .delete()
      .then(() => {
        setCurrentHarvest((prev) =>
          prev.filter((element) => element.harvestId !== harvestId)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function deleteOneFeeding(uid, apiaryId, hiveId, feedingId) {
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("hive")
      .doc(hiveId)
      .collection("feeding")
      .doc(feedingId)
      .delete()
      .then(() => {
        setCurrentFeeding((prev) =>
          prev.filter((element) => element.feedingId !== feedingId)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }
  // ****** Update Functions *********

  function updateApiary(uid, updatedApiary) {
    setLoading();
    ref
      .doc(uid)
      .collection("apiary")
      .doc(updatedApiary.apiaryId)
      .update(updatedApiary)
      .then(() => {
        setCurrentApiaries((prev) =>
          prev.map((element) => {
            if (element.apiaryId !== updatedApiary.apiaryId) {
              return element;
            }
            return updatedApiary;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function updateTotalHarvest(uid, apiaryId, year, updatedHarvest) {
    setLoading();
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("totalHarvest")
      .doc(year)
      .update(updatedHarvest)
      .catch((err) => {
        console.error(err);
      });
  }
  function updateOneNote(uid, apiaryId, noteId, updatedNote) {
    setLoading();
    ref
      .doc(uid)
      .collection("apiary")
      .doc(apiaryId)
      .collection("note")
      .doc(noteId)
      .update(updatedNote)
      .catch((err) => {
        console.error(err);
      });
  }

  async function updateTotalHives(uid) {
    const hivesList = [];
    currentApiaries?.map((item) =>
      ref
        .doc(uid)
        .collection("apiary")
        .doc(item.apiaryId)
        .collection("hive")
        .onSnapshot((querySnapshot) => {
          const itemList = [];
          querySnapshot.forEach((collection) => {
            itemList.push(collection.data());
          });
          const hivesIdList = itemList.map((e) => e.hiveId);
          hivesList.push({
            leng: itemList?.length,
            hiveId: hivesIdList,
            apiaryId: item.apiaryId,
          });
          setSaveHiveList(hivesList);
          console.log("hivesList", hivesList[0]);
        })
    );
    // return hivesList
  }

  function updateTotalHivesList(uid) {
    updateTotalHives(uid);
    saveHiveList?.forEach((item) =>
      ref.doc(uid).collection("apiary").doc(item.apiaryId).update({
        numberOfHives: item.leng,
      })
    );
  }

  // ****** Other Functions *********

  const appendMethods = (newMethods) => {
    setMethods({
      ...methods,
      ...newMethods,
    });
  };
  const allControlledHarvest = () => {
    const allApiaryIds = currentApiaries.map((apiary) => apiary.apiaryId);
    const allHiveIds = allHives.map((hive) => hive.hiveId);
    const filteredHarvest = allHarvest?.filter(
      (harvest) =>
        allApiaryIds.includes(harvest.apiaryId) &&
        allHiveIds.includes(harvest.hiveId)
    );
    return filteredHarvest;
  };

  const value = {
    addUserIsPro,
    getUserIsPro,
    userIsPro,

    addApiary,
    getApiaries,
    getOneApiary,
    deleteOneApiary,
    currentApiaries,
    oneApiary,
    updateApiary,
    updateTotalHives,
    updateTotalHivesList,
    loading,

    addHive,
    getHives,
    getAllHives,
    getOneHive,
    currentHives,
    allHives,
    deleteOneHive,

    addNote,
    getNotes,
    getAllNotes,
    currentNotes,
    deleteOneNote,
    allNotes,
    updateOneNote,

    addHarvest,
    getHarvest,
    getAllHarvest,
    getOneHarvest,
    addTotalHarvest,
    getTotalHarvest,
    updateTotalHarvest,
    currentHarvest,
    allHarvest,
    oneHarvest,
    deleteOneHarvest,
    totalHarvest,
    allControlledHarvest,

    addFeeding,
    getFeeding,
    currentFeeding,
    deleteOneFeeding,

    appendMethods,
    ...methods,
  };
  console.log("methods:", methods);

  return (
    <ApiaryContext.Provider value={value}>{children}</ApiaryContext.Provider>
  );
}
