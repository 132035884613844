import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import apiaryPng from "../assets/apiary2.jpg";
import { useHistory } from "react-router-dom";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import ApiaryForm from "../components/ApiaryForm";
import { v4 as uuidv4 } from "uuid";
import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";
import { DialogActions, DialogContentText } from "@material-ui/core";

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@material-ui/core/Button";
import StoreBadge from "react-store-badge";


 

const useStyles = makeStyles((theme) => ({
  newApiaryContainer: {
    // backgroundImage: `url(${backImage})`,
    // backgroundRepeat: "repeat",
    // backgroundSize: "auto",
  },

  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  apiaryImg: {
    width: 400,
  },

  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
    marginBottom:"3rem"
  },
}));

const NewApiary = ({setValue}) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { addApiary, addTotalHarvest } = useApiary();
  const history = useHistory();
  const uid = currentUser?.uid;
  const { t } = useTranslation(["common"]);
  const [openModal, setOpenModal] = useState(false);


  console.log('history: ', history);
  const [apiaryHarvest, setApiaryHarvest] = useState({totalHoneyAmount :0,
    totalPropolisAmount :0,
    totalPolenAmount :0,
    totalBeeWaxAmount :0,
    totalBeeMilkAmount :0,
    totalBeeVenomAmount :0});

    const years = [2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025]
    const {
      currentHives,
      getUserIsPro,
      userIsPro,
      addUserIsPro,
      getApiaries,
      currentApiaries
    } = useApiary();

    useEffect(() => {
      getUserIsPro(uid)
      getApiaries(uid)
    }, [])
    const handleClose = () => {
      setOpenModal(false);
      toastErrorNotify(t("Arılık Eklenemedi"));
      setValue(0);
    };
     
  
  

  const handler = (newApiary) => {
    try {
     if(currentApiaries.length < 1){
       addApiary(uid, newApiary);
       addTotalHarvest(uid, newApiary.apiaryId, years, apiaryHarvest) 
       toastSuccessNotify(t("Arılık Eklendi"));
       setValue(0);


     }
     else if(userIsPro === true){
       addApiary(uid, {...newApiary,
      isActive:true})     
      addTotalHarvest(uid, newApiary.apiaryId, years, apiaryHarvest)  
      toastSuccessNotify(t("Arılık Eklendi"));
      setValue(0);

     }
     else{
      // toastErrorNotify(t("Arılık Eklenemedi"));
      setOpenModal(true);
      // history.go(-1);
      // setValue(0);
    } }
    catch (error) {
      toastErrorNotify(t("Arılık Eklenemedi"));
    }
  };

  const apiary = {
    apiaryId: uuidv4(), 
    uid: uid,
    isActive: false, 
    apiaryTitle: "",
    apiaryLocation: "", 
    apiaryNotes: "",
    numberOfHives: 0,
    latitude: 39.82206 ,
    longitude: 34.808132 ,
  };

  return (
    <div className={classes.newApiaryContainer}>
           <Dialog
              // fullScreen={fullScreen}
              open={openModal}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
              <h1>{t("Daha fazla arılık eklemek için lütfen IOS veya Android uygulamamızdan satın alınız?")}</h1>
         
               
              </DialogTitle>
              <DialogContent>

<StoreBadge
                        name="Beecity Apiary"
                        googlePlayUrl="https://play.google.com/store/apps/details?id=com.arikent.apiary"
                        appStoreUrl="https://apps.apple.com/us/app/beecity-apiary/id1633088644"
                      />
                <DialogContentText> </DialogContentText>
              </DialogContent>
              <DialogActions>
            
                <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
                {t("kapat")}
                </Button>
                 {/* <Button onClick={() => deleteHandler(uid,isDeleteApiaryId )}  variant="outlined" color="primary" autoFocus>
                 {t("sil")}
                </Button> */}
                
              </DialogActions>
            </Dialog>
  
    <Container>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={apiaryPng} alt="New Apiary" className={classes.apiaryImg} />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.title}>
          ── {t("yeniarılık")} ──
        </Typography>
      </div>
      <ApiaryForm apiary={apiary} handler={handler} />
    </Container>
    </div>
  );
};

export default NewApiary;
