import React, { useState, useEffect, useMemo } from "react";

import { firebaseDB } from "../utils/firebaseUtil";
import { useAuth } from "../context/AuthContextProvider";
import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import { useHistory, useParams } from "react-router-dom";
import { useApiary } from "../context/ApiaryContextProvider";

import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import placeholderPng from "../assets/hive.png";
import ApiaryForm from "../components/ApiaryForm";
import { alpha, styled } from "@mui/material/styles";

import { outlinedInputClasses } from "@mui/material/OutlinedInput";

import { inputLabelClasses, selectLabel, TextField } from "@mui/material";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  updateRoot: {
    marginTop: 90,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  select: {
    width: "100%",
    marginBottom:"1rem",
    backgroundColor:"#ffd54f",
  },
  form: {
     width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  updateFormContainer : {
    display:"flex",
    flexDirection:"column",

  },
  updateFormControl: {
    width: "90vw", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },
  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const UpdateApiaryForTabs = ({ match, apiaryID, isUpdateApiary }) => {
  const classes = useStyles();
  const history = useHistory();

  let { currentUser } = useAuth();

  const { currentApiaries } = useApiary();
  // const { getOneApiary, updateApiary } = useApiary();
  // const [currentApiaries, setCurrentApiaries] = useState([]);

  const [oneApiary, setOneApiary] = useState([]);
  const [updateId, setUpdateId] = useState(apiaryID);


  const ref = firebaseDB.collection("users");
  const uid = currentUser?.uid;

  const { id } = useParams();
  console.log("update UID:", uid, "update ID:", id);

  function updateApiary(uid, updateId, updatedApiary) {
    // setLoading(true);
    ref
      .doc(uid)
      .collection("apiary")
      .doc(updateId)
      .update(updatedApiary)
      .then(() => {
        setOneApiary((prev) =>
          prev.map((element) => {
            if (element.apiaryId !== updatedApiary.apiaryId) {
              return element;
            }
            return updatedApiary;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // const result = getOneApiary(uid, id);
  const result = currentApiaries.filter((item) => item.apiaryId == updateId);

  const res = useMemo(() => {
    return result
      ? result[0]
      : {
          apiaryId: "",
          apiaryTitle: "",
          apiaryLocation: "",
          apiaryNotes: "",
          numberOfHives: "",
          latitude: 39.82206 ,
          longitude: 34.808132 ,
        };
  }, [result]);

  const [updatedApiary, setUpdatedApiary] = useState(res);

  useEffect(() => {
    setUpdatedApiary(res);
  }, [res]);


  const handler = (apiaryToUpdate) => {
    try {
      updateApiary(uid, updateId, apiaryToUpdate);
      history.push("/userNavigator/:id"); // ekleme
      toastSuccessNotify(t("Arılık Güncellendi"));
    } catch (error) {
      toastErrorNotify(t("Arılık Güncellenemedi"));
    }
  };
  const StyledTextField = styled(TextField)((e) => ({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border:  "none",
      borderBottom:  e.value === undefined ? "" :  "3px solid #001E6C",
      borderBottomWidth : e.value === undefined ? "0" : "0.35rem",
      transition: "border-bottom 0.4s",

    },
    [`& .${outlinedInputClasses.input}`]: {
      color: e.value === undefined ? "gray" : "#2E0249",
      fontWeight: "900"
    },
    [`& .${inputLabelClasses.shrink}`]: {
      backgroundColor: "transparant",
      marginTop:"-1.1rem",
      padding:"0.5rem",
      fontSize:"1.3rem",
      color:"#001E6C",
      fontWeight:"900",
    },
  }));

  return (
    <div>
      <Container>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img
              src={updatedApiary?.image || placeholderPng}
              alt="Update Apiary"
              className={classes.apiaryImg}
            />
          </Avatar>
          <Typography component="h1" variant="h5" className={classes.title}>
            ── {t("arılıkduzenle")} ──
          </Typography>
          <div className={classes.updateFormContainer}>
          <FormControl variant="outlined" className={classes.updateFormControl}>
            <StyledTextField
              variant="outlined"
              select
              fullWidth                  
              name="colony-state"
              id="demo-simple-select-outlined"
              value={updateId}
              onChange={(e) => setUpdateId(e.target.value)}
              label={t("arılıkseciniz")}
              className={classes.select}
            >
              {currentApiaries?.map((option) => (
                <MenuItem key={option.apiaryTitle} value={option.apiaryId}>
                  {option.apiaryTitle}
                </MenuItem>
              ))}
            </StyledTextField>
          </FormControl>
          <ApiaryForm updateId={updateId} apiary={updatedApiary} handler={handler} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UpdateApiaryForTabs;
