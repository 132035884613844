import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { Suspense } from "react";
import LandingPage from "../landingpage/LandingPage";
import { useAuth } from "../context/AuthContextProvider";
import Forum from "../landingpage/Forum";
import CardDetails from "../landingpage/CardDetails";
import ForumNavbar from "../landingpage/components/ForumNavbar";
import Admin from "../landingpage/Admin";


const LandingPageRouter = ({setOnLandingPage}) => {
  return (
    <Suspense fallback={null}>
      <Router>
        <Switch>
          <Route exact path="/" component={() => <LandingPage setOnLandingPage={setOnLandingPage}  />} />
          </Switch>
        <ForumNavbar />
          <Switch>
          <Route exact path="/forum" component={Forum} />
          <Route path="/forum/carddetails" component={CardDetails} />
          <Route path="/admin" component={Admin} />

        </Switch>
      </Router>
    </Suspense>
  );
};

export default LandingPageRouter;
