import './weatherPage.css'
import { Deatils } from './components/Details';
import { Navbar } from './components/Navbar';


function WeatherPage() {
    console.log('Weather Page')
    return (
        <div className='weatherPage' style= {{backgroundColor: 'white',height:"100vh"}}>
               
            <Navbar />
            <Deatils />
             
        </div>
    )
}

export default WeatherPage;
