import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useHistory } from "react-router-dom";
import { MdForum } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { MdQuickreply } from "react-icons/md";
import { useAuth } from "../../context/AuthContextProvider";

const ForumNavbar = () => {
    const history = useHistory()
    let { currentUser } = useAuth();
    const uid = currentUser?.uid;
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    <Container>
      <Navbar.Brand onClick={() => history.push("/")} >BeeCity Apiary</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link onClick={() => history.push("/")}>Ana Sayfa</Nav.Link>
          <Nav.Link  onClick={() => history.push("/forum")}>Forum</Nav.Link>
          {uid === "C0KbASmYSsM3mOOYWVvvxVCHlUH2" && <Nav.Link  onClick={() => history.push("/admin")}>Admin</Nav.Link>}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default ForumNavbar