import { ChakraProvider } from '@chakra-ui/react'
import axios from 'axios'
import React from 'react'
// import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import WeatherPage from './weatherPage.jsx'
import { store } from './redux/store'

axios.defaults.baseURL = "https://api.openweathermap.org/data/2.5";

function WeatherWrapper () {
  // const store = createStore(rootReducer);

  return (
    <React.StrictMode>
    <ChakraProvider>
      <ReduxProvider store={store}>
        <WeatherPage/>
      </ReduxProvider>
    </ChakraProvider>
  </React.StrictMode>
  );
}
export default WeatherWrapper;
// const WeatherPage = () => {
//   const dispatch = useDispatch();
