import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import clsx from 'clsx';

import { makeStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { alpha, styled } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  selectClasses,
} from "@mui/material";
import { inputLabelClasses, selectLabel } from "@mui/material";
import BackGroundBee from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContextProvider";
import { useHistory, useParams } from "react-router-dom";
import { useApiary } from "../context/ApiaryContextProvider";
import { DialogActions, DialogContentText } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from '@mui/icons-material/Save';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  hiveTabsRoot: {
    // backgroundImage : "linear-gradient(rgba(245,240,187,0.6) 75%, rgba(255,159,41,0.7))",
    width: "100%",
    height: "100vh",
    margin: 0,
    padding: 0,
    position: "relative",
  },
  hiveTabsRootImage: {
    width: "100vw",
    height: "80vh",
    position: "absolute",
    left: 0,
    top: 0,
    opacity: 0.3,
    objectFit: "cover",
  },

  formContainer: {
    // display: "flex",
    // flexDirection: "row",
  },

  hiveTabsSwipe: {
    height: "70vh",
  },

  avatar: {
    padding: theme.spacing(13),
    // backgroundColor: "#046582",
  },
  formHive: {
    // width: "100%", // Fix IE 11 issue.
    // flexGrow: 1,
    // backgroundColor: "White",
    // marginTop: theme.spacing(2),
    // padding: theme.spacing(5),
  },

  formSection: {
    justifyContent: "space-around",
    display: "flex",
    flexWrap: "wrap",
    // padding: "0.5 rem",
    // borderRadius: "1rem",
  },
  formControl: {
    borderRadius: "1rem",
  },
  formSectionName: {
    fontSize: "25px",
  },
  submit: {
    margin: theme.spacing(5, 0),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },

  formControlGrid: {
    marginTop: theme.spacing(2),
    margin: theme.spacing(0.5),
    minWidth: 100,
    backgroundColor: "white",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  textField: {
    borderColor: "red",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(15),
    top: theme.spacing(8),
    right: theme.spacing(5),
    // backgroundColor:"rgba(255,69,0,0.8)"
    
  },
  fabOrange: {
    color: theme.palette.common.white,
    backgroundColor:"rgba(255,69,0,0.8)",
    '&:hover': {
      backgroundColor: "rgba(255,69,0)",
    },
  },
  fabIn: {
    position: "sticky",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },

}));
const StyledTextField = styled(TextField)((e) => ({
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "none",
    backgroundImage:
      (e.value === "" || e.value === "Seçiniz" || e.value === "0")
        ? "linear-gradient(rgba(241,238,233,0.6) 65%, rgba(240,235,227,0.7))"
        : e.value === "Zayıf" ||
          e.value === "Hastalıklı" ||
          (e.value === "Yok" && e.id === "daily-egg") ||
          (e.value === "Yok" && e.id === "queen-exist") ||
          (e.value === "Yok" && e.id === "larva-status") ||
          (e.value === "Yok" && e.id === "pupa-status") ||
          (e.value === "Var" && e.id === "queen-cell-status") ||
          (e.value === "Fazla" && e.id === "male-bee-status") ||
          (e.value !== "0" && e.id === "queen-grid-need") ||
          (e.value !== "0" && e.id === "super-need") ||
          (e.value !== "0" && e.id === "frames-need") ||
          (e.value !== "0" && e.id === "seperator-need")
        ? "linear-gradient(rgba(255, 112, 67, 0.6) 65%, rgba(240,235,227,0.7))"
        : "linear-gradient(rgba(0,255,0,0.45) 55%, rgba(240,235,227,0.7))",
    borderBottom: (e.value === "" || e.value === "Seçiniz" || e.value === "0") ? "1px solid  #3F4E4F" : "3px solid #3F4E4F",
    borderBottomWidth: (e.value === "" || e.value === "Seçiniz" || e.value === "0") ? "0.05rem" : "0.35rem",
    transition: "background-image 1s",

    // boxShadow: "1px 1px 2px 2px  #3F4E4F",
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: e.value === "" ? "gray" : "#3F4E4F",
    fontWeight: "900",
  },
  [`& .${inputLabelClasses.shrink}`]: {
    backgroundColor: "transparant",
    marginTop: "0rem",
    padding: "0.5rem",
    fontSize: "1.1rem",
    color: "#001E6C",
    fontWeight: "700",
  },
}));

const HiveFormTabs = (props) => {
  const { getHives, currentHives } = useApiary();
  const { hive, handler, setOpen } = props;
  const history = useHistory();

  const { id } = useParams();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [newHive, setNewHive] = useState(hive);
  const { t } = useTranslation(["common"]);

  console.log(newHive.hiveNumber === "");
  




 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const fabs = [
    {
    color: "primary",
    className: clsx(classes.fab, classes.fabOrange),
    icon: <SaveIcon />,
    label: t("kaydet"),
    onClick: () => setOpenModal(true),
    },
    {
    color: "primary",
    className: classes.fab,
    icon: <SaveIcon />,
    label: t("kaydet"),
    // onClick: () => NewApiaryHandler(uid),
    },
    {
    color: "primary",
    className: classes.fab,
    icon: <SaveIcon />,
    label: t("kaydet"),
    // onClick: () => NewApiaryHandler(uid),
    },

  ];
  console.log(theme.direction);

  useEffect(() => {
    setNewHive(hive);
  }, [hive]);
  useEffect(() => {
    getHives(uid, id);
  }, []);

 
  const handleClose = () => {
    setOpenModal(false);
    // history.go(-1);
  };

  const sameHiveNumber = currentHives.map((hive) =>
    hive.hiveNumber === newHive.hiveNumber ? true : null
  );
  console.log(sameHiveNumber);
  const handleSubmit = () => {
    handleClose()
    !sameHiveNumber.includes(true) && handler(newHive)

  }

  return (
    <div className={classes.hiveTabsRoot}>
    
      {/* <img className={classes.hiveTabsRootImage} src={BackGroundBee} alt="no" /> */}
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          <Tab label={t("koloni")} {...a11yProps(0)} />
          <Tab label={t("anaarı")} {...a11yProps(1)} />
          <Tab label={t("ekipman")} {...a11yProps(2)} />
          {/* <Tab label={t("beslenme")} {...a11yProps(3)} /> */}
        </Tabs>
      </AppBar>
      {/* <Grid container spacing={3} className={classes.formContainer}> */}
      <form
        // className={classes.formHives}
        onSubmit={() => !sameHiveNumber.includes(true) && handler(newHive)}
      >

         <Dialog
            // fullScreen={fullScreen}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
            {/* <h1>Eksik Bilgi</h1> */}
            <hr />
              {newHive.hiveNumber === "" ? <h3>{t("kovannogiriniz")}!</h3> :  (<div>
              {newHive.colonyState === "Seçiniz" && <h4>{t("populasyon")}</h4>} 
              {newHive.race === "Seçiniz" && <h4>{t("arıırkı")}</h4>}
              {newHive.healthStatus === "Seçiniz" && <h4>{t("saglıkdurumu")}</h4>}
              {newHive.queenExist === "Seçiniz" && <h4>{t("anaarıdurumu")}</h4>}
              {newHive.dailyEgg === "Seçiniz" && <h4>{t("yumurtadurumu")}</h4>}
              {newHive.larvaStatus === "Seçiniz" && <h4>{t("larvadurumu")}</h4>}
              {newHive.pupaStatus === "Seçiniz" && <h4>{t("pupadurumu")}</h4>}
             
              </div>)}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>  {newHive.hiveNumber !== "" && <h3>{t("Tüm Bilgileri Eklediğinize eminseniz kaydete basarak devam ediniz")}</h3>}</DialogContentText>
            </DialogContent>
            <DialogActions>
          
              <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
                {t("kapat")}
              </Button>
              {newHive.hiveNumber !== "" && <Button onClick={handleSubmit} type="submit" variant="outlined" color="primary" autoFocus>
                {t("kaydet")}
              </Button> }
              
            </DialogActions>
          </Dialog>
        <SwipeableViews
          className={classes.hiveTabsSwipe}
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >

       
          {/* Kovan Genel Koloni Durumu */}
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Grid className={classes.formSectionName} item xs={12}>
              {t("genelkolonidurumu")}
              <Grid className={classes.formSection} item xs={12}>
                {/* Kovan Numarası */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      autoFocus
                      required
                      id="HiveNumber"
                      label={t("kovannogiriniz") + "..."}
                      name="hiveNumber"
                      value={newHive.hiveNumber}
                      helperText={
                        sameHiveNumber.includes(true)
                          ? t("Aynı kovan numarası girilemez")
                          : ""
                      }
                      error={sameHiveNumber.includes(true)}
                      // color={newHive.hiveNumber === "" ? "primary" : "secondary"}

                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          hiveNumber:
                            e.target.value !== ""
                              ? parseFloat(e.target.value)
                              : 0,
                        })
                      }
                    />
                  </FormControl>
                </Grid>

                {/* Kovandaki Arıların Popülasyonu*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      name="colony-state"
                      id="colony-state"
                      value={newHive.colonyState}
                      onChange={(e) =>
                        setNewHive({ ...newHive, colonyState: e.target.value })
                      }
                      label={t("populasyon")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Zayıf">{t("Zayıf")}</MenuItem>
                      <MenuItem value="Normal">{t("Normal")}</MenuItem>
                      <MenuItem value="Güçlü">{t("Güçlü")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Kovandaki Arıların Irkı*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      name="colony-state"
                      id="Race"
                      value={newHive.race}
                      onChange={(e) =>
                        setNewHive({ ...newHive, race: e.target.value })
                      }
                      label={t("arıırkı")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Karniyol">{t("Karniyol")}</MenuItem>
                      <MenuItem value="Kafkas">{t("Kafkas")}</MenuItem>
                      <MenuItem value="İtalyan">{t("İtalyan")}</MenuItem>
                      <MenuItem value="Esmer">{t("Esmer")}</MenuItem>
                      <MenuItem value="Anadolu">{t("Anadolu")}</MenuItem>
                      <MenuItem value="Suriye">{t("Suriye")}</MenuItem>
                      <MenuItem value="İran">{t("İran")}</MenuItem>
                      <MenuItem value="Afrika">{t("Afrika")}</MenuItem>
                      <MenuItem value="Rusya">{t("Rusya")}</MenuItem>
                      <MenuItem value="Diğer">{t("Diğer")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Kovandaki Arıların Sağlık Durumu*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      name="colony-state"
                      id="health-status"
                      value={newHive.healthStatus}
                      onChange={(e) =>
                        setNewHive({ ...newHive, healthStatus: e.target.value })
                      }
                      label={t("saglıkdurumu")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Sağlıklı">{t("Sağlıklı")}</MenuItem>
                      <MenuItem value="Hastalıklı">{t("Hastalıklı")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>
                {/* Açıklamalar */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  lg={11}
                >
                  <StyledTextField
                    id="outlined-multiline-static"
                    label={t("notlar")}
                    name="colonyDescription"
                    multiline
                    value={newHive.colonyDescription}
                    onChange={(e) =>
                      setNewHive({
                        ...newHive,
                        colonyDescription: e.target.value,
                      })
                    }
                    fullWidth
                    rows={5}
                    variant="outlined"
                  />
                  {/* <Button
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                  >
                    {t("onay")}
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Ana Arı Bölümü */}
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Grid className={classes.formSectionName} item xs={12}>
              {t("anaarıbolumu")}
              <Grid className={classes.formSection} item xs={12}>
                {/* Ana Arı Durumu*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="queen-exist"
                      value={newHive.queenExist}
                      onChange={(e) =>
                        setNewHive({ ...newHive, queenExist: e.target.value })
                      }
                      label={t("anaarıdurumu")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Var">{t("Var")}</MenuItem>
                      <MenuItem value="Yok">{t("Yok")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Ana Arı Doğum Yılı*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      name="colony-state"
                      id="queen-age"
                      value={newHive.queenAge}
                      onChange={(e) =>
                        setNewHive({ ...newHive, queenAge: e.target.value })
                      }
                      label={t("anaarıdogumyılı")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value={"2018"}>2018</MenuItem>
                      <MenuItem value={"2019"}>2019</MenuItem>
                      <MenuItem value={"2020"}>2020</MenuItem>
                      <MenuItem value={"2021"}>2021</MenuItem>
                      <MenuItem value={"2022"}>2022</MenuItem>
                      <MenuItem value={"2023"}>2023</MenuItem>
                      <MenuItem value={"2024"}>2024</MenuItem>
                      <MenuItem value={t("bilinmiyor")}>
                        {t("bilinmiyor")}
                      </MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Ana Arı Rengi*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      name="queen-color"
                      id="queen-color"
                      value={newHive.queenColor}
                      onChange={(e) =>
                        setNewHive({ ...newHive, queenColor: e.target.value })
                      }
                      label={t("anaarırengi")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Sarı">{t("Sarı")}</MenuItem>
                      <MenuItem value="Beyaz">{t("Beyaz")}</MenuItem>
                      <MenuItem value="Mavi">{t("Mavi")}</MenuItem>
                      <MenuItem value="Yeşil">{t("Yeşil")}</MenuItem>
                      <MenuItem value="Kırmızı">{t("Kırmızı")}</MenuItem>
                      <MenuItem value="Renk Yok">{t("Renk Yok")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Yumurta Durumu*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="daily-egg"
                      value={newHive.dailyEgg}
                      onChange={(e) =>
                        setNewHive({ ...newHive, dailyEgg: e.target.value })
                      }
                      label={t("yumurtadurumu")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Var">{t("Var")}</MenuItem>
                      <MenuItem value="Yok">{t("Yok")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Larva Durumu*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="larva-status"
                      value={newHive.larvaStatus}
                      onChange={(e) =>
                        setNewHive({ ...newHive, larvaStatus: e.target.value })
                      }
                      label={t("larvadurumu")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Var">{t("Var")}</MenuItem>
                      <MenuItem value="Yok">{t("Yok")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Pupa Durumu*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="pupa-status"
                      value={newHive.pupaStatus}
                      onChange={(e) =>
                        setNewHive({ ...newHive, pupaStatus: e.target.value })
                      }
                      label={t("pupadurumu")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Var">{t("Var")}</MenuItem>
                      <MenuItem value="Yok">{t("Yok")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Ana Arı Memesi*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="queen-cell-status"
                      value={newHive.queenCellStatus}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          queenCellStatus: e.target.value,
                        })
                      }
                      label={t("anaarımemesi")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Var">{t("Var")}</MenuItem>
                      <MenuItem value="Yok">{t("Yok")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Erkek Arı Durumu*/}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="male-bee-status"
                      value={newHive.maleBeeStatus}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          maleBeeStatus: e.target.value,
                        })
                      }
                      label={t("erkekarıdurumu")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Normal">{t("Normal")}</MenuItem>
                      <MenuItem value="Fazla">{t("Fazla")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>
                {/* Açıklamalar */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  lg={11}
                >
                  <StyledTextField
                    id="outlined-multiline-static"
                    label={t("notlar")}
                    name="description"
                    multiline
                    value={newHive.description}
                    onChange={(e) =>
                      setNewHive({ ...newHive, description: e.target.value })
                    }
                    fullWidth
                    rows={5}
                    variant="outlined"
                  />
                  {/* <Button
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                  >
                    {t("onay")}
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Ekipman İhtiyacı */}
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Grid className={classes.formSectionName} item xs={12}>
              {t("ekipmanihtiyacı")}
              <Grid className={classes.formSection} item xs={12}>
                {/* Izgara İhtiyacı */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="queen-grid-need"
                      value={newHive.queenGridNeed}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          queenGridNeed: e.target.value,
                        })
                      }
                      label={t("ızgaraihtiyacı")}
                    >
                      {/* <MenuItem value={"Seçiniz"}><em>Seçiniz</em></MenuItem> */}
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Bölme Tahtası İhtiyacı */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="seperator-need"
                      value={newHive.seperatorNeed}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          seperatorNeed: e.target.value,
                        })
                      }
                      label={t("bolmetahtasıihtiyacı")}
                    >
                      {/* <MenuItem value={"Seçiniz"}><em>Seçiniz</em></MenuItem> */}
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Kat Miktarı */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="super-quantity"
                      value={newHive.superQuantity}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          superQuantity: e.target.value,
                        })
                      }
                      label={t("katmiktarı")}
                    >
                      {/* <MenuItem value={"Seçiniz"}><em>Seçiniz</em></MenuItem> */}
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Kat İhtiyacı */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="super-need"
                      value={newHive.superNeed}
                      onChange={(e) =>
                        setNewHive({ ...newHive, superNeed: e.target.value })
                      }
                      label={t("katihtiyacı")}
                    >
                      {/* <MenuItem value={"Seçiniz"}><em>Seçiniz</em></MenuItem> */}
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Çerçeve Adedi */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="frames-quantity"
                      value={newHive.framesQuantity}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          framesQuantity: e.target.value,
                        })
                      }
                      label={t("cerceveadedi")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Çerçeve İhtiyacı */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="frames-need"
                      value={newHive.emptyFrameNeed}
                      onChange={(e) =>
                        setNewHive({ ...newHive, emptyFrameNeed: e.target.value })
                      }
                      label={t("cerceveihtiyacı")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Temel Petek İhtiyacı */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="foundation-sheets-needN"
                      value={newHive.foundationSheetsNeed}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          foundationSheetsNeed: e.target.value,
                        })
                      }
                      label={t("temelpetekihtiyacı")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Kabartılmış Petek İhtiyacı */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="deep-foundation-sheets-need"
                      value={newHive.deepFoundationSheetsNeed}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          deepFoundationSheetsNeed: e.target.value,
                        })
                      }
                      label={t("kabartılmıspetekihtiyacı")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"3"}>3</MenuItem>
                      <MenuItem value={"4"}>4</MenuItem>
                      <MenuItem value={"5"}>5</MenuItem>
                      <MenuItem value={"6"}>6</MenuItem>
                      <MenuItem value={"7"}>7</MenuItem>
                      <MenuItem value={"8"}>8</MenuItem>
                      <MenuItem value={"9"}>9</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>

                {/* Polen Tuzak Durumu */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={5}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <StyledTextField
                      variant="outlined"
                      select
                      fullWidth
                      id="pollen-trap-status"
                      value={newHive.pollenTrapStatus}
                      onChange={(e) =>
                        setNewHive({
                          ...newHive,
                          pollenTrapStatus: e.target.value,
                        })
                      }
                      label={t("polentuzakdurumu")}
                    >
                      <MenuItem value={"Seçiniz"}>
                        <em>{t("Seçiniz")}</em>
                      </MenuItem>
                      <MenuItem value="Var">{t("Var")}</MenuItem>
                      <MenuItem value="Yok">{t("Yok")}</MenuItem>
                    </StyledTextField>
                  </FormControl>
                </Grid>
                {/* Açıklamalar */}
                <Grid
                  className={classes.formControlGrid}
                  item
                  xs={12}
                  sm={10}
                  md={10}
                  lg={11}
                >
                  <StyledTextField
                    id="equipment-notes"
                    label={t("notlar")}
                    name="equipmentDescription"
                    multiline
                    value={newHive.equipmentDescription}
                    onChange={(e) =>
                      setNewHive({
                        ...newHive,
                        equipmentDescription: e.target.value,
                      })
                    }
                    fullWidth
                    rows={5}
                    variant="outlined"
                  />
                  {/* <Button
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                  >
                    {t("onay")}
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

        </SwipeableViews>
        {/* {fabs.map((fab, index) => ( */}
        <Zoom
          key={fabs[0].color}
          in={true}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              value === 0 ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Tooltip title={fabs[0].label} aria-label="add">
            <Fab
              aria-label={fabs[0].label}
              className={fabs[0].className}
              color={fabs[0].color}
              onClick={fabs[0].onClick}
              // type="submit"
            >
              {fabs[0].icon}
            </Fab>
          </Tooltip>
        </Zoom>
      {/* ))} */}
      </form>
    </div>
  );
};

export default HiveFormTabs;
