import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";

// import { FaMapMarkedAlt } from "react-icons/gr";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { height } from "@mui/system";

const useStyles = makeStyles((theme) => ({

  cardContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",


  },
 
  title: {
    fontFamily: "Girassol",
    textAlign: "center",
    fontSize: "19px",

  },
  homepageTypo: {
    display: "flex",
    fontFamily: "Macondo",
    textAlign: "left",
    color: "black",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  cardHiveStatus: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100vw",
    // height:  "20.5rem",
  },
  cardHiveNone: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100vw",
    height:  "5rem",
  },
  chart:{
    marginLeft:"auto",
    marginRight:"auto",
    width:"auto",
    height:"auto"
  }



}));

const PieChartCards = ({filteredAllHives}) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);

  const {
    // getApiaries,
  } = useApiary();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

 

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
  );



  const populationOptions = {
    layout: {
      padding:13
    },
    plugins: {
      legend: {
        labels: {
            // This more specific font property overrides the global property
            font: {
                size: 14,
              },
        }
      },
      datalabels: {
        labels: {
      
          title: {
            display: true,
            padding: {
              top: -30,
            },
            weight: "bold",
            color: "white",
            font: {
              size: 17,
            },
            align: "end",
          },
        },
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;

          if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
            let sum = datasets[0].data.reduce((a, b) => a + b, 0);
            let percentage = Math.round((value / sum) * 100) > 0 ? Math.round((value / sum) * 100) + "%" : null;
            return percentage;
          }
          
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };


  function totalColonyState() {
    let total = 0;
    let totalWeak = 0;
    let totalNormal = 0;
    let totalStrong = 0;

    filteredAllHives.map((item) =>
      item.colonyState == ("Seçiniz" || "Choose" || "Виберіть" || "") ||  !item.healthStatus
        ? (total += 1)
        : item.colonyState == ("Zayıf" || "Слаба" || "Weak")
        ? (totalWeak += 1)
        : item.colonyState == ("Normal" || "Середня")
        ? (totalNormal += 1)
        : item.colonyState == ("Güçlü" || "Strong") &&
         (totalStrong += 1)
    );
    return [total, totalWeak, totalNormal, totalStrong];
  }

  function totalHealthStatus() {
    let total = 0;
    let totalHealty = 0;
    let totalDiseased = 0;

    filteredAllHives.map((item) =>
      item.healthStatus == ("Seçiniz" || "Choose" || "Виберіть") ||  !item.healthStatus
        ? (total += 1)
        : item.healthStatus == ("Sağlıklı" || "Healthy" || "Здоровий")
        ? (totalHealty += 1)
        : (totalDiseased += 1)
    );
    return [total, totalHealty, totalDiseased];
  }

  function totalQueenExist() {
    let total = 0;
    let totalQueen = 0;
    let totalAbsence = 0;

    filteredAllHives.map((item) =>
      item.queenExist == ("Seçiniz" || "Choose" || "Виберіть") ||  !item.healthStatus
        ? (total += 1)
        : item.queenExist == ("Var" || "Exist" || "Існувати")
        ? (totalQueen += 1)
        : (totalAbsence += 1)
    );
    return [total, totalQueen, totalAbsence];
  }

 

  const hivePopulationData = {
    labels: [t("veriyok"), t("Güçlü"), t("Normal"), t("Zayıf")],
    datasets: [
      {
        label: t("populasyon"),      
        data: [
          totalColonyState()[0],
          totalColonyState()[3],
          totalColonyState()[2],
          totalColonyState()[1],
        ],
        borderColor: ["white"],
        backgroundColor: [
          "#ffcc80",
          "#2e7d32",
          "rgba(5, 9, 0, 0.4)",
          "#e53935",
        ],
        borderWidth: 3,
      },
    ],
  };
  console.log( totalColonyState()[0],
  totalColonyState()[3],
  totalColonyState()[2],
  totalColonyState()[1],)

  const hiveHealthData = {
    labels: [t("veriyok"),t("Sağlıklı"), t("Hastalıklı")],
    datasets: [
      {
        data: [totalHealthStatus()[0], totalHealthStatus()[1], totalHealthStatus()[2]],
        borderColor: ["white"],
        backgroundColor: ["#ffcc80","#2e7d32", "#e53935"],
        borderWidth: 3,
      },
    ],
  };
  const hiveQueenBeeData = {
    labels: [t("veriyok"),t("Var"), t("Yok") ],
    datasets: [
      {
        data: [totalQueenExist()[0], totalQueenExist()[1],totalQueenExist()[2]],
        borderColor: ["white"],
        backgroundColor: ["#ffcc80","#2e7d32", "#e53935"],
        borderWidth: 3,
      },
    ],
  };

 

  return (

      <div className={classes.cardContainer}>
        
        <div className={filteredAllHives.length === 0 ? classes.cardHiveNone : classes.cardHiveStatus}>
          <div>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
              component="div"
            >
              {t("populasyon") + " / " + filteredAllHives.length + " " + t("kovan")}
            </Typography>
            {filteredAllHives.length === 0 ? <></> : 
            <Pie
              options={populationOptions}
              className={classes.chart}
              data={hivePopulationData}
            />}
          </div>

          <div>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
              component="div"
            >
              {t("saglıkdurumu") + " / " + filteredAllHives.length + " " + t("kovan")}
            </Typography>
            
            {filteredAllHives.length === 0 ? <>Henüz Kovanınız Bulunmamaktadır</> : 
            <Pie
              options={populationOptions}
              className={classes.chart}
              data={hiveHealthData}
            />}
             
          </div>
          <div>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h5"
              component="div"
            >
              {t("anaarıdurumu") + " / " + filteredAllHives.length + " " + t("kovan")}
            </Typography>
            {filteredAllHives.length === 0 ? <></> : 
            <Pie
              options={populationOptions}
              className={classes.chart}
              data={hiveQueenBeeData}
            />}
          </div>
        </div>
</div>
        

  );
};

export default PieChartCards;
