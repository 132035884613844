import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import {
  GiHoneypot,
  GiHoneyJar,
  GiPollenDust,
  GiWaxTablet,
  GiBeetleShell,
  GiWaterFlask,
} from "react-icons/gi";
// import { FaMapMarkedAlt } from "react-icons/gr";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import moment from "moment";
import tr from "date-fns/locale/tr";
import en from "date-fns/locale/en-US";
import uk from "date-fns/locale/uk";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { format } from "date-fns";

import ChartDataLabels from "chartjs-plugin-datalabels";
import PieChartCards from "./PieChartCards";

const useStyles = makeStyles((theme) => ({
 
  title: {
    fontFamily: "Girassol",
    textAlign: "center",
    // justifyContent: "center",
    color: "#046582",
  },
  
 


  card: {
    width: "90vw",
    height: "10.5rem",
    margin: "1.5rem",
    // padding:"0.5rem",
    borderBottom: "2px solid orange",
    backgroundImage:
      "linear-gradient(rgba(245,240,187,0.3) 75%, rgba(255,159,41,0.5))",
  },

  cardMain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& > *": {
      width: "90vw",
      height: "30.5rem",
    },
  },

  title: {
    fontSize: "19px",
  },
  statistic: {
    fontSize: 40,
    fontWeigth: 500,
    "& > span": {
      fontSize: 20,
      paddingLeft: 10,
    },
  },
  chart: {
    marginLeft: "auto",
    marginRight: "auto",
    height: "auto",
    width: "85vw",
  },
}));

const BarChartHarvestFromReport = ({ selectedYear, selectedApiaryId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common"]);

  const {allControlledHarvest, getAllHives, getApiaries, allHarvest, getAllHarvest } = useApiary();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  const dateCreate = moment().format("dd/MM/yyyy");
  useEffect(() => {
    getApiaries(uid);
    getAllHarvest(uid);
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
  );
  const HarvestAmountNames = [
    "toplambalmiktarı",
    "toplampropolismiktarı",
    "toplampolenmiktarı",
    "toplamarımumumiktarı",
    "toplamarısutumiktarı",
    "toplamarızehrimiktarı",
  ];

  const options = HarvestAmountNames.map((harvestNmae, index) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: {
        // anchor:"center",
        labels: {
          title: {
            display: true,
            padding: {
              top: -30,
            },
            weight: "bold",
            color: "#00325c",
            font: {
              size: 13,
            },
            align: "end",
          },
        },
        formatter: function (value) {
          return index === 0 || index === 3 ? value + " kg" : value + " gr";
        },
      },
      title: {
        display: false,
        text: "Total Honey Amount / KG",
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }));

  

  const filteredYearHarvestList = allControlledHarvest()?.filter(
    (harvest) => selectedApiaryId ? harvest.apiaryId == selectedApiaryId && harvest.date.slice(6, 10) == selectedYear : harvest.date.slice(6, 10) == selectedYear
  );

  const januaryHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "01"
  );
  const februaryHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "02"
  );
  const marchHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "03"
  );
  const aprilHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "04"
  );
  const mayHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "05"
  );
  const juneHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "06"
  );
  const julyHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "07"
  );
  const agustHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "08"
  );
  const septemberHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "09"
  );
  const octoberHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "10"
  );
  const novemberHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "11"
  );
  const decemberHarvestList = filteredYearHarvestList?.filter(
    (harvest) => harvest.date.slice(3, 5) == "12"
  );

  const calculateTotalHarvest = (state, filteredMonthList) => {
    const MonthAmounts = filteredMonthList?.map((amount) =>
      state === 0
        ? amount?.singleHoneyAmount
        : state === 1
        ? amount?.singlePropolisAmount
        : state === 2
        ? amount?.singlePolenAmount
        : state === 3
        ? amount?.singleBeeWaxAmount
        : state === 4
        ? amount?.singleBeeMilkAmount
        : state === 5 && amount?.singleBeeVenomAmount
    );
    const MonthAmount = parseFloat(
      MonthAmounts?.reduce((pre, curr) => pre + curr, 0)
    );
    return MonthAmount;
  };



  const harvestData = HarvestAmountNames.map((name, index) => ({
    labels: [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ],

    datasets: [
      {
        label: t(name),
        data: [
          calculateTotalHarvest(index, januaryHarvestList),
          calculateTotalHarvest(index, februaryHarvestList),
          calculateTotalHarvest(index, marchHarvestList),
          calculateTotalHarvest(index, aprilHarvestList),
          calculateTotalHarvest(index, mayHarvestList),
          calculateTotalHarvest(index, juneHarvestList),
          calculateTotalHarvest(index, julyHarvestList),
          calculateTotalHarvest(index, agustHarvestList),
          calculateTotalHarvest(index, septemberHarvestList),
          calculateTotalHarvest(index, octoberHarvestList),
          calculateTotalHarvest(index, novemberHarvestList),
          calculateTotalHarvest(index, decemberHarvestList),
        ],
        borderColor: "rgba(255, 69, 0, 0.8)",
        backgroundColor: "rgba(255, 69, 0, 0.8)",
      },
    ],
  }));

  return (
    <div className={classes.cardMain}>
      {HarvestAmountNames?.map((amountNames, index) => (
        <Card key={index} className={classes.card}>
          <CardActionArea>
            {index === 0 ? (
              <GiHoneypot style={{ fontSize: "2.5rem", color: "#FAB718" }} />
            ) : index === 1 ? (
              <GiHoneyJar style={{ fontSize: "2.5rem", color: "#FAB718" }} />
            ) : index === 2 ? (
              <GiPollenDust style={{ fontSize: "2.5rem", color: "#FAB718" }} />
            ) : index === 3 ? (
              <GiWaxTablet style={{ fontSize: "2.5rem", color: "#FAB718" }} />
            ) : index === 4 ? (
              <GiBeetleShell style={{ fontSize: "2.5rem", color: "#FAB718" }} />
            ) : (
              <GiWaterFlask style={{ fontSize: "2.5rem", color: "#FAB718" }} />
            )}
            <CardContent>
              <Typography
                className={classes.title}
                gutterBottom
                variant="h5"
                component="div"
              >
                {t(amountNames)}
              </Typography>
              <Typography className={classes.statistic}>
                {calculateTotalHarvest(index, filteredYearHarvestList)}
                <span>
                  {index === 0 || index === 3
                    ? `/kg-${t("yıllık")}(${selectedYear})`
                    : `/gr-${t("yıllık")}(${selectedYear})`}
                </span>
              </Typography>
            </CardContent>
          </CardActionArea>
          <div style={{ height: "20rem", width: "100%" }}>
            {allHarvest.length !== 0 ? (
              <Bar
                className={classes.chart}
                options={options[index]}
                data={harvestData[index]}
              />
            ) : (
              <h6>Henüz Hasat Bulunmamaktadır.</h6>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default BarChartHarvestFromReport;
