import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles} from "@material-ui/core/styles";
// import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";

// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import DatePicker from './Date'

import Button from "@material-ui/core/Button";
// import { red, green, yellow } from '@material-ui/core/colors';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import Switch from '@material-ui/core/Switch';

import { useTranslation } from "react-i18next";

 


const useStyles = makeStyles((theme) => ({
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    justifyContent: "center",
    // backgroundColor: "White",
    marginTop: theme.spacing(2),
    // padding: theme.spacing(5),
  },
  formSection: {
    backgroundColor: "White",
    borderRadius: "10px",
    boxShadow: "5px 5px 3px 0px rgba(0,0,0,0.75);",
    margin: theme.spacing(0.2),
  },
  submit: {
    margin: theme.spacing(3, 0),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },
  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
  formControl: {
    marginTop: theme.spacing(1),
    margin: theme.spacing(0.5),
    minWidth: 100,
    // width: "auto",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));
const NoteForm = (props) => {
  const { note, handler } = props;
  const classes = useStyles();
  const [newNote, setNewNote] = useState(note);

  const { t } = useTranslation(["common"]);

  useEffect(() => {
    setNewNote(note);
  }, [note]);

  // const theme = createTheme({
  //   palette: {
  //     primary: green,
  //     secondary: red,
  //     inherit: yellow,
  //   },
  // });

  return (
    <form className={classes.form} onSubmit={() => handler(newNote)}>
      <Grid container spacing={3}>
   
        {/* Açıklamalar */}
        <Grid className={classes.formSection} item xs={12}>
          <TextField
            id="outlined-multiline-static"
            label={t("notlar")}
            name="notes"
            multiline
            required
            value={newNote.notes}
            onChange={(e) =>
              setNewNote({ ...newNote, notes: e.target.value })
            }
            fullWidth
            rows={10}
            variant="outlined"
          />
        </Grid>
        {/* Tarih*/}
        <Grid className={classes.formSection} item xs={12}>
        <TextField
          id="date"
          label={t("tarihsec")}
          type="date"
          value={newNote.date}
          className={classes.textField}
          justifyContent= "center"
          onChange={(e) =>
            setNewNote({ ...newNote, date: e.target.value })
          }
          InputLabelProps={{
           shrink: true,
          }}
         />
        {/* <DatePicker/> */}
        </Grid>
      </Grid>
      {/* Onay Düğmesi */}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
      >
        {t("onay")}
      </Button>
    </form>
  );
};

export default NoteForm;
