import { Box, Text } from "@chakra-ui/react";
// import { Icon } from "@chakra-ui/react";
import { ForcastBox } from "./SmallComponents";
// import { ImSun } from "react-icons/im";
// import { MdOutlineNightsStay } from "react-icons/md";
import { dateFormat } from "../helpers/extraFunctions";
// import { setItem } from "../helpers/sessionStorage";
import { ForcastModal } from "./ForcastModal";
import { useTranslation } from "react-i18next";



export const Forcast = ({ data }) => {
    const { t } = useTranslation(["common"]);

    const { date, day } = dateFormat(data.dt, t("lang"));

    return (
        <Box>
            <ForcastBox >

                <Box p={'3px'} bg={'#1D1D1D'}>   
                    <Text fontWeight={500} color={'white'} fontSize={'14px'}>{date}</Text>
                    <Text fontWeight={500} color={'white'} fontSize={'14px'}>{day}</Text>
                </Box>

                <ForcastModal data={data} />

            </ForcastBox>
        </Box>
    );
};