import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from "../components/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
// import ApiaryMap from "../components/Map";
import WeatherWrapper from "../weatherPage/weatherWrapper";
import Calendar from "../calendar/Calendar";

import { Login } from "../pages/LoginRegister";
import { Register } from "../pages/LoginRegister";
import Homepage from "../pages/Homepage";

import Apiary from "../pages/Apiary";
import NewApiary from "../pages/NewApiary";
import ApiaryDetail from "../pages/ApiaryDetails";
import UpdateApiary from "../pages/UpdateApiary";

import Hives from "../pages/Hives";
import NewHive from "../pages/NewHive";
import UpdateHive from "../pages/UpdateHive";
import HiveNavigator from "../pages/HiveNavigator";
import ApiaryNavigator from "../pages/ApiaryNavigator";
import UserNavigator from "../pages/UserNavigator";

import Harvest from "../pages/Harvest";
import NewHarvest from "../pages/NewHarvest";

import Feeding from "../pages/Feeding";
import NewFeeding from "../pages/NewFeeding";

import ApiaryNotesFromSidebar from "../pages/ApiaryNotesFromSidebar";
import NewNote from "../pages/NewNote";

import ApiaryReport from "../pages/ApiaryReport";

import Veterinary from "../pages/Veterinary";

import PrivateRouter from "./PrivateRouter";
import { GeneralContainer, Container } from "./RouterStyle";

import { Suspense } from "react";
import { useAuth } from "../context/AuthContextProvider";

// import {Container, SidebarCss} from "./RouterStyle";

const AppRouter = () => {
  const [onLandingPage, setOnLandingPage] = useState(true);
  const [menuCollapse, setMenuCollapse] = useState(0);

  let { currentUser } = useAuth();

  return (
    <Suspense fallback={null}>
      <Router>
        <Navbar setMenuCollapse={setMenuCollapse} />
        <GeneralContainer>
          <Sidebar
            setMenuCollapse={setMenuCollapse}
            menuCollapse={menuCollapse}
          />
          {/* <Sidebar styled={{SidebarCss}}/> */}
          <Container style={{ marginLeft: menuCollapse >= 1 ? "4rem" : 0 }}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />

              <Route path="/home" exact component={Homepage} />

              {/* <Route path="/map" exact component={ApiaryMap} /> */}
              <Route path="/weather-page" component={WeatherWrapper} />
              <Route path="/calendar-page" component={Calendar} />

              <PrivateRouter
                path="/userNavigator/:id"
                component={UserNavigator}
              />
              <PrivateRouter path="/apiaries/:id" component={Apiary} />
              <PrivateRouter path="/new-apiary" component={NewApiary} />
              <PrivateRouter
                path="/apiary-detail/:id"
                component={ApiaryDetail}
              />
              <PrivateRouter
                path="/update-apiary/:id"
                component={UpdateApiary}
              />

              <PrivateRouter path="/hives/:id" component={Hives} />
              <PrivateRouter path="/new-hive/:id" component={NewHive} />
              <PrivateRouter path="/update-hive/:id" component={UpdateHive} />
              <PrivateRouter
                path="/hiveNavigator/:id"
                component={HiveNavigator}
              />
              <PrivateRouter
                path="/apiaryNavigator/:id"
                component={ApiaryNavigator}
              />

              <PrivateRouter
                path="/apiary-notes/:id"
                component={ApiaryNotesFromSidebar}
              />
              <PrivateRouter path="/new-note/:id" component={NewNote} />

              <PrivateRouter
                path="/apiary-report/:id"
                component={ApiaryReport}
              />

              <PrivateRouter path="/harvest/:id" component={Harvest} />
              <PrivateRouter path="/new-harvest/:id" component={NewHarvest} />

              <PrivateRouter path="/feeding/:id" component={Feeding} />
              <PrivateRouter path="/new-feeding/:id" component={NewFeeding} />

              <PrivateRouter
                path="/veterinary-page/:id"
                component={Veterinary}
              />
            </Switch>
          </Container>
        </GeneralContainer>
        <Footer />
      </Router>
    </Suspense>
  );
};

export default AppRouter;
