import React from "react";
import Carousel from "react-bootstrap/Carousel";
import MobilImage1 from "../../assets/mobile_apiaries_image.png";
import MobilImage2 from "../../assets/mobile_apiary_map.png";
import MobilImage3 from "../../assets/mobile_beehive_harvest_status.png";
import MobilImage4 from "../../assets/mobile_beehive_report.png";
import MobilImage5 from "../../assets/mobile_beehive_status.png";
import MobilImage6 from "../../assets/mobile_beehive_status_2.png";
import MobilImage7 from "../../assets/mobile_colony_status.png";
import MobilImage8 from "../../assets/mobile_feeding_status.png";
import MobilImage9 from "../../assets/mobile_language_options_image.png";
import MobilImage10 from "../../assets/mobile_login_image.png";
import MobilImage1En from "../../assets/mobile_apiaries_image_en.png";
import MobilImage2En from "../../assets/mobile_apiary_map_en.png";
import MobilImage3En from "../../assets/mobile_beehive_harvest_status_en.png";
import MobilImage4En from "../../assets/mobile_beehive_report_en.png";
import MobilImage5En from "../../assets/mobile_beehive_status_en.png";
import MobilImage6En from "../../assets/mobile_beehive_status_2_en.png";
import MobilImage7En from "../../assets/mobile_colony_status_en.png";
import MobilImage8En from "../../assets/mobile_feeding_status_en.png";
import MobilImage9En from "../../assets/mobile_language_options_image_en.png";
import MobilImage10En from "../../assets/mobile_login_image_en.png";
import MobilImage1Uk from "../../assets/mobile_apiaries_image_uk.png";
import MobilImage2Uk from "../../assets/mobile_apiary_map_uk.png";
import MobilImage3Uk from "../../assets/mobile_beehive_harvest_status_uk.png";
import MobilImage4Uk from "../../assets/mobile_beehive_report_uk.png";
import MobilImage5Uk from "../../assets/mobile_beehive_status_uk.png";
import MobilImage6Uk from "../../assets/mobile_beehive_status_2_uk.png";
import MobilImage7Uk from "../../assets/mobile_colony_status_uk.png";
import MobilImage8Uk from "../../assets/mobile_feeding_status_uk.png";
import MobilImage9Uk from "../../assets/mobile_language_options_image_uk.png";
import MobilImage10Uk from "../../assets/mobile_login_image_uk.png";
import "./Carousel.css";

const Carousel1 = ({ screenWidth, selectedLanguage }) => {
  const imageArray = [
    MobilImage1,
    MobilImage2,
    MobilImage3,
    MobilImage4,
    MobilImage5,
    MobilImage6,
    MobilImage7,
    MobilImage8,
    MobilImage9,
    MobilImage10,
  ];
  const imageArray_en = [
    MobilImage1En,
    MobilImage2En,
    MobilImage3En,
    MobilImage4En,
    MobilImage5En,
    MobilImage6En,
    MobilImage7En,
    MobilImage8En,
    MobilImage9En,
    MobilImage10En,
  ];
  const imageArray_uk = [
    MobilImage1Uk,
    MobilImage2Uk,
    MobilImage3Uk,
    MobilImage4Uk,
    MobilImage5Uk,
    MobilImage6Uk,
    MobilImage7Uk,
    MobilImage8Uk,
    MobilImage9Uk,
    MobilImage10Uk,
  ];
  return (
    <div>
      <Carousel variant="light">
        {screenWidth < 500 &&
          (selectedLanguage === undefined ? [MobilImage1] : selectedLanguage === "tr"
            ? imageArray
            : selectedLanguage === "en"
            ? imageArray_en
            : selectedLanguage === "uk" && imageArray_uk
          )?.map((image, index) => (
            <Carousel.Item key={index}>
              <div className="carousel-images">
                <img
                  src={image}
                  alt="all mobile apiary beehive harvest feeding login language images"
                />
              </div>
            </Carousel.Item>
          ))}
      </Carousel>
      {screenWidth >= 500 && (
        <Carousel variant="light">
          <Carousel.Item>
            <div className="carousel-images">
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage1
                    : selectedLanguage === "en"
                    ? MobilImage1En
                    : selectedLanguage === "uk" && MobilImage1Uk
                }
                alt="mobile_apiaries_image"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage2
                    : selectedLanguage === "en"
                    ? MobilImage2En
                    : selectedLanguage === "uk" && MobilImage2Uk
                }
                alt="mobile_apiary_map"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage3
                    : selectedLanguage === "en"
                    ? MobilImage3En
                    : selectedLanguage === "uk" && MobilImage3Uk
                }
                alt="mobile_beehive_harvest_status"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage4
                    : selectedLanguage === "en"
                    ? MobilImage4En
                    : selectedLanguage === "uk" && MobilImage4Uk
                }
                alt="mobile_beehive_report"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage5
                    : selectedLanguage === "en"
                    ? MobilImage5En
                    : selectedLanguage === "uk" && MobilImage5Uk
                }
                alt="mobile_beehive_status"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-images">
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage6
                    : selectedLanguage === "en"
                    ? MobilImage6En
                    : selectedLanguage === "uk" && MobilImage6Uk
                }
                alt="mobile_beehive_status_2"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage7
                    : selectedLanguage === "en"
                    ? MobilImage7En
                    : selectedLanguage === "uk" && MobilImage7Uk
                }
                alt="mobile_colony_status"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage8
                    : selectedLanguage === "en"
                    ? MobilImage8En
                    : selectedLanguage === "uk" && MobilImage8Uk
                }
                alt="mobile_feeding_status"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage9
                    : selectedLanguage === "en"
                    ? MobilImage9En
                    : selectedLanguage === "uk" && MobilImage9Uk
                }
                alt="mobile_language_options_image"
              />
              <img
                src={
                  selectedLanguage === "tr"
                    ? MobilImage10
                    : selectedLanguage === "en"
                    ? MobilImage10En
                    : selectedLanguage === "uk" && MobilImage10Uk
                }
                alt="mobile_login_image"
              />
            </div>
          </Carousel.Item>
        </Carousel>
      )}
    </div>
  );
};

export default Carousel1;
