import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker,LoadScript } from '@react-google-maps/api';

const MapContainer = ({currentPosition,setCurrentPosition,openCurrentLocation }) => {
    
//   const getCoordsByLocation =  (position) => {
//     const currentPosition = {
//         lat: position.coords.latitude,
//         lng: position.coords.longitude
//       }
//       setCurrentPosition(currentPosition);
//     };
//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(getCoordsByLocation);
//   }, [currentPosition])

//  async function getCoordinates(){
//     await fetch("https://www.google.com/maps/embed/v1/place?key=AIzaSyAq15HbfCRMW7RqNb5LUNyOLyfzpYI0wl4&q=erzurum")
//       .then(response => response.json())
//       .then(data => {
//           console.log(data)
//         // const latitude = data.results.geometry.location.lat;
//         // const longitude = data.results.geometry.location.lng;
//       })
//   }
  

   
  const mapStyles = {       
    height: "55vh",
    // width: "100%"
        }

        console.log(currentPosition)

  
    const onMarkerDragEnd = (e) => { 
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setCurrentPosition({ lat, lng})
      };
      // console.log(process.env.GOOGLE_MAPS_API)
    const googleMapsApiKey= "AIzaSyAZdbMuIRkqceXh5tp9uXRfiPiY5-1HqAE"
      return (
          <div style={{ height:"50vh"}}>

        <LoadScript
        googleMapsApiKey={googleMapsApiKey}>
 
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={openCurrentLocation ? 13 : 5}
              center={currentPosition}>
              {
                currentPosition.lat ? 
                <Marker
                position={currentPosition}
                onDragEnd={(e) => onMarkerDragEnd(e)}
                draggable={true} /> :
                null
              } 
            </GoogleMap> 
            </LoadScript>
          </div>
            )
}

export default MapContainer;