import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import NotesIcon from "@material-ui/icons/Notes";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import NotesImg from "../assets/Notes.png";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// import moment from "moment";
// import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";

import placeholder from "../assets/petek.png";
import image from "../assets/apiary2.jpg";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";

import { toastErrorNotify } from "../utils/ToastNotify";
import { toastSuccessNotify } from "../utils/ToastNotify";

import { useTranslation } from "react-i18next";

 


const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: 245,
    maxWidth: 245,
  },
  media: {
    height: 100,
  },
  module: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "text-overflow": "ellipsis",
    overflow: "hidden",
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    backgroundColor: "#efeefe",
    height: "100px",
  },
  noteCardActions: {
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const NoteCard = ({ item, id }) => {
  const {
    noteId,
    date,
    // isFinished,
    notes,
  } = item;

  const classes = useStyles();
  const history = useHistory();
  const { deleteOneNote } = useApiary();
  // const { getOneNote, deleteOneNote } = useApiary();

  const { currentUser } = useAuth();
  const uid = currentUser?.uid;
  // const { id } = useParams();

  const { t } = useTranslation(["common"]);

  const openDetails = () => {
    if (!currentUser) {
      toastErrorNotify("Please Login to get the details");
    }
    // if user doenst exist it is routed to the login page via PrivateRouter
    history.push(`/apiary-notes/${noteId}`);
  };

  const deleteHandler = (uid, id, noteId) => {
    deleteOneNote(uid, id, noteId);
    history.push(`/apiary-notes/${id}`);
    toastSuccessNotify("Note deleted successfully!");
    console.log("delete handler hive id:", noteId);
  };

  return (
    <Card className={classes.cardRoot}>
      <CardActionArea onClick={openDetails}>
        <CardMedia
          className={classes.media}
          image={NotesImg || placeholder}
          title={date}
          component="img"
        />

        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {t("notlar")}:
          </Typography>
          <p className={classes.module}>{notes}</p>
        </CardContent>
      </CardActionArea>

      <CardActions className={classes.noteCardActions}>
        <IconButton>
          <NotesIcon />
        </IconButton>
        <Typography gutterBottom variant="h6" component="h2">
          {date}
        </Typography>
        <IconButton>
          <DeleteIcon onClick={() => deleteHandler(uid, id, noteId)} />
        </IconButton>
        {/* <Button
          variant="contained"
          onClick={() => deleteHandler(uid, id, noteId)}
        >
          Delete
          </Button> */}
      </CardActions>
    </Card>
  );
};

export default NoteCard;
