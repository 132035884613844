import { Container, Image } from "@chakra-ui/react";
import Load from "../weatherAssets/images/loading.gif"

export const Loading = () => {

    return (
        <Container  >
            <Image src={Load} alt="error" />
        </Container>
    ); 
};