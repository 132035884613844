import React, { useEffect, useState } from "react";
import { firebaseDB } from "../utils/firebaseUtil";
import { useAuth } from "../context/AuthContextProvider";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
// import HiveIcon from "@mui/icons-material/Hive";
// import placeholder from "../assets/petek.png";
// import image from "../assets/apiary2.jpg";

import { useApiary } from "../context/ApiaryContextProvider";
// import { useAuth } from "../context/AuthContextProvider";
// import { toastErrorNotify } from "../utils/ToastNotify";
// import { toastSuccessNotify } from "../utils/ToastNotify";

// import { useHistory, useParams } from 'react-router-dom';
// import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";
import { FormControl, Grid, MenuItem, styled, TextField } from "@material-ui/core";
import { inputLabelClasses, outlinedInputClasses } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    minWidth: 250,
    maxWidth: 345,
    // backgroundColor: "#FAB718",
  },

  totalHiveRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // paddingTop: 10,
    flexWrap: "wrap",
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  race: {
    display: "flex",
    justifyContent: "space-between",
  },

  media: {
    height: 140,
  },
  module: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "text-overflow": "ellipsis",
    overflow: "hidden",
    textAlign: "left",
    fontSize: "0.81rem",
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    // backgroundColor: "#efeefe",
    // height: "125px",
  },

  harvestCardActions: {
    justifyContent: "space-between",
  },

  title: {
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const TotalHive = ({setYear, year}) => {
  const classes = useStyles();
  const { currentHives, apiaryIdFromHive, getTotalHarvest, totalHarvest } = useApiary();
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  useEffect(() => {
    getTotalHarvest(uid, apiaryId, `${year}`)
  }, [year])
  console.log("totaHarvest", totalHarvest)

  const { t } = useTranslation(["common"]);

  const StyledTextField = styled(TextField)((e) => ({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: "none",
      // backgroundImage:"linear-gradient(#efeefe 75%, rgba(0,0,0,0.7))",
      borderBottom: e.value === "" ? "1px solid  #3F4E4F" : "3px solid #3F4E4F",
      borderBottomWidth: e.value === "" ? "0.05rem" : "0.35rem",
      transition: "background-image 1s",
        
      // boxShadow: "1px 1px 2px 2px  #3F4E4F",
      // backgroundColor:  "#FAB718",
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "black" ,
      fontWeight: "900",
      backgroundColor:"#efeefe"
    },
    [`& .${inputLabelClasses.shrink}`]: {
      backgroundColor: "transparant",
      marginTop: "-1.3rem",
      padding: "0.5rem",
      fontSize: "1.1rem",
      color: "#001E6C",
      fontWeight: "700",
    },
  }));

 




  function totalColonyState() {
    let total = 0;
    let totalWeak = 0;
    let totalNormal = 0;
    let totalStrong = 0;

    currentHives.map((item) =>
      item.colonyState == ("Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : item.colonyState == ("Zayıf" || "Слаба" || "Weak")
        ? (totalWeak += 1)
        : item.colonyState == ("Normal" || "Середня")
        ? (totalNormal += 1)
        : (totalStrong += 1)
    );
    return [total, totalWeak, totalNormal, totalStrong];
  }

  function totalRace() {
    let total = 0;
    let totalCarniolian = 0;
    let totalCaucasian = 0;
    let totalItalian = 0;
    let totalDark = 0;
    let totalAnatolian = 0;
    let totalSyrian = 0;
    let totalIraninan = 0;
    let totalAfricanized = 0;
    let totalRussian = 0;
    let totalOther = 0;

    currentHives.map((item) =>
      item.race == ("Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : item.race == ("Karniyol" || "Carniolan Bees" || "кранський")
        ? (totalCarniolian += 1)
        : item.race == ("Kafkas" || "Caucasian Bees" || "кавказька")
        ? (totalCaucasian += 1)
        : item.race == ("İtalyan" || "Italian Bees" || "італійська")
        ? (totalItalian += 1)
        : item.race == ("Esmer" || "Dark Bees" || "Темні бджоли")
        ? (totalDark += 1)
        : item.race == ("Anadolu" || "Anatolian Bees" || "Анадолу")
        ? (totalAnatolian += 1)
        : item.race == ("Suriye" || "Syrian Bees" || "сирійський")
        ? (totalSyrian += 1)
        : item.race == ("İran" || "Iranian Bees" || "Iіранська")
        ? (totalIraninan += 1)
        : item.race == ("Afrika" || "Africanized Bees" || "Африканізовані")
        ? (totalAfricanized += 1)
        : item.race == ("Rusya" || "Russian Bees" || "російський")
        ? (totalRussian += 1)
        : (totalOther += 1)
    );
    return [
      total,
      totalCarniolian,
      totalCaucasian,
      totalItalian,
      totalDark,
      totalAnatolian,
      totalSyrian,
      totalIraninan,
      totalAfricanized,
      totalRussian,
      totalOther,
    ];
  }

  function totalHealthStatus() {
    let total = 0;
    let totalHealty = 0;
    let totalDiseased = 0;

    currentHives.map((item) =>
      item.healthStatus == ("Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : item.healthStatus == ("Sağlıklı" || "Healthy" || "Здоровий")
        ? (totalHealty += 1)
        : (totalDiseased += 1)
    );
    return totalDiseased;
  }

  function totalQueenExist() {
    let total = 0;
    let totalQueen = 0;
    let totalAbsence = 0;

    console.log('total:', total,'totalQueen:', totalQueen, 'totalAbsence:', totalAbsence )

    currentHives.map((item) =>{
      console.log('item.queenExist :', Object(item.queenExist))
      item.queenExist == (null || '' || "Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : item.queenExist == ("Var" || "Exist" || "Існувати")
        ? (totalQueen += 1)
        : item.queenExist == ("Yok" || "None" || "Жодного")
        ?  (totalAbsence += 1)
        :  (total += 1)
      });
      console.log('total:', total,'totalQueen:', totalQueen, 'totalAbsence:', totalAbsence )
    return totalAbsence;
    
  }

  function totalQueenCellStatus() {
    let total = 0;
    let totalQueenCell = 0;
    let totalAbsence = 0;

    currentHives.map((item) =>
      item.queenCellStatus == ("Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : item.queenCellStatus == ("Var" || "Exist" || "Існувати")
        ? (totalQueenCell += 1)
        : (totalAbsence += 1)
    );
    return totalQueenCell;
  }

  function totalMaleBeeStatus() {
    let total = 0;
    let totalNormal = 0;
    let totalSurplus = 0;

    currentHives.map((item) =>
      item.maleBeeStatus == ("Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : item.maleBeeStatus == ("Normal" || "Середня")
        ? (totalNormal += 1)
        : item.maleBeeStatus == ("Fazla" || "Surplus" || "Надлишок")
        ? (totalSurplus += 1)
        : (total += 1)
    );
    return totalSurplus;
  }

  function totalQueenGridNeed() {
    let total = 0;
    currentHives.map((item) =>  { 
    console.log('queenGridNeed:', Object(item.queenGridNeed))
    total +=  (item.queenGridNeed == 0) ? 0 : parseFloat(item.queenGridNeed)
  });
    return total;
  }

  function totalSuperNeed() {
    let total = 0;
    currentHives.map((item) => {
      console.log('item.superNeed:', item.superNeed)
      total += (item.superNeed == 0) ? 0 : parseFloat(item.superNeed);
    
    });
    return total;
  }

  function totalFramesNeed() {
    let total = 0;
    let totalFrames = 0;
    currentHives.map((item) =>
      item.emptyFrameNeed == ("Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : (totalFrames += (item.emptyFrameNeed == 0) ? 0 : parseFloat(item.emptyFrameNeed))
    );
    return totalFrames;
  }

  function totalFoodNeed() {
    let total = 0;
    let totalFoodNeed = 0;
    let totalAbsence = 0;

    currentHives.map((item) =>
      item.foodNeed == ("Seçiniz" || "Choose" || "Виберіть")
        ? (total += 1)
        : item.foodNeed == ("Var" || "Exist" || "Існувати")
        ? (totalFoodNeed += 1)
        : (totalAbsence += 1)
    );
    return totalFoodNeed;
  }

  function totalCakeNeed() {
    let total = 0;
    let totalOther = 0;
    currentHives.map((item) =>
      isNaN(item.cakeNeed) ? (totalOther += 1) : (total += 1)
    );
    return total;
  }

  function totalCakeNeed() {
    let total = 0;
    let totalOther = 0;
    currentHives.map((item) =>
      isNaN(item.cakeNeed) || item.cakeNeed == 0
        ? (totalOther += 1)
        : (total += parseFloat(item.cakeNeed))
    );
    return total;
  }

  function totalSyrupNeed() {
    let total = 0;
    let totalOther = 0;
    currentHives.map((item) =>
      isNaN(item.syrupNeed) || item.syrupNeed == 0
        ? (totalOther += 1)
        : (total += parseFloat(item.syrupNeed))
    );
    return total;
  }

  function totalFondanNeed() {
    let total = 0;
    let totalOther = 0;
    currentHives.map((item) =>
      isNaN(item.fondanNeed) || item.fondanNeed == 0
        ? (totalOther += 1)
        : (total += parseFloat(item.fondanNeed))
    );
    return total;
  }

  function totalHoneySyrupNeed() {
    let total = 0;
    let totalOther = 0;
    currentHives.map((item) =>
      isNaN(item.honeySyrupNeed) || item.honeySyrupNeed == 0
        ? (totalOther += 1)
        : (total += parseFloat(item.honeySyrupNeed))
    );
    return total;
  }

  function totalHoneyCakeNeed() {
    let total = 0;
    let totalOther = 0;
    currentHives.map((item) =>
      isNaN(item.honeyCakeNeed) || item.honeyCakeNeed == 0
        ? (totalOther += 1)
        : (total += parseFloat(item.honeyCakeNeed))
    );
    return total;
  }

  // {t("toplam")} Hasat Verisi
  const apiaryId = apiaryIdFromHive;
  const ref = firebaseDB.collection("users");

  
  // console.log("Hives Harvest Data:", harvestData);

  // const totalHarvestData = () => {
  //   const HiveId = currentHives.map((item) => item.hiveId);
  //   HiveId.map((id) =>
  //   ref
  //     .doc(uid)
  //     .collection("apiary")
  //     .doc(apiaryId)
  //     .collection("hive")
  //     .doc(id)
  //     .collection("harvest")
  //     .onSnapshot((querySnapshot) => {
  //       const items = [];
  //       querySnapshot.forEach((collection) => {
  //         items.push(collection.data());
  //       });
  //       setHarvestData(items)
  //     })
  //     );

  // }
  
  // console.log(apiaryHarvest)

  // function totalHoneyAmount() {
  //   let total = 0;
  //   apiaryHarvest?.map((item) => (total += (item === undefined ? 0 : parseFloat(item.singleHoneyAmount) ))
  //   );
  //   return total;
  // }

  // function totalPropolisAmount() {
  //   let total = 0;
  //   apiaryHarvest?.map((item) => (total += (item === undefined) ? 0 : parseFloat(item.singlePropolisAmount)));
  //   return total;
  // }

  // function totalPolenAmount() {
  //   let total = 0;
  //   apiaryHarvest?.map((item) => total += (item === undefined) ? 0 : parseFloat(item.singlePolenAmount));
  //   return total;
  // }

  // function totalBeeWaxAmount() {
  //   let total = 0;
  //   apiaryHarvest?.map((item) => (total += (item === undefined ? 0 : parseFloat(item.singleBeeWaxAmount))));
  //   return total;
  // }

  // function totalBeeMilkAmount() {
  //   let total = 0;
  //   apiaryHarvest?.map((item) => (total += (item === undefined ? 0 : parseFloat(item.singleBeeMilkAmount))));
  //   return total;
  // }

  // function totalBeeVenomAmount() {
  //   let total = 0;
  //   apiaryHarvest?.map((item) => (total += (item === undefined ? 0 : parseFloat(item.singleBeeVenomAmount))));
  //   return total;
  // }

  // console.log("{t("toplam")}dan Kovanlar Listesi:", currentHives);


  
  

  return (
    <div className={classes.totalHiveRoot} justifyContent="center">
      <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card className={classes.cardRoot}>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.title}
            >
              {t("genelkolonidurumu")}
            </Typography>
            <hr></hr>
            <p className={classes.module} style={{ textAlign: "center" }}>
              {t("arıırkı")}
            </p>
            <div className={classes.race} xs={12}>
              <div xs={3}>
                <p className={classes.module}>
                  {t("toplam")} {t("Karniyol")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("Kafkas")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("İtalyan")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("Esmer")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("Anadolu")} :
                </p>
              </div>
              <div xs={3}>
                <p className={classes.module}>{totalRace()[1]}</p>
                <p className={classes.module}>{totalRace()[2]}</p>
                <p className={classes.module}>{totalRace()[3]}</p>
                <p className={classes.module}>{totalRace()[4]}</p>
                <p className={classes.module}>{totalRace()[5]}</p>
              </div>

              <div xs={3}>
                <p className={classes.module}>
                  {t("toplam")} {t("Suriye")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("İran")} :{" "}
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("Afrika")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("Rusya")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("diger")} :
                </p>
              </div>
              <div xs={3}>
                <p className={classes.module}>{totalRace()[6]}</p>
                <p className={classes.module}>{totalRace()[7]}</p>
                <p className={classes.module}>{totalRace()[8]}</p>
                <p className={classes.module}>{totalRace()[9]}</p>
                <p className={classes.module}>{totalRace()[10]}</p>
              </div>
            </div>
            <hr></hr>
            <p className={classes.module} style={{ textAlign: "center" }}>
              {t("toplam")} {t("Populasyon")}
            </p>

            <div className={classes.race} xs={12}>
              <div xs={6}>
                <p className={classes.module}>
                  {t("toplam")} {t("Zayıf")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("Normal")} {t("Populasyon")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("Güçlü")} {t("Populasyon")} :
                </p>
                <p className={classes.module}>
                  {t("toplam")} {t("hastalıklıkovansayısı")}:
                </p>
              </div>
              <div xs={6}>
                <p className={classes.module}>{totalColonyState()[1]}</p>
                <p className={classes.module}>{totalColonyState()[2]}</p>
                <p className={classes.module}>{totalColonyState()[3]}</p>
                <p className={classes.module}>{totalHealthStatus()}</p>
              </div>
              <hr></hr>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Card className={classes.cardRoot}>
          <CardActionArea>
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.title}
              >
                {t("anaarıdurumu")}
              </Typography>
              <hr></hr>
              <div className={classes.race} xs={12}>
                <div xs={6}>
                  <p className={classes.module}>
                    {t("toplam")} {t("anaarıihtiyacı")}:
                  </p>
                  <hr></hr>

                  <p className={classes.module}>
                    {t("toplam")} {t("arımemelikovansayısı")}:
                  </p>
                  <hr></hr>

                  <p className={classes.module}>
                    {t("erkekarınufusufazlakovansayısı")}:
                  </p>
                </div>
                <div xs={6}>
                  <p className={classes.module}>{totalQueenExist()}</p>
                  <hr></hr>

                  <p className={classes.module}>{totalQueenCellStatus()}</p>
                  <hr></hr>

                  <p className={classes.module}>{totalMaleBeeStatus()}</p>
                </div>
                <hr></hr>
              </div>
              <br />
            </CardContent>
          </CardActionArea>
        </Card>

        <Card className={classes.cardRoot}>
          <CardActionArea>
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.title}
              >
                {t("ekipmandurumu")}
              </Typography>
              <hr></hr>
              <div className={classes.race} xs={12}>
                <div xs={6}>
                  <p className={classes.module}>
                    {t("toplam")} {t("anaarıızgarasıihtiyacı")}
                  </p>
                  <p className={classes.module}>
                    {t("toplam")} {t("katihtiyacı")}
                  </p>
                  <p className={classes.module}>
                    {t("toplam")} {t("cerceveihtiyacı")}
                  </p>
                </div>
                <div xs={6}>
                  <p className={classes.module}>{totalQueenGridNeed()}</p>
                  <p className={classes.module}>{totalSuperNeed()}</p>
                  <p className={classes.module}>{totalFramesNeed()}</p>
                </div>
                <hr></hr>
              </div>
              <br />
              <br />
            </CardContent>
          </CardActionArea>
        </Card>
      
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card className={classes.cardRoot}>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.title}
            >
              {t("beslenmedurumu")}
            </Typography>
            <hr></hr>
            <div className={classes.race} xs={12}>
                <div xs={6}>
                  <p className={classes.module}>
                     {t("toplam")} {t("besinverilecekkovansayısı")}:
                  </p>
                              <hr></hr>

                  <p className={classes.module}>
                    {t("toplam")} {t("kekihtiyacı")}:
                  </p>
                  <p className={classes.module}>
                   {t("toplam")} {t("surupihtiyacı")}:
                  </p>
                  <p className={classes.module}>
                    {t("toplam")} {t("fondanihtiyacı")}: 
                  </p>
                  <p className={classes.module}>
                     {t("toplam")} {t("ballısurupihtiyacı")}:
                  </p>
                  <p className={classes.module}>
                  {t("toplam")} {t("ballıkekihtiyacı")}:  
                  </p>
                </div>
                <div xs={6}>
                  <p className={classes.module}>{totalFoodNeed()}</p>
                  <hr />
                  <p className={classes.module}>{totalCakeNeed()}</p>
                  <p className={classes.module}>{totalSyrupNeed()}</p>
                  <p className={classes.module}>{totalFondanNeed()}</p>
                  <p className={classes.module}>{totalHoneySyrupNeed()}</p>
                  <p className={classes.module}>{totalHoneyCakeNeed()}</p>
                </div>
                <hr></hr>
                
              </div>
           
          </CardContent>
        </CardActionArea>
      </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card className={classes.cardRoot}>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.title}
            >
              {t("hasatdurumu")}
            </Typography>
            <hr></hr>
            <br />
            
            <div className={classes.race} xs={12}>
            
                <div xs={6}>
                  <p className={classes.module}>
                     {t("toplam")} {t("balmiktarı")} :
                  </p>

                  <p className={classes.module}>
                     {t("toplam")} {t("propolismiktarı")} :
                  </p>
                  <p className={classes.module}>
                    {t("toplam")} {t("polenmiktarı")} :
                  </p>
                  <p className={classes.module}>
                    {t("toplam")} {t("arımumumiktarı")} : 
                  </p>
                  <p className={classes.module}>
                     {t("toplam")} {t("arısutumiktarı")} :
                  </p>
                  <p className={classes.module}>
                  {t("toplam")} {t("arızehrimiktarı")} :  
                  </p>
                </div>
                <div xs={6}>
                  <p className={classes.module}>{parseFloat(totalHarvest[0]?.totalHoneyAmount)}</p>
                  <p className={classes.module}>{parseFloat(totalHarvest[0]?.totalPropolisAmount)}</p>
                  <p className={classes.module}>{parseFloat(totalHarvest[0]?.totalPolenAmount)}</p>
                  <p className={classes.module}>{parseFloat(totalHarvest[0]?.totalBeeWaxAmount)}</p>
                  <p className={classes.module}>{parseFloat(totalHarvest[0]?.totalBeeMilkAmount)}</p>
                  <p className={classes.module}>{parseFloat(totalHarvest[0]?.totalBeeVenomAmount)}</p>
                </div>
                <hr></hr>
                
              </div>
          </CardContent>
          <div className={classes.race}>
        
        <FormControl
          variant="outlined"
          fullWidth
          // className={classes.formControl}
        >

          <TextField 
            variant="standard"
            select
            fullWidth  
            id="queen-grid-need"
            required
            value={year}
            onChange={(e) => setYear(e.target.value)}
            label={t("Seçiniz")}

          >
            <MenuItem value={"2015"}>2015</MenuItem>
            <MenuItem value={"2016"}>2016</MenuItem>
            <MenuItem value={"2017"}>2017</MenuItem>
            <MenuItem value={"2018"}>2018</MenuItem>
            <MenuItem value={"2019"}>2019</MenuItem>
            <MenuItem value={"2020"}>2020</MenuItem>
            <MenuItem value={"2021"}>2021</MenuItem>
            <MenuItem value={"2022"}>2022</MenuItem>
            <MenuItem value={"2023"}>2023</MenuItem>
            <MenuItem value={"2024"}>2024</MenuItem>
            <MenuItem value={"2025"}>2025</MenuItem>          
          </TextField>
        </FormControl>
</div>
        </CardActionArea>
      </Card>
      </Grid>
    </div>
  );
};
export default TotalHive;
