import React, { useEffect,  } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";

// import { useApiary } from "../context/ApiaryContextProvider";
// import loadingGif from "../assets/loading.gif";
import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";
import {
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import { FaArchive, FaMapMarkedAlt } from "react-icons/fa";

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import { useHistory } from "react-router-dom";

import PieChartCards from "../components/PieChartCards";
import BarChartTotalHarvest from "../components/BarChartTotalHarvest";
import AllNotesTable from "../components/AllNotesTable";




const useStyles = makeStyles((theme) => ({
 

  title: {
    fontFamily: "Girassol",
    textAlign: "center",
    // justifyContent: "center",
    color: "#046582",
  },




  homeRoot:{
    // width:"100vw"

  },
  container: {
    minHeight: "100vh",
    // width:"100%"
    // bac9roundImage : "linear-gradient(#F5F0BB 75%, #FF9F29)",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",

    // marginTop: "3rem",
  },

  card: {
    width: "15.5rem",
    height: "10.5rem",
    margin: "1.5rem",
    // padding:"0.5rem",
    borderBottom: "2px solid orange",
    backgroundImage:
      "linear-gradient(rgba(245,240,187,0.3) 75%, rgba(255,159,41,0.5))",
  },
  cardHeaders: {
    display: "flex",
    marginTop: "-0.5rem",
    flexWrap:"wrap",
    justifyContent:"center",
    alignItems:"center",
    "& > *": {
      backgroundImage:
        "linear-gradient(rgba(256,256,256,0.3) 75%, rgba(256,256,256,0.5))",
    },
  },
  cardMain: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& > *": {
      width: "22.5rem",
      height: "30.5rem",
    },
  },
  cardHiveStatus: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100vw",
    height: "20.5rem",
  },

  title: {
    fontSize: "19px",
  },
  statistic: {
    fontSize: 40,
    fontWeigth: 500,
    "& > span": {
      fontSize: 20,
      paddingLeft: 10,
    },
  },
  chart: {
    marginLeft: "auto",
    marginRight: "auto",
    height: "auto",
    width: "auto",
  },
}));

const Homepage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common"]);

 



  const {
    currentApiaries,
    getAllHives,
    allHives,
    getApiaries
  } = useApiary();

  useEffect(() => {
    getApiaries(uid); 
    getAllHives(uid);
   }, []);

  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  const handleApiary = (uid) => {
    history.push(`/userNavigator/${uid}`);
  };
  const allApiaryIds = currentApiaries?.map((apiary) => apiary.apiaryId);
      const filteredAllHives = allHives?.filter((hive) =>
      allApiaryIds.includes(hive.apiaryId)
    );
  console.log(allHives, filteredAllHives)


 

  return (
    <div className={classes.homeRoot}>
    <div className={classes.container}>
    


      <h1 style={{ textAlign: "center", marginTop: "1rem" }}>
        {t("uygulamayahoşgeldiniz")}
      </h1>

      <div className={classes.cardContainer}>
        <div className={classes.cardHeaders}>
          <Card className={classes.card} onClick={() =>handleApiary(uid)}>
            <CardActionArea>
              <FaMapMarkedAlt
                style={{ fontSize: "2.5rem", color: "#FAB718" }}
              />
              <CardContent>
                <Typography
                  className={classes.title}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {t("toplamarılıksayınız")}
                </Typography>
                <Typography className={classes.statistic}>
                  {currentApiaries.length}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card className={classes.card} onClick={() =>handleApiary(uid)}>
            <CardActionArea>
              <FaArchive style={{ fontSize: "2.5rem", color: "#FAB718" }} />
              <CardContent>
                <Typography
                  className={classes.title}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  {t("toplamkovansayınız")}
                </Typography>
                <Typography className={classes.statistic}>
                  {filteredAllHives.length}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </div>
        <PieChartCards  filteredAllHives={filteredAllHives} />
        <BarChartTotalHarvest  />
        <div style={{width:"85vw"}}>
        <AllNotesTable  />

        </div>

      </div>


    </div>
    </div>
   
  );
};

export default Homepage;
