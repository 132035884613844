import React, { useState, useEffect } from "react";

import MapContainer from "./ApiaryMap";

import Button from "@material-ui/core/Button";
import TextField from '@mui/material/TextField';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { alpha, styled } from "@mui/material/styles";

import { outlinedInputClasses } from "@mui/material/OutlinedInput";

import { inputLabelClasses, selectLabel } from "@mui/material";


import { useTranslation } from "react-i18next";



const useStyles = makeStyles((theme) => ({
  avatar: {
    padding: theme.spacing(3),
    backgroundColor: "#046582",
  },
  userForm: {
    // backgroundColor: "white",
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),

  },
  userGrid: {
   marginTop: "-1rem",


  },
  userMapGrid: {
    marginBottom: theme.spacing(0),
    // marginTop: "-5rem",
  },
  userMap: {
    maxWidth: "150px",
  },

  userText: {
    marginTop: theme.spacing(3),
    backgroundColor:"#ffd54f",


  },

  userContainer: {
    justifyContent: "space-around",
    display: "flex",
    flexWrap: "wrap",
  },
  buttonGrid: {
    display: "flex",
    justifyContent: "space-around",
    // backgroundColor: "white",
    // marginBottom: '5px',
  },
  submit: {
    margin: theme.spacing(3, 0),
    backgroundColor:"#ffd54f",
    color: "#2E0249",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },

  choices: {
    // margin: theme.spacing(3, 0),
    marginTop: theme.spacing(5),
    backgroundColor:"#ffd54f",
    color: "#2E0249",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },

  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));
const StyledTextField = styled(TextField)((e) => ({
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border:  "none",
    borderBottom:  e.value === "" ? "" :  "3px solid #001E6C",
    borderBottomWidth : e.value === "" ? "0" : "0.35rem",
    transition: "border-bottom 0.4s"

    // boxShadow: "1px 1px 2px 2px rgba(0,0,0,0.75);",
    // backgroundColor : "gray",
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: e.value === "" ? "gray" : "#2E0249",
    fontWeight: "900"
  },
  [`& .${inputLabelClasses.shrink}`]: {
    backgroundColor: "transparant",
    marginTop:"-1.1rem",
    padding:"0.5rem",
    fontSize:"1.3rem",
    color:"#001E6C",
    fontWeight:"900",
  },
}));

const ApiaryForm = (props) => {
  const {updateId, apiary, handler } = props;
  const classes = useStyles();
  const [newApiary, setNewApiary] = useState(apiary);
  const [openCurrentLocation, setOpenCurrentLocation] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(updateId ? {
    lat:  newApiary.latitude ,
    lng: newApiary.longitude
  } : {
    lat:  39.82206 ,
    lng:  34.808132 
  });
  const { t } = useTranslation(["common"]);

  console.log('new Apiary:', newApiary)


  useEffect(() => {
    openCurrentLocation &&
      navigator.geolocation.getCurrentPosition(getCoordsByLocation);
  }, [openCurrentLocation]);

  useEffect(() => {
    setNewApiary(apiary);
  }, [apiary]);
  console.log(currentPosition);

  const ChooseFromMap = () => {
    setOpenCurrentLocation(false);
    setCurrentPosition({ lat: 39.82206, lng: 34.808132 });
  };

  const getCoordsByLocation = (position) => {
    setOpenCurrentLocation(true);
    position !== undefined &&
      setCurrentPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
  };
  const sendNewApiary = (e) =>{
      e.preventDefault();
      handler(newApiary);
  }
  useEffect(() => {
    setCurrentPosition({
      lat: (newApiary === undefined) ? 39.82206 : newApiary?.latitude,
      lng: (newApiary === undefined) ? 34.808132 : newApiary?.longitude
    });
  }, [ newApiary?.latitude])

  useEffect(() => {
    setNewApiary({ ...newApiary, 
      latitude:currentPosition.lat,
      longitude:currentPosition.lng  })
  }, [currentPosition])

  return (
    <form className={classes.userForm} onSubmit={sendNewApiary}>
      <Grid container className={classes.userContainer} spacing={2} item xs={12}>
        <Grid className={classes.userGrid} item xs={12} md={4} lg={4}>
          <StyledTextField
            className={classes.userText}
            variant="outlined"
            fullWidth
            required
            id="apiaryTitle"
            label={t("arılıkadı")}
            name="apiaryTitle"
            value={newApiary === undefined ? "" : newApiary?.apiaryTitle}
            onChange={(e) =>
              setNewApiary({ ...newApiary, apiaryTitle: e.target.value })
            }
          />
        
          <StyledTextField
            className={classes.userText}
            variant="outlined"
            fullWidth
            required
            id="apiaryLocation"
            label={t("arılıkyeri")}
            name="apiaryLocation"
            value={newApiary === undefined ? "" : newApiary?.apiaryLocation}
            onChange={(e) =>
              setNewApiary({ ...newApiary, apiaryLocation: e.target.value })
            }
          />
        
          <StyledTextField
            className={classes.userText}
            id="outlined-multiline-static"
            label={t("notlar")}
            name="description"
            multiline
            value={newApiary === undefined ? "" : newApiary?.apiaryNotes}
            onChange={(e) =>
              setNewApiary({ ...newApiary, apiaryNotes: e.target.value })
            }
            fullWidth
            rows={3}
            variant="outlined"
          />
        </Grid>

        {/* Google Map Component */}
        <Grid className={classes.userMapGrid} xs={12} md={8} lg={8}>
        <MapContainer
          openCurrentLocation={openCurrentLocation}
          currentPosition={currentPosition}
          setCurrentPosition={setCurrentPosition}
          className={classes.userMap}
        />

        <Grid className={classes.buttonGrid} item xs={12} md={12} lg={12}>
          <Button
            variant="contained"
            className={classes.choices}
            onClick={() => getCoordsByLocation()}
          >
            {t("mevcutkonum")}
          </Button>

          <Button
            variant="contained"
            className={classes.choices}
            onClick={() => ChooseFromMap()}
          >
             {t("haritadansec")}
          </Button>
        </Grid>
        </Grid>
      </Grid>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
      >
         {t("onay")}
      </Button>
    </form>
  );
};

export default ApiaryForm;
