import "./App.css";
import AuthContextProvider from "./context/AuthContextProvider";
import AppRouter from "./router/AppRouter";
import { ToastContainer } from "react-toastify";
import { ApiaryContextProvider } from "./context/ApiaryContextProvider";
import 'dotenv/config'
import LandingPageRouter from "./router/LandingPageRouter";
import { useState } from "react";



function App() {
  const [onLandingPage, setOnLandingPage] = useState(true);


  return (
    <div className="App">

      <AuthContextProvider>
      {onLandingPage ?
          <LandingPageRouter setOnLandingPage={setOnLandingPage} /> 
          :
        <ApiaryContextProvider>
          <AppRouter/>
          <ToastContainer />
        </ApiaryContextProvider>}
      </AuthContextProvider> 
    </div>
  );
}

export default App;
