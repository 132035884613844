import React, { useEffect, useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Paper from '@material-ui/core/Paper';
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import { FormControl, MenuItem, styled, TextField } from "@material-ui/core";
import { grey, amber, green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import { inputLabelClasses, outlinedInputClasses } from "@mui/material";

import NoteCard from "../components/NoteCard";
// import Weather from "../weather/Weather.js";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";

import loadingGif from "../assets/loading.gif";
import backImage from "../assets/apiary3.png";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DialogActions, DialogContentText } from "@material-ui/core";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  noteRoot: {
    display: "flex",
    minHeight: "90vh",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",
    // paddingTop: 10,
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(5),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },

  title: {
    // flexGrow: 1,
    fontFamily: "Girassol",
    textAlign: "center",
    color: "#591900",
  },
  noteHeader: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    alignItems: "center",

    // paddingTop: 30,
    // flexGrow: 1,
    // "& > *": {
    //   margin: theme.spacing(3),
    // },
  },
  noteSelect: {
    display: "flex",
    flexDirection: "row",
    minWidth: "50vw",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  cardContent: {
    display: "flex",
    backgroundColor: "#E0E0E0",
    textAlign: "center",
  },
  notePaper: {
    justifyContent: "center",
    textAlign: "center",
    height: "8vh",
    minWidth: "14vw",
  },
  noteButton: {
    height: "8vh",
    minWidth: "14vw",
  },
  noteMainRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(3),
    },
    // marginTop: 30,
    // backgroundImage: `url(${backImage})`,
    // backgroundRepeat: "repeat",
    // backgroundSize: "cover",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: amber[100],
    },
  },
}))(Button);

const ApiaryNotesFromSidebar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentApiaries, getApiaries, currentNotes, getNotes } = useApiary();
  const [selectedApiaryId, setApiary] = useState("");
  const [openModal, setOpenModal] = useState(false);

  console.log("Current Notes:", currentNotes);

  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  // const { id } = useParams();
  // console.log("Note sayafası id'si : ", id);

  // const ApiaryId = currentApiaries?.filter((item) => item.apiaryId!==selectedApiaryId);

  const NewNoteHandler = (selectedApiaryId) => {
    {selectedApiaryId? (
    history.push(`/new-note/${selectedApiaryId}`)
    // console.log("Note Handler id'si : ", selectedApiaryId)
    ):(
      setOpenModal(true)
    )}
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getApiaries(uid);
    selectedApiaryId !== "" && getNotes(uid, selectedApiaryId);
  }, [selectedApiaryId]);

  console.log("selectedApiaryId :", selectedApiaryId);

  const { t } = useTranslation(["common"]);

  const StyledTextField = styled(TextField)((e) => ({
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: "none",
      // backgroundImage:"linear-gradient(#efeefe 75%, rgba(0,0,0,0.7))",
      borderBottom: e.value === "" ? "1px solid  #3F4E4F" : "3px solid #3F4E4F",
      borderBottomWidth: e.value === "" ? "0.05rem" : "0.35rem",
      transition: "background-image 1s",

      // boxShadow: "1px 1px 2px 2px  #3F4E4F",
      // backgroundColor:  "#FAB718",
    },
    [`& .${outlinedInputClasses.input}`]: {
      color: "black",
      fontWeight: "900",
      backgroundColor: "#efeefe",
    },
    [`& .${inputLabelClasses.shrink}`]: {
      backgroundColor: "transparant",
      marginTop: "-1.3rem",
      padding: "0.5rem",
      fontSize: "1.1rem",
      color: "#001E6C",
      fontWeight: "700",
    },
  }));

  return (
    <div className={classes.noteMainRoot}>
        <Dialog
            // fullScreen={fullScreen}
            open={openModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
           {t("Not Eklemek İçin")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>  
                 <h3>{t("Lütfen Arılık Seçiniz")}</h3>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined" color="primary" autoFocus>
                {t("kapat")}
                </Button>
            </DialogActions>
          </Dialog>
      <h1>{t("notlar")}</h1>
      <Grid className={classes.noteHeader} item xs={12}>
        <Grid className={classes.noteSelect} item xs={12}>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <StyledTextField
              variant="outlined"
              select
              fullWidth
              id="queen-grid-need"
              value={selectedApiaryId}
              onChange={(e) => setApiary(e.target.value)}
              label={t("arilik")}
            >
              <MenuItem value={""}>{t("arilik")} {t("Seçiniz")}</MenuItem>
              {currentApiaries?.map((option) => (
                <MenuItem key={option.apiaryId} value={option.apiaryId}>
                  {option.apiaryTitle}
                </MenuItem>
              ))}
            </StyledTextField>
          </FormControl>

          {/* <Paper className={classes.notePaper} elevation={3}>
            {t("toplam")} {t("notlar")}: {currentNotes.length}
          </Paper> */}

          {/* <Card className={classes.headerCard}>
            <CardContent className={classes.cardContent}>
              {t("toplam")} {t("notlar")}: {currentNotes.length}
            </CardContent>
          </Card> */}

          {/* <Button
            className={classes.noteButton}
            startIcon={<LibraryAddIcon />}
            variant="contained"
            color="white"
            onClick={() => NewNoteHandler(selectedApiaryId)}
          >
            Not Ekle
          </Button> */}

          <ColorButton
            variant="contained"
            color="primary"
            className={classes.noteButton}
          >
            {t("toplam")} {t("notlar")}: {currentNotes.length}
          </ColorButton>

          <ColorButton
            variant="contained"
            color="primary"
            startIcon={<LibraryAddIcon />}
            onClick={() => NewNoteHandler(selectedApiaryId)}
            className={classes.noteButton}
          >
            {t("yeninot")}
          </ColorButton>

        </Grid>
        <Grid container className={classes.noteRoot}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={5}>
              {currentNotes === undefined ? (
                <img src={loadingGif} alt="loading" />
              ) : currentNotes ? (
                currentNotes.map((item, noteId) => (
                  <Grid key={noteId} item>
                    <NoteCard item={item} id={selectedApiaryId} />
                  </Grid>
                ))
              ) : (
                <h3>No data available.</h3>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApiaryNotesFromSidebar;
