import React, { useState, useEffect, useMemo } from "react";

import { firebaseDB } from "../utils/firebaseUtil";
import { useAuth } from "../context/AuthContextProvider";
import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import { useHistory, useParams} from "react-router-dom";
import { useApiary } from "../context/ApiaryContextProvider";

import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import placeholderPng from "../assets/hive.png";
import ApiaryForm from "../components/ApiaryForm";

const useStyles = makeStyles((theme) => ({
  updateRoot: {
    marginTop: 90,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  apiaryImg: {
    width: 400,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },
  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const UpdateApiary = ({ match }) => {

  const classes = useStyles();
  const history = useHistory();

  let { currentUser } = useAuth();

  const { currentApiaries } = useApiary();

  const [oneApiary, setOneApiary] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const ref = firebaseDB.collection("users");
  const uid = currentUser?.uid;
  const { id } = useParams();
  console.log('update UID:', uid, 'update ID:', id);

  function updateApiary(uid, id, updatedApiary) {
    // setLoading(true);
    ref
      .doc(uid)
      .collection('apiary')
      .doc(id)
      .update(updatedApiary)
      .then(() => {
        setOneApiary((prev) =>
          prev.map((element) => {
            if (element.apiaryId !== updatedApiary.apiaryId) {
              return element;
            }
            return updatedApiary;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }
 
  // const result = getOneApiary(uid, id);
  const result = currentApiaries.filter((item)=> item.apiaryId==id)

  const res = useMemo(() => {
    return result ? result[0] : {
      apiaryId: "",
      apiaryTitle: "",
      apiaryLocation: "",
      apiaryNotes: "",
      numberOfHives: "",
      latitude: 39.82206 ,
      longitude: 34.808132 ,
    };
  }, [result]);

  const [updatedApiary, setUpdatedApiary] = useState(res);

  useEffect(() => {
    setUpdatedApiary(res);
  }, [res]);

  const handler = (apiaryToUpdate) => {
    try {
      updateApiary(uid, id, apiaryToUpdate);
      history.push("/userNavigator/:id"); // ekleme
      toastSuccessNotify("Apiary Updated");
    } catch (error) {
      toastErrorNotify("Apiary can not be Updated");
    }
  };

  return (
    <div>
    <Container>
      <CssBaseline />
      <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <img
          src={updatedApiary?.image || placeholderPng}
          alt="Update Apiary"
          className={classes.apiaryImg}
        />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.title}>
          ── Arılık Düzenle ──
        </Typography>
        <ApiaryForm apiary={updatedApiary} handler={handler} />
      </div>
    </Container>
    </div>
  );
};

export default UpdateApiary;
