import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import Typography from "@material-ui/core/Typography";
import placeholder from "../assets/petek.png";
import HiveIcon from "@mui/icons-material/Hive";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import image from "../assets/location.jpg";

import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";
import { useHistory } from "react-router-dom";
import { toastErrorNotify } from "../utils/ToastNotify";
import { toastSuccessNotify } from "../utils/ToastNotify";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";

// import moment from "moment";
// import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
// import IconButton from "@material-ui/core/IconButton";
// import FavoriteIcon from "@material-ui/icons/Favorite";
// "linear-gradient(rgba(141,138,233,0.6) 65%, rgba(240,235,227,0.7))",

const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: 375,
    maxWidth: 375,
    minHeight: 375,
    maxHeight: 375,
    backgroundColor: "#f4511e",
    opacity: 1,
    borderRadius: "60% 60% 0 58%",
    transform: "matrix(0.55, 0.55, -0.55, 0.55, 0, 0)",
    cursor: "pointer",
    position: "relative",

    // borderRadius:"5rem 5rem 0 0 "
  },
  cardRootHover: {
    minWidth: 375,
    maxWidth: 375,
    minHeight: 375,
    maxHeight: 375,
    backgroundColor: "#f4511e",
    opacity: 1,
    borderRadius: "60% 60% 0 58%",
    transform: "matrix(0.55, 0.55, -0.55, 0.55, 0, 0) translate(-5%, -5%)",
    cursor: "pointer",
    position: "relative",
    transition: "transform 1.5s",

    // borderRadius:"5rem 5rem 0 0 "
  },
  media: {
    height: 100,
    transform: "rotate(-45deg)",
    visibility: "hidden",
  },
  // module: {
  //   display: "-webkit-box",
  //   "-webkit-line-clamp": 2,
  //   "-webkit-box-orient": "vertical",
  //   "text-overflow": "ellipsis",
  //   overflow: "hidden",
  // },
  image: {
    // padding: 3,
    scale: 1.1,
    transform: "rotate(-45deg)",
  },
  avatar: {
    marginBottom: "0.35em",
    transform: "rotate(-45deg)",
  },
  cardContent: {
    position: "absolute",
    alignContent: "center",
    backgroundColor: "#efeefe",
    transform: "rotate(-45.5deg) translate(-43%, -28%)",
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    left: "50%",
    top: "50%",
  },
  apiaryCardActions: {
    // justifyContent: "space-between",
    position: "absolute",
    left: "50%",
    bottom: "50%",
    transform: "rotate(-45deg) translate(-48%, 5%)",
    marginTop: "3rem",
  },
  title: {
    Family: "Girassol",
    color: "#046582",
    marginTop: "3rem",
  },
}));

const ApiaryCard = ({ item, setOpenModal, setApiaryID, setUpdateApiary }) => {
  const {
    apiaryTitle,
    apiaryLocation,
    apiaryId,
    apiaryNotes,
    numberOfHives,
    latitude,
    longitude,
  } = item;
  // console.log('item:', item)

  const classes = useStyles();
  const history = useHistory();
  const { currentUser } = useAuth();
  const { updateTotalHivesList } = useApiary();
  const uid = currentUser?.uid;
  const [cardHover, setCardHover] = useState(false);

  const openDetails = () => {
    if (!currentUser) {
      toastErrorNotify("Please Login to get the details");
    }
    // if user doenst exist it is routed to the login page via PrivateRouter
    // history.push(`/hives/${apiaryId}`);
    history.push(`/apiaryNavigator/${apiaryId}`);
  };
  const updateHandler = (id) => {
    setApiaryID(apiaryId);
    setUpdateApiary(true)
    // history.push(`/update-apiary/${apiaryId}`);
  };
  const handleDeleteHandler = (uid, apiaryId) => {
    setOpenModal(true);
    setApiaryID(apiaryId);
  };

  const { t } = useTranslation(["common"]);
  useEffect(() => {
    // updateTotalHives(uid);
    updateTotalHivesList(uid);
  }, []);

  return (
    <Card
      onMouseEnter={() => setCardHover(true)}
      onMouseLeave={() => setCardHover(false)}
     
      className={!cardHover ? classes.cardRoot : classes.cardRootHover}
    >
      <CardActionArea  onClick={openDetails}>
        {/* <CardMedia
          className={classes.media}
          image={image || placeholder}
          title={apiaryTitle}
        /> */}

        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {apiaryTitle}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p">
            {moment(published_date).format("MMM DD, YYYY")}
          </Typography> */}
          <h4 className={classes.module}>
            {t("yer")}: {apiaryLocation}
          </h4>
          <h4 className={classes.module}>
            {t("kovansayısı")}: {numberOfHives}
          </h4>

          {/* <p className={classes.module}>Notlar: {apiaryNotes}</p>  will be changed to a comment
          <p className={classes.module}>Enlem: {latitude} Boylam: {longitude}</p> will be changed to a comment */}
          {/* <p className={classes.module}>id: {apiaryId}</p> */}
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.apiaryCardActions}>
        <IconButton>
          <HiveIcon onClick={openDetails} />
        </IconButton>
        {/* <Typography gutterBottom variant="h7" component="h4">
          Kovan Sayısı: {numberOfHives}
        </Typography> */}

        <div>
          <IconButton>
            <EditIcon
              style={{ fontSize: "2rem" }}
              onClick={() => updateHandler(apiaryId)}
            />
          </IconButton>
          <IconButton>
            <DeleteIcon
              style={{ fontSize: "2rem" }}
              onClick={() => handleDeleteHandler(uid, apiaryId)}
            />
          </IconButton>
        </div>

        {/* <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => updateHandler(apiaryId)}
          > Edit
          </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={() => deleteHandler(uid, apiaryId)}
         > Sil
          </Button> */}
      </CardActions>
      {/* <CardActions>
        <IconButton aria-label="add to favorites" className={classes.image}>
          <FavoriteIcon color={get_like_count > 0 ? "secondary" : "disabled"} />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {get_like_count}
        </Typography>
        <IconButton aria-label="comment count" className={classes.image}>
          <ChatBubbleOutlineIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          {get_comment_count}
        </Typography>
      </CardActions> */}
    </Card>
  );
};

export default ApiaryCard;
