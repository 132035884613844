import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import WebImage1 from "../../assets/web_screenshot_1.png"
import WebImage2 from "../../assets/web_screenshot_2.png"
import "./Carousel.css"
// import WebImage3 from "../../assets/web_screenshot_3.png"


const Carousel2 = () => {
  return (
    <div>
        <Carousel variant="dark">
      <Carousel.Item >
      <div className='carousel2-images'>
      <img
          src={WebImage1}
          alt="First slide"
        />

      </div>        
      </Carousel.Item>
      <Carousel.Item >
      <div className='carousel2-images'>
      <img
          src={WebImage2}
          alt="First slide"
        />
       
      </div>
        
      </Carousel.Item>
      {/* <Carousel.Item >
      <div className='carousel-images'>
      <img
            // style={{width:"14rem"}}
          src={MobilImage6}
          alt="First slide"
        />
       
      </div>
        
      </Carousel.Item> */}
  
    </Carousel>
    </div>
  )
}

export default Carousel2