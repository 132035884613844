import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Paper from '@material-ui/core/Paper';
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

import NoteCard from "../components/NoteCard";
// import Weather from "../weather/Weather.js";
import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";

import loadingGif from "../assets/loading.gif";
import backImage from "../assets/apiary3.png";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

 


const useStyles = makeStyles((theme) => ({
  noteRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(5),
    },
  },

  title: {
    // flexGrow: 1,
    fontFamily: "Girassol",
    textAlign: "center",
    color: "#591900",
  },
  noteHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // paddingTop: 30,
    // flexGrow: 1,
    "& > *": {
      margin: theme.spacing(5),
    },
  },
  cardContent: {
    display: "flex",
    backgroundColor: "#E0E0E0",
  },
  headerCard: {
    display: "flex",
  },
  noteButton: {
    height: 60,
  },
  noteMainRoot: {
    display: "flex",
    // marginTop: 30,
    // backgroundImage: `url(${backImage})`,
    // backgroundRepeat: "repeat",
    // backgroundSize: "cover",
  },
}));

const ApiaryNotes = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentNotes, getNotes } = useApiary();

  console.log("Current Notes:", currentNotes);

  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  const { id } = useParams();
  console.log("Note sayafası id'si : ", id);

  const NewNoteHandler = (id) => {
    history.push(`/new-note/${id}`);
    console.log("Note Handler id'si : ", id);
  };

  useEffect(() => {
    getNotes(uid, id);
  }, []);

   const { t } = useTranslation(["common"]);

  return (
    <div className={classes.noteMainRoot}>
      <Grid container className={classes.noteRoot} justifyContent="center">
        <Card className={classes.headerCard}>
          <CardContent className={classes.cardContent} color="transparent">
            {t("toplam")} {t("notlar")}: {currentNotes.length}
          </CardContent>
        </Card>

        {/* <Button
          className={classes.noteButton}
          startIcon={<LibraryAddIcon />}
          variant="contained"
          color="transparent"
          onClick={() => NewNoteHandler(id)}
        >
          Not Ekle
        </Button> */}

        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={5}>
            {currentNotes === undefined ? (
              <img src={loadingGif} alt="loading" />
            ) : currentNotes ? (
              currentNotes.map((item, noteId) => (
                <Grid key={noteId} item>
                  <NoteCard item={item} id={id} />
                </Grid>
              ))
            ) : (
              <h3>No data available.</h3>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ApiaryNotes;
