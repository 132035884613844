import React from "react";
import Container from "react-bootstrap/Container";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { MdForum } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { MdQuickreply } from "react-icons/md";

const ForumCard = ({ blog }) => {
  const history = useHistory();
 const htmlContent = [0,1,2,3,4,5,6,7,8].map(i =>
   blog?.content.split(/\s*((?:[^\s<]*<\w[^>]*>[\s\S]*?<\/\w[^>]*>)+[^\s<]*)\s*/)[i]
 )
 const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

const resurlt = htmlContent?.map(e => e?.startsWith("<figure><img") ? `${insertAt(e, ' style="max-width:70vw"', 12)}` : e)
console.log("result", resurlt)

  return (
    <Container
      onClick={() => history.push({pathname:`/forum/carddetails`,  state: { detail: blog }})}
      className="post-card border rounded-lg p-3 mt-3"
    >
      <Row className="">
        <Col sm={11}>
          <Row>
            <Col sm={1}>
              <h3>
                <MdForum className="text-info" />
              </h3>
            </Col>
            <Col
              className="d-flex flex-column align-items-start justify-content-start"
              style={{ cursor: "pointer" }}
              sm={10}
            >
              <div>
                <div
                style={{ textAlign: "start" }} 
                  dangerouslySetInnerHTML={{ __html: resurlt.join("") }}
                ></div>
              </div>

              <p style={{ width: "7rem" }} className="p-2 bg-info">
                #{blog?.tag}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          md={{ span: 4, offset: 8 }}
          className="d-flex justify-content-center align-items-center"
        >
          <h6 className="p-1 border">Share in {blog?.date}</h6>
          <h2 className="text-danger m-3">
            <FaHeart />
            <span className="ms-2">{blog?.like === "" ? 0 : Array.from(new Set(blog?.like?.split(" "))).length}</span>

          </h2>
          {/* <h2 className="text-primary m-3">
          <MdQuickreply />
          10
        </h2> */}
        </Col>
      </Row>
    </Container>
  );
};

export default ForumCard;
