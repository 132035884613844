import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { Dialog } from "@mui/material";

import { useTranslation } from "react-i18next";




const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function OneCalendarNoteModal({
  currentCalendarNotes,
  deleteOneCalendarNote,
  states,
  handleClose,
  uid,
}) {
  const handleRemoveNote = () => {
    deleteOneCalendarNote(uid, states.id);
    handleClose();
  };
  const [selectedCalendarNote] = currentCalendarNotes.filter(
    (note) => note.id === states.id
  );
  const { t } = useTranslation(["common"]);
  console.log(states.openModal);

  return (
    <div>
      <Modal
        maxWidth="sm"
        fullWidth
        open={states.openModal}
        onClose={handleClose}
        height="800"
      >
        <Fade in={states.openModal}>
          <Box
            key={states.openModal && selectedCalendarNote?.id}
            style={{
              backgroundColor:
                states.openModal && selectedCalendarNote?.backgroundColor,
            }}
            sx={style}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {states.eventTitle}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t("baslamazamanı")} :
              {states.openModal &&
                format(new Date(states.startTime), "dd/MM/yyyy")}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t("bitiszamanı")} :
              {states.openModal &&
                format(new Date(states.endTime), "dd/MM/yyyy")}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t("arilik")}: {states.openModal && selectedCalendarNote.apiaryName}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {t("acıklamalar")}: {states.openModal && selectedCalendarNote.desc}
            </Typography>
            <div>
              <Button
                style={{ margin: "0.5rem 0.5rem" }}
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleRemoveNote}
              >
                {t("sil")}
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                startIcon={<CancelIcon />}
              >
                {t("iptal")}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
