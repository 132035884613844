import {
    Box,
    Grid,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
  } from "@chakra-ui/react";
  import { dateFormat } from "../helpers/extraFunctions";
  import { NewText } from "./SmallComponents";
  import { ImSun } from "react-icons/im";
  import { MdOutlineNightsStay } from "react-icons/md";
  import { useTranslation } from "react-i18next";


  
  export const ForcastModal = ({ data }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation(["common"]);
    const { date, day } = dateFormat(data.dt, t("lang"));

  
    return (
      <>
        <Box bg={"#F7C160"} onClick={onOpen} cursor={"pointer"} mt={"1px"}>
          <div style ={{display:"flex", alignItems:"center", justifyContent:"center"}}>  
            <img style={{width:"3rem"}} src= {`http://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`} />
          <Text color={"#1D1D1D"} fontWeight={900} fontSize={"10px"}>
            {data.weather[0].description.toUpperCase()}
          </Text>
  
          </div>
          <Text color={"#1D1D1D"} fontWeight={500} fontSize={"20px"}>
  
            <Icon as={ImSun} /> {Math.round(data.temp.day)}
            <sup>o</sup> C
          </Text>
  
          <Text color={"#1D1D1D"} fontWeight={500} fontSize={"20px"}>
            <Icon as={MdOutlineNightsStay} /> {Math.round(data.temp.night)}
            <sup>o</sup> C
          </Text>
   
        </Box>
  
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
  
            <ModalBody>
              <Box p={"5px"}>
                <Box
                  p={"5px"}
                  bg={"#52616B"}
                  textAlign={"center"}
                  borderRadius={"30px"}
                  mb={"20px"}
                >
                  <Text fontWeight={500} color={"white"} fontSize={"18px"}>
                    {date}
                  </Text>
                  <Text fontWeight={500} color={"white"} fontSize={"18px"}>
                    {day}
                  </Text>
                </Box>
  
                <Grid templateColumns={"50% 50%"}>
                  <Box pb={"10px"} pl={"15%"}>
                    {[
                      t("hissedilensıcaklık"),
                      t("nem"),
                      t("ruzgar"),
                      t("basınc"),
                      t("gunluksıcaklık"),
                      t("aksamsıcaklıgı"),
                      t("gecesıcaklıgı"),
                      t("enyukseksıcaklık"),
                      t("endusuksıcaklık"),
                    ].map((e, i) => (
                      <Text
                        key={i}
                        color={"#52616B"}
                        fontWeight={500}
                        mt={"15px"}
                        fontSize={"15px"}
                      >
                        {e}
                      </Text>
                    ))}
                  </Box>
                  <Box
                    borderRadius={"30px"}
                    bg={"#52616B"}
                    pb={"0px"}
                    pl={"15%"}
                  >
                    <NewText>
                      {data.feels_like.day}
                      <sup>o</sup> C
                    </NewText>
                    <NewText>{data.humidity}%</NewText>
                    <NewText>{(data.wind_speed * 3.6).toFixed(2)} Km/h</NewText>
                    <NewText>{data.pressure} hPa</NewText>
                    <NewText>
                      {data.temp.day}
                      <sup>o</sup> C
                    </NewText>
                    <NewText>
                      {data.temp.eve}
                      <sup>o</sup> C
                    </NewText>
                    <NewText>
                      {data.temp.night}
                      <sup>o</sup> C
                    </NewText>
                    <NewText>
                      {data.temp.min}
                      <sup>o</sup> C
                    </NewText>
                    <NewText>
                      {data.temp.max}
                      <sup>o</sup> C
                    </NewText>
                  </Box>
                </Grid>
              </Box>
            </ModalBody>
  
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  