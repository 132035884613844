import React, { useContext, createContext, useState, useEffect } from "react";
import { auth, googleProvider, facebookProvider, appleProvider} from "../utils/firebaseUtil";

//! Create context for autentication data
const AuthContext = createContext();

//! Define a function to get data from Auth context
export function useAuth() {
  return useContext(AuthContext);
}

const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  // const uid = currentUser.uid;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    auth.signOut();
  }

  function loginWithGoogle() {
    googleProvider.setCustomParameters({ prompt: "select_account" });
    auth.signInWithPopup(googleProvider);
  }

  function loginWithFacebook(){
    facebookProvider.setCustomParameters({'display': 'popup'});
    auth.signInWithPopup(facebookProvider);
  }

  function loginWithApple(){
    appleProvider.setCustomParameters({locale: 'tr'});
    auth.signInWithPopup(appleProvider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
      console.log("credential Apple:", credential)
      // The signed-in user info.
      var user = result.user;
      console.log("user Apple:", user)
      // You can also get the Apple OAuth Access and ID Tokens.
      var accessToken = credential.accessToken;
      console.log("accessToken Apple:", accessToken)
      var idToken = credential.idToken;
      console.log("idToken Apple:", idToken)

      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      console.log("errorCode Apple:", errorCode)
      var errorMessage = error.message;
      console.log("errorMessage Apple:", errorMessage)
      // The email of the user's account used.
      var email = error.email;
      console.log("email Apple:", email)
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      console.log("error credential Apple:", credential)

      // ...
    });
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  const values = {
    currentUser,
    // uid,
    signup,
    login,
    logout,
    
    resetPassword,
    updatePassword,
    updateEmail,

    loginWithGoogle,
    loginWithFacebook,
    loginWithApple,
  };
  return (
    <AuthContext.Provider value={values}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
