import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import StoreBadge from "react-store-badge";


const UprageModal = (props) =>  {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            PREMIUM YÜKSELTME
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4></h4>
        <p>
          Premium Hesap için lütfen Android veya IOS telefonunuzdan Beecity Apiary uygulamamızı indirip satın alınız.
        </p>
        <StoreBadge
                        name="Beecity Apiary"
                        googlePlayUrl="https://play.google.com/store/apps/details?id=com.arikent.apiary"
                        appStoreUrl="https://apps.apple.com/us/app/beecity-apiary/id1633088644"
                      />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Kapat</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default UprageModal;