import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import FeedingIcon from '@material-ui/icons/FilterVintageOutlined';
// import placeholder from "../assets/petek.png";
// import image from "../assets/apiary2.jpg";

import { useAuth } from "../context/AuthContextProvider";
import { useApiary } from "../context/ApiaryContextProvider";
import { toastErrorNotify } from "../utils/ToastNotify";
import { toastSuccessNotify } from "../utils/ToastNotify";

import { useHistory, useParams } from 'react-router-dom';
// import { TextField } from "@mui/material";

import { useTranslation } from "react-i18next";




const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: 345,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  module: {
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "text-overflow": "ellipsis",
    overflow: "hidden",
    textAlign: "left",
  },
  image: {
    padding: 3,
  },
  avatar: {
    marginBottom: "0.35em",
  },
  cardContent: {
    backgroundColor: "#efeefe",
    // height: "125px",
  },
  feedingCardActions: {
    justifyContent: "space-between",
  },
  title: {
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const FeedingCard = ({ item }) => {
  const {
    feedingId,
    cakeNeed,
    syrupNeed,
    fondanNeed,
    honeySyrupNeed,
    honeyCakeNeed,
    other,
    date,
    cakeUnit,
    syrupUnit,
    fondanUnit,
    honeySyrupUnit,
    honeyCakeUnit,
    otherUnit,
  } = item;

  const classes = useStyles();
  const history = useHistory();
  const { deleteOneFeeding, apiaryIdFromHive } = useApiary();

  const { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const { id } = useParams();
  const apiaryId = apiaryIdFromHive; 
  const hiveId = id;
  const { t } = useTranslation(["common"]);

  const openDetails = () => {
    if (!currentUser) {
      toastErrorNotify("Please Login to get the details");
    }
    // if user doenst exist it is routed to the login page via PrivateRouter
    history.push(`/hiveNavigator/${hiveId}`);
  };

  // const updateHandler = () => {
  //   history.push(`/update-feeding/${feedingId}`);
  // };

  const deleteHandler = (uid, apiaryId, hiveId, feedingId) => {
    deleteOneFeeding(uid, apiaryId, hiveId, feedingId);
    history.push(`/hiveNavigator/${hiveId}`);
    toastSuccessNotify(t("Başarıyla Silindi"));
    console.log('delete handler feeding id:', feedingId)
  };

  return (
    <Card className={classes.cardRoot}>
      <CardActionArea onClick={openDetails}>
        {/* <CardMedia
          className={classes.media}
          image={image || placeholder}
          title='Beslenme Durumu'
        /> */}

        <CardContent className={classes.cardContent}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className={classes.title}
          >
            {t("beslenmetarihi")}: {date}
          </Typography>
          <p className={classes.module}>{t("kekmiktarı")}           : {cakeNeed} {cakeUnit}</p>
          <p className={classes.module}>{t("surupmiktarı")}           : {syrupNeed} {syrupUnit}</p>
          <p className={classes.module}>{t("fondanmiktarı")}         : {fondanNeed} {fondanUnit}</p>
          <p className={classes.module}>{t("ballısurupmiktarı")}    : {honeySyrupNeed} {honeySyrupUnit}</p>
          <p className={classes.module}>{t("ballıkekmiktarı")}       : {honeyCakeNeed} {honeyCakeUnit}</p>
          <p className={classes.module}>{t("digermiktar")}           : {other} {otherUnit}</p>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.feedingCardActions}>
        <FeedingIcon className={classes.avatar} />

        <Button
          className={classes.buttonDelete}
          variant="contained"
          onClick={() => deleteHandler(uid, apiaryId, hiveId, feedingId)}
        >
         {t("sil")}
          </Button>
      </CardActions>
    </Card>
  );
};

export default FeedingCard;
