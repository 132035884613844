import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useApiary } from '../context/ApiaryContextProvider';
import { useAuth } from "../context/AuthContextProvider";
import { useTranslation } from "react-i18next";
import { toastSuccessNotify } from '../utils/ToastNotify';



function createData(note, apiary, date, isFinished, noteId, apiaryId, uid) {
  return {
    note,
    apiary,
    date,
    isFinished,
    noteId,
    apiaryId,
    uid
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation(["common"]);

const headCells = [
  {
    id: 'note',
    numeric: false,
    disablePadding: true,
    label: t('Notlar'),
  },
  {
    id: 'apiary',
    numeric: false,
    disablePadding: false,
    label: t('Arılık'),
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: t('Tarih'),
  },
  {
    id: 'isFinished',
    numeric: false,
    disablePadding: false,
    label: t('Tamamlanma'),
  },

];

  return (
    <TableHead style={{backgroundColor:"orangered", width:"100vw"}}>
      <TableRow >
        <TableCell>
          
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          
          </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



export default function AllNotesTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [isSelected, setIsSelected] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { currentUser } = useAuth();

  const { t } = useTranslation(["common"]);


  const { getAllNotes, allNotes, currentApiaries,getApiaries, updateOneNote, deleteOneNote } = useApiary();

  const uid = currentUser?.uid;

  const selected = allNotes.filter(note => note.isFinished=== true)
  React.useEffect(() => {
   getAllNotes(uid)

  }, [isSelected])
  // React.useEffect(() => {
  //  getApiaries(uid)
  // }, [])

  const filterApiary = (selectedApiaryId) => currentApiaries.filter(apiary => apiary.apiaryId == selectedApiaryId )
  const rows = allNotes?.map(note =>  createData(note.notes, filterApiary(note.apiaryId)[0]?.apiaryTitle, note.date , note.isFinished, note.noteId, note.apiaryId, note.uid  ),
  )


  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleDeleteNote = (note) =>{
    deleteOneNote(note.uid, note.apiaryId, note.noteId )
    toastSuccessNotify(t("Başarıyla Silindi"));

  }



  
  const handleClick = (event, row) => {
    setIsSelected(!isSelected)
      updateOneNote(row.uid, row.apiaryId, row.noteId, {
        apiaryId: row.apiaryId,
        noteId: row.noteId,
        uid: row.uid,
        date: row.date,
        isFinished: !row.isFinished,
        notes: row.note,
    })


  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




  // Avoid a layout jump when reaching the last page with empty rows.


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length > 0 ? selectedle} /> */}
        <h2>{t("Arılık Not Takip Listesi")}</h2>
        <h4 style={{textAlign:"left"}}>{t("Toplam Not Sayısı")} : {rows.length}</h4>
        <h4 style={{textAlign:"left"}}>{t("Tamamlanan Not Sayısı")} : {selected.length}</h4>

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={selected.length}
            />
            <TableBody>
   
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  {/* console.log("isItemSelected", isItemSelected) */}

                  return (
   
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={row.isFinished}
                      tabIndex={-1}
                      key={row.note}
                      selected={row.isFinished}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={row.isFinished}                          
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.note}
                      </TableCell>
                      <TableCell align="center">{row.apiary}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">{row.isFinished ? t("Tamamlandı") : t("Tamamlanmadı")}</TableCell>
                      <TableCell align="center"><Tooltip title="Delete">
          <IconButton>
            <DeleteIcon onClick={() => handleDeleteNote(row)} />
          </IconButton>
        </Tooltip></TableCell>
                    </TableRow>
                  );
                })}
              
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
     
    </Box>
  );
}
