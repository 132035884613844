import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import RichTextEditor from "./components/RichTextEditor";
import firebase from "firebase";

import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { toast } from "react-toastify";

const Admin = () => {
  const [editorContent, setEditorContent] = useState();
  const [sendContent, setSendContent] = useState(false);
  const [tag, setTag] = useState();
  const currentDate = format(new Date(), "dd/MM/yyyy");

  const handleAddInfo = async () => {
    setSendContent(true)
    await firebase
      .database()
      .ref("blog/" + uuidv4())
      .set({ content: editorContent, tag: tag, date: currentDate, like: "" })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "center", minHeight: "100vh" }}
    >
      <div style={{ width: "80vw", marginTop: "2rem" }}>
        <RichTextEditor setEditorContent={setEditorContent} />

        <input
          className="m-3"
          placeholder="Enter Tag..."
          value={tag}
          onChange={(e) => setTag(e.target.value)}
        />
        <br />

        <Button disabled={sendContent} className="m-3" onClick={handleAddInfo}>
          Send Forum
        </Button>
        {sendContent && <h4 className="text-success">Content successfully sended Forum</h4>}
      </div>
    </div>
  );
};

export default Admin;
