import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GrFacebook } from "react-icons/gr";
import { AiFillYoutube } from "react-icons/ai";
import "draft-js/dist/Draft.css";


import { v4 as uuidv4 } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContextProvider";
import {AiOutlineLike,AiFillLike} from "react-icons/ai"
import {BsBackspaceFill} from "react-icons/bs"
import firebase from "firebase";


const CardDetails = () => {
  const location = useLocation();
  const [handleRate, setHandleRate] = useState(false)
  const blogDetail = location?.state?.detail
  let { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const history = useHistory();
  const alluid = (blogDetail?.like + " " + uid).trim();

  const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
  const splitedContent = blogDetail?.content.split(/\s*((?:[^\s<]*<\w[^>]*>[\s\S]*?<\/\w[^>]*>)+[^\s<]*)\s*/)
  const resurlt = splitedContent?.map(e => e?.startsWith("<figure><img") ? `${insertAt(e, ' style="max-width:70vw"', 12)}` : e)
  console.log("result", resurlt)

  const handleUpdateBlog = async () => {
    if(uid === undefined){
      alert("Please register BeeCity App")
        // history.push("/")

      }
    else{
      setHandleRate(true);
      await firebase
      .database()
      .ref("blog/" + blogDetail?.id)
        .update({ ...blogDetail, like:alluid
       })
        .catch((error) => {
          console.log(error);
        });

    }
  };
  return (
    <Container className="p-3">
      <Row>
        <Col
          className="d-flex flex-column align-items-start justify-content-center"
          sm={10}
        > 
          <h3 style={{cursor:"pointer"}} onClick={() => history.push("/forum")} className="text-black bg-info"><BsBackspaceFill /> BACK</h3>
        </Col>
        <Col sm={2}>
          <p
            style={{ backgroundColor: "#4267B2", cursor: "pointer" }}
            className="py-1"
          >
            <h4 className="text-white">
              <GrFacebook /> Follow
            </h4>
          </p>
          <p
            style={{ backgroundColor: "#FF0000", cursor: "pointer" }}
            className="py-1"
          >
            <h4 className="text-white">
              <AiFillYoutube /> Subscribe
            </h4>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={4} sm={2} md={2} lg={1}>
          <p className="border rounded-circle bg-info text-white font-weight-bolder p-3">
            ST
          </p>
        </Col>
        <Col xs={10} sm={10}  md={11} lg={11}>
          <h5 style={{ textAlign: "start" }} className="text-muted">
            Support Team posted this {blogDetail?.date}
          </h5>
          <p className="border-bottom p-1"></p>
          
          <div>
                <div
                style={{ textAlign: "start", maxWidth:"90vw" }} 
                  dangerouslySetInnerHTML={{ __html: resurlt?.join("") }}
                ></div>
              </div>
         
          <div className="d-flex align-items-center justify-content-start">

            <p style={{ width: "7rem" }} className="p-2 bg-info m-2">
              {blogDetail?.tag}
            </p>
          </div>
           {( Array.from(new Set(blogDetail?.like?.split(" "))).includes(uid)) ? 
          <><h2><AiFillLike /></h2><span>YOU ARE RATED</span></> : 
          <div style={{cursor:"pointer"}} onClick={handleUpdateBlog}>
            <h2>{handleRate ? <AiFillLike /> : <AiOutlineLike />}</h2><span> RATE</span>

          </div>

          }
        </Col>
      </Row>
    </Container>
  );
};

export default CardDetails;
