import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";

import ApiaryNotes from "../pages/ApiaryNotes";
import Hives from "./Hives";
import TotalHive from "../components/TotalHive";
import NewNote from "./NewNote";

import HiveCard from "../components/HiveCard";
import loadingGif from "../assets/loading.gif";

import { useHistory, useParams } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import NotesIcon from "@material-ui/icons/Notes";
import AddIcon from "@material-ui/icons/Add";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BackGroundBee from "../assets/apiaries2.jpg";

import { useTranslation } from "react-i18next";
import moment from "moment";

// import clsx from 'clsx';
// import EditIcon from '@material-ui/icons/Edit';
// import Button from '@material-ui/core/Button';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  // container:{
  //   width: "100%",
  //   minHeight: "100%",
  //   position:"relative",
  //   backgroundImage : "linear-gradient(rgba(245,240,187,0.3) 75%, rgba(255,159,41,0.5))",
  // },
  hiveTabsRoot: {

    width: "100%",
    height: "1.5rem",
    display:"block", 
    backgroundColor:"#EEBF7D",

  },
  hiveNaviRoot: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
  },

  hiveNaviSwipe: {
    height: "100vh",
  },
  hiveData: {
    fontSize: theme.spacing(1.7),
  },

  hiveMainRoot: {
    display: "flex",
    minHeight: "80vh",
  },

  hiveButton: {
    height: 60,
    width: 150,
  },

  hiveHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  //  tabPanel:{
  //   backgroundColor:"#efeefe",
  //   // height:"1.5rem",
  //   "& > *":{
  //     color:"#000000",
  //     fontWeight:900
  // },
// },
//   activeTab:{
//     backgroundColor : "rgba(255,69,0,0.7)"
//   },
  // defaultTab:{
  //   backgroundColor : "#efeefe"
  // },

  sectionGrid: {
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
  },
  controlGrid: {
    marginTop: theme.spacing(1),
    margin: theme.spacing(0.5),
  },

  fab: {
    position: "absolute",
    bottom: theme.spacing(15),
    top: theme.spacing(12),
    right: theme.spacing(2),
    backgroundColor:"rgba(255,69,0,0.8)"
  },
  fabIn: {
    position: "sticky",
    top: theme.spacing(1),
    right: theme.spacing(2),
  },

  // totalCard: {
  //   marginBottom: theme.spacing(5),
  // },
}));

export default function ApiaryNavigator() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const dateCreate = moment().format("dd/MM/yyyy");
  const[year, setYear] = useState(String(dateCreate.slice(6,10)))
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    currentHives,
    getHives,
    appendMethods,
    getOneApiary,
    oneApiary,
    getAllHarvest,
    allControlledHarvest
  } = useApiary();

  const { id } = useParams();
  const [apiaryIdFromHive, setApiaryIdFromHive] = useState(id);
  const { t } = useTranslation(["common"]);

  console.log("allControlledHarvests:", allControlledHarvest());

  let { currentUser } = useAuth();
  const uid = currentUser?.uid;

  console.log("Hive sayfasının id'si : ", id);
  console.log("Apiary id'si : ", oneApiary);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const NewHiveHandler = (id) => {
    history.push(`/new-hive/${id}`);
    console.log("handler id'si : ", id);
  };

  const NotesHandler = (id) => {
    history.push(`/new-note/${id}`);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
    color: "primary",
    className: classes.fab,
    icon: <AddIcon />,
    label: "Add",
    onClick: () => NewHiveHandler(id),
    },
    {
      color: "primary",
      className: classes.fab,
      icon: <AddIcon />,
      label: "Add",
      onClick: () => handleClickOpen(),
    },
    // {
    //   color: "primary",
    //   className: classes.fab,
    //   icon: <AddIcon />,
    //   label: "Add",
    //   onClick: () => ReportHandler(id),
    // },
  ];


  useEffect(() => {
    getHives(uid, id);
    appendMethods({ apiaryIdFromHive });
    getOneApiary(uid, id)

  }, []);




  return (
    <Grid item xs={12} md={12} lg={12}>
    <div className={classes.container}>
    <div className={classes.hiveTabsRoot}>
      <h4 style={{textAlign:"center", textTransform:"uppercase"}}>{oneApiary[0]?.apiaryTitle}</h4>
    </div>
    <div className={classes.hiveNaviRoot}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          // className={classes.tabPanel}
        >
          <Tab className={value === 0 ? classes.activeTab : classes.defaultTab} label={t("arilik")} {...a11yProps(0)} />
          <Tab className={value === 1 ? classes.activeTab : classes.defaultTab} label={t("notlar")} {...a11yProps(1)} />
          <Tab className={value === 2 ? classes.activeTab : classes.defaultTab} label={t("rapor")} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        className={classes.hiveNaviSwipe}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Hives/>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <ApiaryNotes/>
          <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Yeni Not Ekleyin"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <NewNote/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose} color="primary">
            Ekle
          </Button> */}
          <Button onClick={handleClose} color="primary" autoFocus>
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          {/* <Grid className={classes.totalCard} container item xs={12}> */}
            <TotalHive year={year} setYear={setYear}/>
          {/* </Grid> */}
        </TabPanel>
      </SwipeableViews>
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              value === index ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Tooltip title="Ekle" aria-label="add">
            <Fab
              aria-label={fab.label}
              className={fab.className}
              color={fab.color}
              onClick={fab.onClick}
            >
              {fab.icon}
            </Fab>
          </Tooltip>
        </Zoom>
      ))}
    </div>
    </div>
    </Grid>
  );
}
