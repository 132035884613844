import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@mui/material/TextField";
// import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { useHistory } from "react-router-dom";
import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import { Formik, Form } from "formik";
import * as yup from "yup";

import { useAuth } from "../context/AuthContextProvider";
import loadingGif from "../assets/loading.gif";
import googlePng from "../assets/google.png";
import facebookPng from "../assets/facebook.png";
import backgroundImage from "../assets/arikentApiary.jpeg";
// import backgroundImage from "../assets/hive.png";
import applePng from "../assets/apple.png";
import apiaryPng from "../assets/apiary2.jpg";
// import backImage from "../assets/apiary3.png";

import { useTranslation } from "react-i18next";

import { DialogActions, DialogContentText } from "@material-ui/core";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  authRoot: {
    // display: "grid",
    width: "100vw",
    // marginLeft: 'auto',
    // marginRigth: 'auto',
    // flexGrow: 1,
    // flexShrink: 3,
    // flexDirection: "row",
    // alignItems: "center",
    justifyContent: "center",
    // flexArea: 'content',
    // backgroundColor: "purple",
    // height: "100vh",

    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",

    "& .MuiPaper-root": {
      borderRadius: "30px",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);",
      height: "fit-content",
      margin: 30,
      maxWidth: "100%",
    },
  },
  // mainRoot: {
  //   display: "flex",
  //   flexArea: 'content',
  //   flexDirection: "row",
  // },
  avatarImg: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    padding: "40px",
  },
  paper: {
    margin: theme.spacing(4, 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(25),
    height: theme.spacing(25),
    backgroundColor: "#046582",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#046582",
    color: "white",
    fontWeight: "bold",
    "&:hover": {
      color: "#046582",
    },
  },
  header: {
    fontFamily: "Girassol",
    textAlign: "center",
    color: "#046582",
  },
  askLoginRegister: {
    textAlign: "center",
    fontSize: 13,
    color: "red",
    "& span": {},
    "& > a ": {
      fontWeight: 900,
      backgrounColor: "blue",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  loadingGif: {
    width: 75,
  },
  loadingContainer: {
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },

  googleImg: {
    width: 75,
    heigth: 15,
    marginLeft: 10,
  },

  facebookImg: {
    width: 75,
    heigth: 15,
    marginLeft: 10,
  },

  appleImg: {
    width: 75,
    heigth: 15,
    marginLeft: 10,
  },

  googleBtn: {
    backgroundColor: "white",
    fontWeight: "bold",
  },
  facebookBtn: {
    marginTop: theme.spacing(2),
    backgroundColor: "white",
    fontWeight: "bold",
  },
  appleBtn: {
    marginTop: theme.spacing(2),
    backgroundColor: "white",
    fontWeight: "bold",
  },
}));

const ValidationSchema = yup.object().shape({
  email: yup
    .string("Enter your email")
    .email("Invalid email address")
    .required("Required"),
  password: yup.string("Enter your password").required("password required"),
});

const LoginAndRegisterForm = (props) => {
  console.log(props);

  const { loginWithGoogle, loginWithFacebook, loginWithApple, signup } = useAuth();
  const { t } = useTranslation(["common"]);
  const [openModal, setOpenModal] = useState(false);

  const history = useHistory();

  const handleGoogleProvider = () => {
    loginWithGoogle();
  };

  const handleFacebookProvider = () => {
    loginWithFacebook();
  };

  const handleAppleProvider = () => {
    loginWithApple();
  };

  const {
    handleBlur,
    handleChange,
    errors,
    values,
    touched,
    isSubmitting,
    method,
    setSubmitting
  } = props;
  console.log("method", method);
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleSignIn = () =>{
    setOpenModal(false)
    signup(values.email, values.password)
    .then(() => {
      toastSuccessNotify(`${method} Successfully performed!`);
      history.push("/userNavigator/:id");
      setSubmitting(false);
    })
    .catch((error) => {
      toastErrorNotify(error.message);
      setSubmitting(false);
      // resetForm();
    });
  }

  return (
      <Grid container className={classes.authRoot}>
        {/* <Grid container justifyContent="center" className={classes.image}> */}
        {/* <Grid item component={Paper} elevation={6} square xs={3} sm={8} md={6}> */}
        <Grid
          item
          component={Paper}
          elevation={6}
          square
          xs={8}
          sm={4}
          md={4}
          lg={4}
        >
          <Grid className={classes.paper}>
            <Avatar className={classes.avatar}>
              <img src={apiaryPng} style={{ width: 200 }} alt="Login" />
            </Avatar>
            <Typography className={classes.header} component="h1" variant="h5">
              ── {method === "Login" ? t("girisyap") : t("uyeol")} ──
            </Typography>
            <Form className={classes.form}>

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                onBlur={handleBlur}
                autoFocus
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={t("sifre")}
                type="password"
                id="password"
                autoComplete="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
              <h5 className={classes.askLoginRegister}>
                {method === "Login" ? (
                  <>
                    <span>{t("Üye değilseniz Üye olmak için lütfen")}</span>{" "}
                    <a onClick={() => history.push("/register")}>
                      Tıklayınız..
                    </a>
                  </>
                ) : (
                  <>
                    <span>{t("Üye girişi yapmak için lütfen")}</span>{" "}
                    <a onClick={() => history.push("/login")}>Tıklayınız..</a>
                  </>
                )}
              </h5>
              {isSubmitting ? (
                <div className={classes.loadingContainer}>
                  <img
                    src={loadingGif}
                    alt="Loading"
                    className={classes.loadingGif}
                  />
                </div>
              ) : (
                <div>
                {method ==="Login" ?
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                  >
                    {t("girisyap")}
                  </Button> : 
                  <Button
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                    onClick={() => setOpenModal(true)}
                  >
                    {t("uyeol")}
                  </Button>      }


                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleGoogleProvider}
                    className={classes.googleBtn}
                  >
                    With
                    <img
                      src={googlePng}
                      alt="google"
                      className={classes.googleImg}
                    />
                  </Button>

                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleFacebookProvider}
                    className={classes.facebookBtn}
                  >
                    With
                    <img
                      src={facebookPng}
                      alt="facebook"
                      className={classes.facebookImg}
                    />
                  </Button>

                  {/* <Button
                    fullWidth
                    variant="contained"
                    onClick={handleAppleProvider}
                    className={classes.appleBtn}
                  >
                    With
                    <img
                      src={applePng}
                      alt="apple"
                      className={classes.appleImg}
                    />
                  </Button> */}
                </div>
              )}
              <Dialog
        // fullScreen={fullScreen}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <h4>Acceptance of Terms</h4>
          <p>
            The Beecity Apiary is an online beehive and apiary management
            application designed for beekeepers for professional beekeeping. The
            Terms and Conditions set forth below (the “Terms”) govern your use
            of the application Beecity Apiary and this site on the world wide
            web. These terms are a legal contract between the User and the
            Platform’s Provider and govern your access to, and use of the
            Beecity Apiary website located at www.beecityapiary.com (the
            “Website”) and/or by downloading and using the Beecity Apiary mobile
            apps and/or by creating an user account within Beecity Apiary mobile
            apps, as the case may be, you agree that you have read and
            understood all of the terms and conditions contained in this Terms
            of Service and you agree to be bound by this Terms of Service. By
            indicating acceptance of this agreement or by otherwise using the
            application, the services or the website, or by using Beecity Apiary
            software, you are entering into this agreement with Beecity Apiary.
            This is a legally binding agreement. If you do not agree with the
            Terms of Service you shall immediately; do not create your account
            and/or stop using the Service. Your access to and use of the Service
            is conditioned on your acceptance of and compliance with these
            Terms. These Terms apply to all visitors, users and others who
            access or use the Service. The Site and the App are available to
            registered users who are not under the age of 16. If you are under
            the age of 16, you are not permitted to register or to send personal
            or business data to Beecity Apiary. We reserve the right to revoke
            your ability to access the products and services offered on the Site
            or the App for any reason at any time including as a result of a
            violation of these Terms or the Privacy Policy, without notice. You
            warrant that you are at least 16-years-old and you are legally
            capable of entering into binding contracts. If you are under
            16-years-old, you warrant that you have obtained consent from your
            parent or guardian and they agree to be bound by these Terms on your
            behalf. By accessing or using the Service you agree to be bound by
            these Terms. If you disagree with any part of the terms then you may
            not access the Service. Purchases If you wish to purchase any
            product or service made available through the Service (“Purchase”),
            you may be asked to supply certain information relevant to your
            Purchase including, without limitation, your credit card number, the
            expiration date of your credit card, your billing address, and your
            shipping information. You represent and warrant that: (i) you have
            the legal right to use any credit card(s) or other payment method(s)
            in connection with any Purchase; and that (ii) the information you
            supply to us is true, correct and complete. By submitting such
            information, you grant us the right to provide the information to
            third parties for purposes of facilitating the completion of
            Purchases.
          </p>
          <h4>Description of Service</h4>
          <p>
            The Beecity Apiary is an online beehive and apiary management
            application that you can easily manage apiaries and beehives. You
            may use the Services for your personal and business use. You may
            connect to the Services using application beecity Apiary supported
            by the Services. You are responsible for obtaining access to the
            Internet and the equipments necessary to use the Services.
          </p>
          <h4>Usage Restrictions</h4>
          <p>
            The Customer will ensure that its use of the Services complies with
            all applicable laws, statutes, regulations or rules. The Customer
            will not and will not attempt to: (a) copy, modify, duplicate,
            decompile or reverse engineer any part of the Services or Software
            by any means; or (b) assist third parties in obtaining, or
            attempting to obtain, access to the Services
          </p>
          <h4>User Registration</h4>
          <p>
            You will be needed to register and submit some information in order
            to use some services on the Site and the App. The following
            representations and warranties are made by you to Beecity Apiary if
            you register as a User or otherwise use the Services: (I) you are of
            legal age to form a binding contract; (II) you will provide Beecity
            Apiary with accurate, current, and complete registration information
            in ApiManager's registration form (the "Registration Data"); and
            (III) your registration and use of the Services are not prohibited
            by applicable law. You acknowledge that it is your responsibility to
            keep the Registration Data truthful, accurate, current, and
            complete. Your account may be suspended or terminated and you may be
            prohibited from using the Beecity Apiary Services in the future if
            you supply any information that is untrue, inaccurate, out-of-date,
            or incomplete, or if Beecity Apiary has a good faith belief that
            such information is false (or any portion thereof). You shouldn't
            utilize a third party's registration or reveal your password with
            anyone. You agree to alert Beecity Apiary immediately if your
            password is lost, stolen, given to an unauthorized party, or
            otherwise appears to have been compromised. You are responsible for
            keeping the confidentiality of your password and account. All
            actions taken on your behalf while using your account are your
            responsibility. You promise to alert Beecity Apiary right away if
            you become aware of any unauthorized use of your account or any
            other security lapse related to this website.
          </p>
          <h4>Intellectual Property Rights and Ownership</h4>
          <p>
            We own all the intellectual Property Rights regarding the Software
            and the Service. Nothing in this Agreement shall operate to assign
            or transfer any Intellectual Property Rights to you. The Customer
            acknowledges that Beecity Apiary retains: (I) all Intellectual
            Property Rights in and to the Software, Website, Portal and the
            Services, including the right to use, and to grant third parties the
            right to use, the Software, Website, Portal and Services for any and
            all purposes whatsoever; and (II) all right, title and interest in
            the Software, Website, Portal and Services, including all copies in
            any form or medium, whether now known or existing or subsequently
            developed. Except to the extent expressly granted in this Agreement,
            the Customer acquires no rights in any of the foregoing.
          </p>
          <h4>License to Use the Services</h4>
          <p>
            The Services are subject to reasonable adjustments at any time by
            ApiManager. No trademark, copyright, or other proprietary markings
            that have been posted on the ApiManager Property may be removed. Any
            alteration, replication, redistribution, republication, uploading,
            posting, transmitting, distributing, or other use of the ApiManager
            Property, or any portion of the ApiManager Property, other than as
            expressly permitted by this Agreement, is strictly prohibited
            without the prior written consent of ApiManager. You acknowledge,
            agree, and represent and warrant that your use of the Services and
            the ApiManager Property, or any portion thereof, will comply with
            the aforementioned license, covenants, and restrictions and will not
            violate the rights of any third party or violate any agreement with
            them or their legal obligations. You also acknowledge and agree that
            you will not use the Services in any manner that restricts or
            inhibits any other person from using or appreciating the Services,
            and that you will comply with all laws, regulations, and ordinances
            that are relevant to the Services, the ApiManager Property, or your
            use of them.
          </p>
          <h4>User Content</h4>
          <p>
            Beecity Apiary shall be considered a software for the management of
            beehives and apiaries. Therefore, you are allowed to use the
            Platform exclusively in relation with this purpose. You may add,
            update, submit, handle in any other way, or share sensitive
            beekeeping business data, beekeeping operating data, beekeeping
            production data, personal contact and third party data, along with
            other materials and information (collectively, "User Content") while
            using Beecity Apiary. You represent and warrant that you own all
            proprietary and business rights in your User Content or, with
            respect to any User Content you do not own, you have the full
            authority and right to store the User Content and to grant the
            licenses granted hereunder, and that your storing and handling of
            the User Content, and the exercise by Beecity Apiary its Partners
            and Customers of the license rights granted by you shall not
            infringe any third party intellectual property rights, nor violate
            any rights of privacy or publicity, nor be defamatory, libelous or
            obscene, nor violate any other right, privilege or interest of any
            third party; and if any royalties are payable to any third party for
            exploitation of User Content stored or handled by you in accordance
            with the licenses granted by you in this Agreement, you shall be
            responsible for and pay such royalties. The User Content that you
            and other users upload, download, or otherwise disseminate is not
            subject to editing or control by Beecity Apiary is not responsible
            or accountable in any manner. Customers and other users of the
            Services are not screened or under the control of Beecity Apiary.
            You do so at your own risk; Beecity Apiary and its Partners will not
            be held responsible for any loss or harm anybody may experience as a
            result of using the Service or relying on the User Content. Users of
            the Services are not allowed to: Use, store, distribute, or transmit
            any User Content in violation of any local, state, national, or
            international laws or in conjunction with such violations; Falsify
            or modify headers to conceal the origin of any User Content,
            impersonate any person or entity, and, unless expressly permitted by
            this Agreement, harvest or otherwise gather information about
            others—including email addresses—without their consent. Engage in
            any other behavior that, in Beecity Apiary's sole discretion,
            exposes Beecity Apiary, its Partners, or any of their officers,
            directors, employees, or agents to any liability or harm of any
            kind. This includes behavior that interferes with the Services,
            limits or inhibits another person's use or enjoyment of any User
            Content, or the Services.
          </p>
          <h4>Services Restrictions</h4>
          <p>
            No user of the Services shall store, upload to, distribute through
            or otherwise use in connection with the Services any User Content
            that: Is libelous, defamatory, threatening, abusive, harassing,
            scandalous, obscene, invasive of another’s privacy, pornographic,
            harmful to minors or unlawful or that encourages a criminal offense;
            Infringes any patent, trademark, trade secret, copyright or other
            proprietary rights of any party, or Content that you do not have a
            right to make available under any law or under contractual or
            fiduciary relationships; Contains any statement, formula, direction,
            recipe, prescription or other matter that involves a reasonably
            foreseeable risk of injury or damage to the material’s readers or
            others; or Harasses, degrades, intimidates or is hateful toward an
            individual or group of individuals on the basis of religion, gender,
            sexual orientation, race, ethnicity, age, or disability; Is false,
            deceptive, misleading, deceitful, misinformative, or constitutes
            “bait and switch”; Constitutes or contains “affiliate marketing,”
            “link referral code,” “junk mail,” “spam,” “chain letters,” “pyramid
            schemes,” or unsolicited commercial advertisement; or contains
            non-local or otherwise irrelevant Content, repeatedly post the same
            or similar Content or otherwise impose an unreasonable or
            disproportionately large load on our infrastructure. Beecity Apiary
            reserves the right (but is not obligated) to do any or all of the
            following: record Customer Content; investigate any allegation that
            Customer Content or Customer registration information does not
            conform to the terms and conditions of this Agreement; remove
            Customer Content or Customer registration information that is
            abusive, illegal or disruptive, or that otherwise fails to conform
            with the terms and conditions of this Agreement; monitor, edit or
            disclose any Customer Content or Customer registration information;
            or edit or delete any Customer Content or Customer registration
            information, regardless of whether such content violates any terms
            and conditions of this Agreement. Beecity Apiary has no liability or
            responsibility to users of the Services or any other person or
            entity for performance or nonperformance of the aforementioned
            activities.
          </p>
          <h4>Limitation of Liability</h4>

          <p>
            The service is provided on an “as is” and “as available” basis.
            Therefore, you acknowledge that your use of the Service is at your
            sole risk. We do not warrant that (I) the Service will meet your
            specific requirements, (II) the Service will be uninterrupted,
            timely, secure, or error-free, (III) the results that may be
            obtained from the use of the service will be accurate or reliable,
            (IV) the quality of any products, services, information, or other
            material purchased or obtained by you through the service will meet
            your expectations, and (V) any errors in the Service will be
            corrected. We do not warrant that the Content will be always
            available, always synchronized and updated and error free. Also, you
            understand that in extraordinary circumstances the Content can be
            lost, therefore we recommend you to always backup the Content you
            provide on the Platform. Beecity Apiary warrants that the Services
            and Application Property will conform substantially to the
            descriptions set forth on the Beecity Apiary website. However,
            Beecity Apiary does not warrant that the Services or Application
            Property will operate error-free or without downtime. Beecity Apiary
            may pause or interrupt the Services at any time, and users should
            expect periodic downtime for updates to Beecity Apiary Services. We
            have no obligation to provide technical support. Thus, we will try
            to fix any errors regarding the functioning of the Platform in a
            proper time. You expressly understand and agree that we shall not be
            liable for any direct, indirect, incidental, special, consequential
            or exemplary damages, including but not limited to, damages for loss
            of profits, goodwill, use, data or other intangible losses resulting
            from: (I) the use or the inability to use the Service; (II) the cost
            of procurement of substitute goods and services resulting from any
            goods, data, information or services purchased or obtained or
            messages received or transactions entered into through or from the
            Service; (III) unauthorized access to or alteration of your
            transmissions or data; (IV) statements or conduct of any third party
            on the Service; (V) or any other matter relating to the Service.
            Exclusive remedy: In the event of a breach of the aforementioned
            limited warranty, we will make things right by refunding you the
            cost of the Service in the month in which the breach took place, but
            only if you had already paid for it. You agree that the
            aforementioned guarantees are your sole and exclusive warranties,
            superseding any other express or implied guarantees you may have,
            such as guarantees of merchantability, adequate quality, fitness for
            a specific use, title, and non-infringement.
          </p>
          <h4>Indemnity</h4>
          <p>
            You agree to defend, indemnify and hold Beecity Apiary and anyone on
            its behalf, harmless against any losses, expenses, costs, claims,
            actions, damages (including reasonable attorneys’ fees, expert fees’
            and other reasonable costs of litigation) arising from, incurred as
            a result of, or in any manner related to your breach of the terms of
            these Terms of Use and arising in connection with Customer’s use of
            Beecity Apiary, participation in Beecity Apiary, or Customer’s
            breach of any provision of this Agreement, including, without
            limitation, any claim by any participant in any Beecity Apiary
            designed by Customer, or any claim by any non-participant third
            party who suffers any injury in connection with the use of Beecity
            Apiary or Beecity Apiary participants. Beecity Apiary reserves the
            right to assume the sole control of the defense and settlement of
            any claim, action, suit or proceeding for which you are obliged to
            provide indemnification hereunder. You will cooperate with Beecity
            Apiary with respect to such defense and settlement.
          </p>
          <h4>Payments</h4>
          <p>
            Some of our Services are paid services. In this respect, in order to
            access the unlimited premium version, you need a paid subscription.
            "Beecity Apiary" offers a monthly subscription plan of "Unlimited
            number of apiaries and beehives". If you choose not to subscribe you
            will have the opportunity to create one apiary and up to 5 beehives
            without any charge. If you choose to subscribe, a monthly
            subscription will be offered and you will be charged 5.99$ per
            month. Payment will be charged to iTunes Account at confirmation of
            purchase. Subscription automatically renews unless auto-renew is
            turned off at least 24-hours before the end of the current period.
            Account will be charged for renewal within 24-hours prior to the end
            of the current period. You can manage or turn off auto-renew in your
            Account settings at any time after purchase. You may cancel an
            ongoing subscription anytime by operating such modification in your
            Applestore Account. In case you cancel your subscription, the right
            to use the Beecity Apiary premium version will be active until the
            end of the billing period for whom you already paid the
            subscription. Therefore, we will not reimburse you the amount paid
            in advance in case you cancel an active subscription.
          </p>
          <h4>Fraud protection</h4>
          <p>
            As part of our order processing procedures, we may screen received
            orders for fraud or other types of unauthorized or illegal activity.
            We reserve the right to refuse to process an order due to suspected
            fraud or unauthorized or illegal activity. If we suspect fraudulent,
            unauthorized or illegal activity, we may reject your order or we may
            contact you at the phone number or email address you provided to
            confirm your order. We also reserve the right to cancel any accounts
            or refuse to ship to certain addresses due to suspected fraud or
            unauthorized or illegal activity. We take these measures to protect
            our customers as well as ourselves from fraud or other unauthorized
            or illegal activity.
          </p>
          <h4>Privacy Policy</h4>
          <p>
            Beecity Apiary is concerned about user privacy and operates the
            Services under the PRIVACY POLICY published at
            https://beecityglobal.com/app-privacy-policy/ . We urge you to read
            this policy now and, because the policy is updated from time to
            time, later at your convenience.
          </p>
          <h4>Miscellaneous</h4>
          <p>
            The failure to exercise or enforce any right or provision of the
            Terms of Service shall not constitute a waiver of such right or
            provision. We may assign its rights and obligations under this
            Agreement to any party for any reason whatsoever without any prior
            notice. Any dispute arising under or in connection with this
            Agreement will be deferred to the competent courts from Turkiye. Any
            dispute arising under or in connection with this Agreement will be
            judged in accordance with Turkish laws. The Terms of Service
            constitutes the entire agreement between you and the Provider and
            govern your use of the Service, superseding any prior agreements
            between you and the Provider (including, but not limited to, any
            prior versions of the Terms of Service). You can contact us at the
            following email address: apiary@beecityglobal.com
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {" "}
            Devam etmek için lütfen Kullanım Koşulları ve kuralları kabul ediniz!{" "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            autoFocus
          >
            {t("kapat")}
          </Button>
          <Button type="submit"  onClick={() => handleSignIn()} variant="outlined" color="primary">
            {t("kabulet")}
          </Button>
        </DialogActions>
      </Dialog>
            </Form>
          </Grid>
          {/* </Grid> */}
        </Grid>
      
      </Grid>    
  );
};

const Autorization = (props) => {
  const history = useHistory();
  const { signup, login, currentUser } = useAuth();
  const [method] = useState(props.method);

  useEffect(() => {
    if (currentUser) {
      history.push("/userNavigator/:id");
    }
    console.log({ currentUser });
  }, [currentUser, history]);

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          if (method === "Login") {
            login(values.email, values.password)
              .then(() => {
                toastSuccessNotify(`${method} Successfully performed!`);
                history.push("/userNavigator/:id");
                actions.setSubmitting(false);
              })
              .catch((error) => {
                toastErrorNotify(error.message);
                actions.setSubmitting(false);
                actions.resetForm();
              });
          } else {
            signup(values.email, values.password)
              .then(() => {
                toastSuccessNotify(`${method} Successfully performed!`);
                history.push("/userNavigator/:id");
                actions.setSubmitting(false);
              })
              .catch((error) => {
                toastErrorNotify(error.message);
                actions.setSubmitting(false);
                actions.resetForm();
              });
          }
        }}
        component={(props) => (
          <LoginAndRegisterForm
            method={method}
            {...props}
          />
        )}
      ></Formik>
    </div>
  );
};

export default Autorization;
