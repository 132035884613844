import {
    Box,
    Flex,
    Grid,
    Heading,
    Icon,
    Text,
    useToast,
  } from "@chakra-ui/react";
  import { useEffect, useState } from "react";
  import { shallowEqual, useDispatch, useSelector } from "react-redux";
  import { celsius } from "../helpers/extraFunctions";
  import { getItem } from "../helpers/sessionStorage";
  import { getWeatherByLocation, syncData } from "../redux/actions";
  import { Error } from "./Error";
  import { Loading } from "./Loading";
  import { Map } from "./Map";
  import { FaSyncAlt } from "react-icons/fa";
  import { Newbox, NewText } from "./SmallComponents";
  import { Forcast } from "./Forcast";

  import { useTranslation } from "react-i18next";


  
  export const Deatils = () => {
    const {
      isLoading,
      weatherData: data,
      forcastData,
      isError,
    } = useSelector((state) => state, shallowEqual);
    const [isRotate, setIsRotate] = useState(false);
    const dispatch = useDispatch();
    const toast = useToast();
    const { t } = useTranslation(["common"]);

  
    useEffect(() => {
      let weather = getItem("weather");
      !weather && dispatch(getWeatherByLocation(toast, t("lang"), t));
       dispatch(syncData(data.name, toast, t("lang"), t));
    }, [t("lang")]);
  
    const handleSyncData = () => {
      setIsRotate(true);
      dispatch(syncData(data.name, toast, t("lang"), t));
    };
  
    return isLoading ? (
      <Loading />
    ) : isError ? (
      <Error />
    ) : (
      <>
        <Box maxW={"1100px"} m={"0 auto 30px"} p={"20px"} minH={"400px"}>
          <Grid
            gridTemplateColumns={[
              "100%",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "27.5% 27.5% 43%",
            ]}
            gap={"20px"}
          >
            <Newbox>
              <Box color={"#52616B"} p={"30px"} textAlign={"center"}>
                <Flex justify={"end"}>
                  <Icon
                    onClick={handleSyncData}
                    onAnimationEnd={() => {
                      setIsRotate(false);
                    }}
                    className={isRotate ? "iconRotate" : undefined}
                    cursor={"pointer"}
                    w={"23px"}
                    h={"23px"}
                    as={FaSyncAlt}
                  />
                </Flex>
                <Heading>{data.name.split(" ")[0]}</Heading>
                <div style={{display:"flex", alignItems:"center" }}> 
                <img src= {`http://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`} />
  
                <Heading color={"#5e82f4"} fontSize={["40px", "50px", "50px", "40px", "40px"]}>
                  {Math.round(data.main.temp - 273)}
                  <sup>o</sup>C
                </Heading>
                </div>
                <Heading  fontSize={"18px"}>{data.weather[0].description.toUpperCase()}</Heading>
                
  
              </Box>
            </Newbox>
  
            <Newbox  >
              <Grid  templateColumns={"60% 40%"} h={"100%"} p={"15px"}>
                <Box  py={"5px"} pl={"10%"}>
                  {[
                    t("hissedilensıcaklık"),
                    t("nem"),
                    t("ruzgar"),
                    t("gorusalanı"),
                    t("enyukseksıcaklık"),
                    t("endusuksıcaklık"),
                  ].map((e, i) => (
                    <Text
                      key={i}
                      color={"#5e82f4"}
                      fontWeight={500}
                      mt={"14px"}
                      fontSize={"15px"}
                      textAlign={"left"}
                    >
                      {e}
                    </Text>
                  ))}
                </Box>
                <Box color={"#D8D8D8"}  textAlign={"left"}  borderRadius={"30px"} bg={"#1D1D1D"} py={"-7px"} pl={"15%"}>
                  <NewText  color={"#D8D8D8"} >
                    {celsius(data.main.feels_like)}
                    <sup>o</sup> C
                  </NewText>
                  <NewText>{data.main.humidity}%</NewText>
                  <NewText>{(data.wind.speed * 3.6).toFixed(2)} Km/h</NewText>
                  <NewText>{(data.visibility * 0.001).toFixed(2)} Km</NewText>
                  <NewText>
                    {celsius(data.main.temp_max)}
                    <sup>o</sup> C
                  </NewText>
                  <NewText>
                    {celsius(data.main.temp_min)}
                    <sup>o</sup> C
                  </NewText>
                </Box>
              </Grid>
            </Newbox>
  
            <Newbox>
              <Map city={data.name} />
            </Newbox>
          </Grid>
  
          <Grid
            mt={"30px"}
            templateColumns={[
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
              "repeat(4, 1fr)",
              "repeat(5, 1fr)",
              "repeat(8, 1fr)",
            ]}
            gap={"20px"}
          >
            {forcastData.map((e, i) => (
              <Forcast key={i} data={e} />
            ))}
          </Grid>
  
          <div className="tagLine">
            <p id="madeByMohit">{t("arıkentuygulamasıhavadurumu")}</p>
          </div>
        </Box>
      </>
    );
  };
  