// import firebase from "firebase/app";
import "firebase/auth";
// import { initializeApp } from "firebase/app"
// import "firebase/database";
import firebase from "firebase";


const firebaseUtil = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  // databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId,
});
export default firebaseUtil;

export const auth = firebaseUtil.auth();
// export const firebaseDB = firebaseUtil.database();
export const firebaseDB = firebase.firestore();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');

