import React, { useEffect, useState } from "react";
import moment from "moment";

import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';

import { useApiary } from "../context/ApiaryContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import harvestImg from "../assets/honeycomb.png";

import { toastSuccessNotify, toastErrorNotify } from "../utils/ToastNotify";
import HarvestForm from "../components/HarvestForm";
import { v4 as uuidv4 } from "uuid";

import { useTranslation } from "react-i18next";
import { format } from "date-fns";



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    padding: theme.spacing(13),
    backgroundColor: "#046582",
  },
  harvestImg: {
    width: 200,
  },

  title: {
    fontSize: 35,
    fontFamily: "Girassol",
    color: "#046582",
  },
}));

const NewHarvest = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { addHarvest, apiaryIdFromHive } = useApiary();
  const history = useHistory();

  const uid = currentUser?.uid;
  const { id } = useParams();
  const apiaryId = apiaryIdFromHive; 
  const hiveId = id;
  const dateCreate = format(new Date(), "yyyy-MM-dd");
  console.log(dateCreate)

  const harvest = {
    harvestId: uuidv4(),
    uid : uid,
    apiaryId : apiaryId,
    hiveId : hiveId,
    singleHoneyAmount: 0.0,
    singlePropolisAmount: 0.0,
    singlePolenAmount: 0.0,
    singleBeeWaxAmount: 0.0,
    singleBeeMilkAmount: 0.0,
    singleBeeVenomAmount: 0.0,
    date: dateCreate,

  };

  const [newHarvest, setNewHarvest] = useState(harvest);
  const { t } = useTranslation(["common"]);
  
   

  const handler = async (newHarvest) => {
    await addHarvest(uid, apiaryId, hiveId, {
      ...newHarvest,
      date: format(new Date(newHarvest.date), "dd/MM/yyyy")});
    // history.push(`/hiveNavigator/${hiveId}`); // Buranın dönüşü apiary list olmalı
    history.push({
      pathname: `/hiveNavigator/${hiveId}`,
      state: [1,newHarvest]
    });
    toastSuccessNotify(t("Hasat Eklendi"));
  };




  return (
    <Container maxWidth="xs">
      <CssBaseline />
      <Grid item xs={12} md={12} lg={12}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={harvestImg} alt="harvest" className={classes.harvestImg} />
        </Avatar>
        <Typography component="h1" variant="h5" className={classes.title}>
          {/* ── {t("yenihasat")} ── */}
          {t("yenihasat")}
        </Typography>
      </div>
      <HarvestForm setNewHarvest={setNewHarvest} newHarvest={newHarvest} harvest={harvest} handler={handler} />
    </Grid>
    </Container>
  );
};

export default NewHarvest;
