import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > *": {
      margin: theme.spacing(5),
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    backgroundPosition: "initial",
    minHeight: "100vh",
    color: "white",
  },
}));

const Veterinary = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1>
        <hr />
        Veterinary Page
        <hr />
      </h1>
      <hr />
    </div>
  );
};

export default Veterinary;
