import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { MdForum } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { MdQuickreply } from "react-icons/md";
import ForumCard from "./components/ForumCard";
import firebase from "firebase";
import { useState } from "react";
import { useEffect } from "react";


const Forum = () => {
  const history = useHistory();
  const [blogContentList, setContactList] = useState();


  const fetchBlogContent = async () => {
    await firebase
      .database()
      .ref("/blog/")
      .once("value")
      .then((snapshot) => {
        const contacts = snapshot.val();
        const contactArray = [];
        for (let id in contacts) {
          contactArray.push({ id, ...contacts[id] });
        }
        setContactList(contactArray);
      });
  };
  useEffect(() => {
    fetchBlogContent();
  }, []);
  console.log(blogContentList)

  return (
    <div>     
      <Container className="mt-3">
        <Row>
          <Col lg={10} md={9} sm={8}>
            <Form>
              <Form.Group className="" controlId="formBasicEmail">
                <Form.Label>Beecity Apiary Forum</Form.Label>
                <Form.Control size="sm" type="text" placeholder="Ara.." />
              </Form.Group>
            </Form>{" "}
            <h3>Recent activity</h3>
            {blogContentList?.map(blog => 
            <ForumCard  blog={blog}  />
            )}
            
          </Col>
          <Col style={{ flexGrow: 1 }}  lg={2}  md={3} sm={4}>
            <Container
              style={{ minHeight: "25vh", minWidth:"10vw" }}
              className="border p-3 bg-info"
            >
              <h4>POPULAR TAGS</h4>
              {blogContentList?.map(blog => 
              <Button className="m-2" variant="secondary">
                #{blog.tag}
              </Button>
              
              )}            
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Forum;
